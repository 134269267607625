import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

const SettingsPage = (props) => (
  <div>
    <Title level={4}>General Settings</Title>
  </div>
);

export default SettingsPage;
