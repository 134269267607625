import { getMarkets, deleteMarkets, getPageMarkets } from 'actions/markets';

const markets = () => {
  const initialState = {};

  return (state = initialState, { type, response, ids }) => {
    switch (type) {
      case getMarkets.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getPageMarkets.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case deleteMarkets.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      default:
        return state;
    }
  };
};

export default markets();
