import { getLoanTypes, getPageLoanTypes, deleteLoanTypes } from 'actions/loanTypes';

const loanTypes = () => {
  const initialState = {};

  return (state = initialState, { type, response, ids }) => {
    switch (type) {
      case getLoanTypes.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getPageLoanTypes.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case deleteLoanTypes.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      default:
        return state;
    }
  };
};

export default loanTypes();
