import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Icon, Row, Col, Typography, Modal, Form, message,
} from 'antd';
import { StarFilled } from '@ant-design/icons';
import styled from 'styled-components';

import {
  Table, Button, FormItem, Input, Select, Switch, NumberFormat,
} from 'components';
import { messages } from 'messages';
import {
  getAllLenders,
} from 'actions/lenders';
import {
  getContacts as fetchContacts, addNewContact, updateContacts, deleteContacts,
} from 'actions/contacts';
import {
  getUsers as getUsersAction,
} from 'actions/users';

import {
  getContacts,
  contactsLoading,
  pageContactsLoading,
  addNewContactLoading,
  updateContactsLoading,
  deleteContactsLoading,
  getContactsAllErrors,
} from 'selectors/contacts';

import {
  getUsers,
  getCurrentUserId,
} from 'selectors/users';

import { getLenders, getLoading } from 'selectors/lenders';

import contactFormConfig from './contactFormConfig';

const { Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const ContactAddButton = styled(Button)`
  position: absolute;
  right: 0 
`;

const {
  title,
  fullNameColumnName,
  featuredColumnName,
  approvedColumnName,
  titleColumnName,
  editButtonMessage,
  deleteButtonMessage,
  addButtonMessage,
  deleteConfirmMessage,
  createModalActionName,
  saveModalActionName,
} = messages.landersCrudMessages.contactsMessages;

const {
  lenderSelect,
  featuredSwitch,
  createdByInput,
  approvedSwitch,
  emailInput,
  firstNameInput,
  lastNameInput,
  titleInput,
  phoneInput,
  linkedInUrlInput,
  notesInput,
  countrySelect,
  provinceSelect,
  cityInput,
  faxInput,
  mobileInput,
} = contactFormConfig;

const Contacts = (props) => {
  const columns = [
    {
      title: fullNameColumnName,
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: titleColumnName,
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: featuredColumnName,
      dataIndex: 'featured',
      key: 'featured',
      render: (text, record) => (record.featured === 'true'
        ? <div style={{ width: '100%', marginLeft: '20px' }}><StarFilled style={{ color: '#1B90FF' }} /></div>
        : ''),
    },
    {
      title: approvedColumnName,
      dataIndex: 'approved',
      key: 'approved',
      render: (text, record) => <Switch defaultChecked={record.approved === 'true'} onClick={() => setApprovedContact(record)} />,
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 200,
      render: (text, record) => record.hovered
      && <div>
        <Button type='default' size='small' onClick={() => changeModalAction('Edit', record)}>
          <Icon type='edit' theme='filled' />
          {editButtonMessage}
        </Button>
        {' '}
        <Button type='danger' size='small' onClick={() => showDeleteDialog(record.id)}>
          <Icon type='delete' theme='filled' />
          {deleteButtonMessage}
        </Button>
      </div>,
    },
  ];

  const {
    form, lenderId, country, provinces,
  } = props;
  const { getFieldDecorator } = form;

  const [modalAction, setModalAction] = useState('');
  const [selectedRecord, setSelectedRecord] = useState({});

  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const currentUser = useSelector(getCurrentUserId);
  const contacts = useSelector(getContacts);
  const lenders = useSelector(getLenders);
  const isGetContactsLoading = useSelector(contactsLoading);
  const isGetPageContactsLoading = useSelector(pageContactsLoading);
  const isAddContactLoading = useSelector(addNewContactLoading);
  const isUpdateContactLoading = useSelector(updateContactsLoading);
  const isDeleteContactsLoading = useSelector(deleteContactsLoading);
  const isLendersLoading = useSelector(getLoading);
  const contactFormErrors = useSelector(getContactsAllErrors);

  useEffect(() => {
    dispatch(getAllLenders.request({ getAll: true }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUsersAction.request({ getAll: true }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchContacts.request({ companyId: lenderId, getAll: true }));
  }, [dispatch, lenderId]);

  useEffect(() => {
    if (!isUpdateContactLoading && !isAddContactLoading && !contactFormErrors) {
      changeModalAction();
    }
    dispatch(fetchContacts.request({ companyId: lenderId, getAll: true }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lenderId, isUpdateContactLoading, isAddContactLoading, JSON.stringify(contactFormErrors)]);

  useEffect(() => {
    contactFormErrors && contactFormErrors.forEach((error) => message.error(`Error : ${error.code} ${error.message}`));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(contactFormErrors)]);

  const setApprovedContact = (record) => {
    dispatch(
      updateContacts.request({
        values: { ...record, approved: record.approved === 'true' ? 'false' : 'true' },
        id: record.id,
      }),
    );
  };

  const showDeleteDialog = useCallback((id) => {
    confirm({
      title: deleteConfirmMessage,
      okType: 'danger',
      onOk() {
        dispatch(deleteContacts.request({ id }));
      },
    });
  }, [dispatch]);

  const changeModalAction = (action, record = {}) => {
    setModalAction(action);
    setSelectedRecord(record);
    form.resetFields();
  };

  const cancelModal = (action, record = {}) => {
    confirm({
      title: 'Are you sure you want to close the modal without saving the changes? ',
      okType: 'danger',
      onOk() {
        setModalAction(action);
        setSelectedRecord(record);
        form.resetFields();
      },
    });
  };
  const submitForm = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const modifiedValues = {
          ...values,
          companyId: values.companyId || lenderId,
          featured: values.featured ? 'true' : 'false',
          createdBy: currentUser,
        };
        dispatch(
          modalAction === 'Create'
            ? addNewContact.request(modifiedValues)
            : updateContacts.request({ id: selectedRecord.id, values: modifiedValues }),
        );
      }
    });
  };

  const getNameFromId = () => {
    const temp = users.data.filter((user) => user.id === selectedRecord.createdBy);
    return temp.length ? temp[0].email : 'Admin';
  };

  return <>
    <Row>
      <Col span={24}>
        <Title level={4}>
          {`${title}s`}
          <ContactAddButton type='primary' style={{ float: 'right' }} onClick={() => changeModalAction('Create')}>
            <Icon type='plus' />
            {addButtonMessage}
          </ContactAddButton>
        </Title>
        <Table
          pagination={false}
          columns={columns}
          dataSource={contacts.data}
          loading={
            isGetContactsLoading
            || isDeleteContactsLoading
            || isGetPageContactsLoading
          }
          rowKey='id' />
        <Modal
          style={{ top: '7%' }}
          title={`${modalAction === 'Create' ? createModalActionName : saveModalActionName} ${title}`}
          visible={!!modalAction}
          onOk={() => submitForm()}
          onCancel={() => cancelModal()}
          okText={modalAction === 'Create' ? createModalActionName : saveModalActionName}
          okButtonProps={{
            form: 'contacts',
            loading: isAddContactLoading || isUpdateContactLoading,
          }}>
          <Row gutter={[20, 20]}>
            <Form
              layout='horizontal'
              hideRequiredMark
              id='contacts'
              onSubmit={submitForm}>
              <Col span={12}>
                <FormItem label={createdByInput.label}>
                  {getFieldDecorator(createdByInput.fieldName, {
                    ...createdByInput.decoratorOptions,
                    initialValue: getNameFromId(selectedRecord),

                  })(
                    <Input type='createdBy' {...createdByInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={featuredSwitch.label}>
                  {getFieldDecorator(featuredSwitch.fieldName, {
                    ...featuredSwitch.decoratorOptions,
                    initialValue: selectedRecord.featured === 'true',
                  })(
                    <Switch {...featuredSwitch.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={approvedSwitch.label}>
                  {getFieldDecorator(approvedSwitch.fieldName, {
                    ...approvedSwitch.decoratorOptions,
                    initialValue: selectedRecord.approved === 'true',
                  })(
                    <Switch {...approvedSwitch.elementProps} />,
                  )}
                </FormItem>
              </Col>
              {modalAction === 'Edit'
              && <Col span={24}>
                <FormItem label={lenderSelect.label}>
                  {getFieldDecorator(lenderSelect.fieldName, {
                    ...lenderSelect.decoratorOptions,
                    initialValue: selectedRecord.companyId || lenderId,
                  })(
                    <Select
                      loading={isLendersLoading}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} >
                      {lenders.all.data.sort((a, b) => a.companyName.toLowerCase() > b.companyName.toLowerCase()).map((option) => (
                        <Option key={option.id} value={option.id}>
                          {option.companyName}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>}
              <Col span={12}>
                <FormItem label={emailInput.label}>
                  {getFieldDecorator(emailInput.fieldName, {
                    ...emailInput.decoratorOptions,
                    initialValue: selectedRecord.email,
                  })(
                    <Input type='email' {...emailInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={titleInput.label}>
                  {getFieldDecorator(titleInput.fieldName, {
                    ...titleInput.decoratorOptions,
                    initialValue: selectedRecord.title,
                  })(
                    <Input {...titleInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={firstNameInput.label}>
                  {getFieldDecorator(firstNameInput.fieldName, {
                    ...firstNameInput.decoratorOptions,
                    initialValue: selectedRecord.firstName,
                  })(
                    <Input {...firstNameInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={lastNameInput.label}>
                  {getFieldDecorator(lastNameInput.fieldName, {
                    ...lastNameInput.decoratorOptions,
                    initialValue: selectedRecord.lastName,
                  })(
                    <Input {...lastNameInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                {/* <FormItem label={countrySelect.label}>
                {getFieldDecorator(countrySelect.fieldName, {
                  ...countrySelect.decoratorOptions,
                  initialValue: selectedRecord.country || country,
                })(
                  <Input {...selectedRecord.elementProps} />,
                )}
              </FormItem> */}
                <FormItem label={countrySelect.label}>
                  {getFieldDecorator(countrySelect.fieldName, {
                    ...countrySelect.decoratorOptions,
                    initialValue: selectedRecord.country || country,
                  })(
                    <Select {...countrySelect.elementProps}>
                      {countrySelect.options.map((option) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label={provinceSelect.label}>
                  {getFieldDecorator(provinceSelect.fieldName, {
                    ...provinceSelect.decoratorOptions,
                    initialValue: selectedRecord.provinceId,
                  })(
                    <Select {...provinceSelect.elementProps}>
                      {provinces.map((option) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label={cityInput.label}>
                  {getFieldDecorator(cityInput.fieldName, {
                    ...cityInput.decoratorOptions,
                    initialValue: selectedRecord.city,
                  })(
                    <Input {...cityInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label={phoneInput.label}>
                  {getFieldDecorator(phoneInput.fieldName, {
                    ...phoneInput.decoratorOptions,
                    initialValue: selectedRecord.phone,
                  })(
                    <NumberFormat {...phoneInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label={faxInput.label}>
                  {getFieldDecorator(faxInput.fieldName, {
                    ...faxInput.decoratorOptions,
                    initialValue: selectedRecord.fax,
                  })(
                    <Input {...faxInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label={mobileInput.label}>
                  {getFieldDecorator(mobileInput.fieldName, {
                    ...mobileInput.decoratorOptions,
                    initialValue: selectedRecord.mobile,
                  })(
                    <Input {...mobileInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label={linkedInUrlInput.label}>
                  {getFieldDecorator(linkedInUrlInput.fieldName, {
                    ...linkedInUrlInput.decoratorOptions,
                    initialValue: selectedRecord.linkedInUrl,
                  })(
                    <Input {...linkedInUrlInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label={notesInput.label}>
                  {getFieldDecorator(notesInput.fieldName, {
                    ...notesInput.decoratorOptions,
                    initialValue: selectedRecord.notes,
                  })(
                    <Input.TextArea {...notesInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
            </Form>
          </Row>
        </Modal>
      </Col>
    </Row>
  </>;
};

export default Form.create({ name: 'contacts-form' })(Contacts);
