import { makeRequestAction } from './index';

export const getContacts = makeRequestAction('GET_CONTACTS', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const getPageContacts = makeRequestAction('GET_PAGE_CONTACTS', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const addNewContact = makeRequestAction('ADD_NEW_CONTACT', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const deleteContacts = makeRequestAction('DELETE_CONTACT', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const updateContacts = makeRequestAction('UPDATE_CONTACT', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
