import api from './api';

export const postImportListingsApi = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => (
    key === 'file' && Array.isArray(data[key])
      ? data[key].forEach((file) => formData.append(key, file))
      : formData.append(key, data[key])
  ));
  return api({ 'Content-type': 'application/x-www-form-urlencoded' }).post('/listing-leads', formData);
};
