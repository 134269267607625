import React, { Fragment, useState, useEffect } from 'react';
import uniqid from 'uniqid';
import {
  Row,
  Col,
  Input,
  Button,
  Modal,
  Icon,
} from 'antd';
import {
  Card, BuilderWrapper, BuilderPageHeader, BuilderFieldCard,
} from 'components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddFieldModal from './partials/AddFieldModal';

const { confirm } = Modal;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const GridCard = React.memo(({
  data,
  parentOrder,
  currentData,
  childOrder,
  updateForm,
  fieldId,
  busySlugs,
}) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [items, setItems] = useState(data.fields);
  const [fieldSettings, setFieldSettings] = useState();

  useEffect(() => {
    setItems(data.fields);
  }, [data]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    setItems(reorderedItems);
    updateForm({
      ...currentData,
      action: {
        type: 'reorderFields',
        blockId: data.id,
        fieldId,
        updatedFields: reorderedItems,
      },
    });
  };

  return (<Fragment key={`child-${data.id}`}>
    <Card
      title={`Fields Box ${parentOrder}.${childOrder}`}
      extra={[
        <Button
          key={'card-add-button'}
          size={'small'}
          type={'link'}
          onClick={() => setModalVisibility(true)}
        > <Icon type='plus' /></Button>,
      ]} >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={data.id}>
          {(droppableProvided) => <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.draggableProps}
            {...droppableProvided.dragHandleProps}>
            {items.length ? items.map((res, index) => <Draggable key={res.id} draggableId={res.id} index={index}>
              {(draggableProvided) => (<div
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                style={{ overflow: 'auto', ...draggableProvided.draggableProps.style }}>
                <BuilderFieldCard
                  updateForm={updateForm}
                  onSettingsClick={() => {
                    setFieldSettings(res);
                    setModalVisibility(true);
                  }}
                  removeField={(id) => {
                    updateForm({
                      ...currentData,
                      action: {
                        type: 'removeField',
                        fieldId,
                        blockId: data.id,
                        childFieldId: id,
                      },
                    });
                  }}
                  dragHandleProps={draggableProvided.dragHandleProps}
                  {...res} />
              </div>)}
            </Draggable>) : ''}
            {droppableProvided.placeholder}
          </div>}

        </Droppable>
      </DragDropContext>
    </Card>
    <AddFieldModal
      visible={modalVisibility}
      fieldSettings={fieldSettings}
      onCancel={() => {
        setFieldSettings(undefined);
        setModalVisibility(false);
      }}
      busySlugs={busySlugs}
      onAdd={(fieldData) => {
        setFieldSettings(undefined);
        updateForm({
          ...currentData,
          action: {
            type: !fieldData.id ? 'addField' : 'updateField',
            fieldId,
            blockId: data.id,
            ...(fieldData.id ? { childFieldId: fieldData.id } : {}),
            fieldData: {
              ...fieldData,
              ...(!fieldData.id ? { id: uniqid() } : {}),
            },
          },
        });
      }}
    />
  </Fragment>);
});

export default React.memo(({
  field,
  updateForm,
  currentData,
  busySlugs,
}) => field.child.length && <BuilderWrapper>
  <Row gutter={40}>
    <Col span={24}>
      <BuilderPageHeader
        title={<Input
          defaultValue={field.sectionName}
          addonBefore='Section Name'
          onChange={(e) => {
            updateForm({
              ...currentData,
              action: {
                type: 'updateSectionName',
                sectionId: field.id,
                sectionNameValue: e.target.value,
              },
            });
          }}
          disabled={field.locked}
        />}
        extra={[
          <Button
            key='action-button-remove-form'
            type='link'
            ghost
            size='small'
            onClick={() => {
              handleSectionRemove(updateForm, currentData, field);
            }}
            disabled={field.locked}
          >
            <Icon type='delete' theme='twoTone' twoToneColor='#ff0000' />
          </Button>,
        ]}
      />
    </Col>
  </Row>
  <Row gutter={40}>
    {field.child.map((child) => <Col span={child.col} key={child.id}>
      <GridCard
        data={child}
        fieldId={field.id}
        updateForm={updateForm}
        currentData={currentData}
        parentOrder={field.order}
        childOrder={child.order}
        busySlugs={busySlugs} />
    </Col>)}
  </Row>
</BuilderWrapper>);

function handleSectionRemove(updateForm, currentData, field) {
  confirm({
    title: 'Do you want to delete this section ?',
    onOk() {
      updateForm({
        ...currentData,
        action: {
          type: 'removeSection',
          sectionId: field.id,
        },
      });
    },
    onCancel() { },
  });
}
