import {
  getServiceRoles, deleteServiceRoles, getPageServiceRoles, addNewServiceRoles, updateServiceRoles,
} from 'actions/roleManagements';

const serviceRoles = () => {
  const initialState = {};

  return (state = initialState, { type, response, ids }) => {
    switch (type) {
      case getServiceRoles.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getPageServiceRoles.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case deleteServiceRoles.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      case addNewServiceRoles.requestTypes.REQUEST:
      case updateServiceRoles.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addNewServiceRoles.requestTypes.SUCCESS:
      case updateServiceRoles.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      default:
        return state;
    }
  };
};

export default serviceRoles();
