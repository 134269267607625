import { messages } from 'messages';

const {
  propertyNameColumnName,
  createdAtColumnName,
  statusColumnName,
  addressColumnName,
} = messages.services.table;

const columns = [
  {
    title: propertyNameColumnName,
    dataIndex: 'propertyName',
    key: 'propertyName',
  },
  {
    title: createdAtColumnName,
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text) => text.slice(0, 10),
  },
  {
    title: statusColumnName,
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: addressColumnName,
    dataIndex: 'address',
    key: 'address',
  },
];

export default {
  drone: {
    titleMessage: 'Drones',
    columns,
  },
  lease: {
    titleMessage: 'Leases',
    columns,
  },
  inspection: {
    titleMessage: 'Inspections',
    columns,
  },
};
