import React from 'react';
import {
  Upload,
  Icon,
  message,
} from 'antd';

const { Dragger } = Upload;

const FileUpload = (props) => {
  const {
    fileList, changeHandler, extensions, maxAmount, fileSize, ...otherProps
  } = props;

  const addFile = (file, selectedFileList) => {
    const hasBigFiles = selectedFileList.find((f) => f.size > fileSize);
    const hasRestrictedExtensions = !!selectedFileList.filter(
      (f) => !extensions.includes(f.name.split('.').pop()),
    ).length;
    if (fileList.length + selectedFileList.length > maxAmount || hasBigFiles) {
      message.error(`Maximum ${maxAmount} files allowed, ${fileSize / (1024 * 1024)} MiB each`);
    } else if (hasRestrictedExtensions) {
      message.error('Unsupported extension!');
    } else {
      changeHandler(file, [...fileList, ...selectedFileList], 'add');
    }
    return false;
  };

  const deleteFile = (file) => {
    changeHandler(file, fileList.filter((f) => f.uid !== file.uid), 'delete');
  };

  return (
    <Dragger
      multiple
      fileList={fileList}
      onRemove={deleteFile}
      beforeUpload={addFile}
      {...otherProps}
  >
      <p className='ant-upload-drag-icon'>
        <Icon type='inbox' />
      </p>
      <p className='ant-upload-text'>
        Click or drag file to this area to upload
      </p>
      <p className='ant-upload-hint'>
        Maximum {maxAmount} files allowed, {fileSize / (1024 * 1024)} MiB each
      </p>
      <p className='ant-upload-hint'>
        Supported extensions: {extensions.map((ext) => `.${ext}, `)}
      </p>
    </Dragger>
  );
};

export default FileUpload;
