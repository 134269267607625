import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Form, message, Typography,
} from 'antd';
import styled from 'styled-components';
import logo from 'assets/img/logo.png';

import {
  Button, Input, FormItem,
} from 'components';
import { userLogin } from 'actions/auth';
import { loginLoading, loginError } from 'selectors/auth';

const { Title } = Typography;

const StyledRow = styled(Row)`
    align-items: center; 
    height: calc(100vh - 165px);
`;

const StyledLogo = styled.img`
    width: 100%;
    margin-bottom: 20px
`;

const LoginPageComponent = ({ form }) => {
  const { getFieldDecorator } = form;

  const dispatch = useDispatch();
  const loading = useSelector(loginLoading);
  const error = useSelector(loginError);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        dispatch(userLogin.request(values));
      }
      return false;
    });
  };

  useEffect(() => {
    if (error) {
      error.data && error.data.redirect && message.error(error.data.message);
      form.setFields({
        email: {
          value: form.getFieldValue('email'),
          errors: [new Error('Invalid credentials')],
        },
        password: {
          value: form.getFieldValue('password'),
          errors: [new Error('Invalid credentials')],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Fragment>
      <StyledRow type='flex'>
        <Col md={9} />
        <Col md={6}>
          <Row>
            <StyledLogo src={logo} />
          </Row>
          <Row type={'flex'} align={'middle'}>
            <Col span={24}>
              <Title level={2} strong={'true'} margin={'auto'} align='middle'>
                Log In
              </Title>
              <Form
                onSubmit={handleSubmit}
                layout={'vertical'}
                labelAlign={'left'}
                hideRequiredMark
              >
                <FormItem label='Username/Email'>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                        required: true,
                        message: 'Please input correct email address',
                      },
                    ],
                  })(
                    <Input
                      placeholder='Email'
                      size={'large'}
                      fullwidth={'true'}
                    />,
                  )}
                </FormItem>
                <FormItem label='Password'>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        // min: 8,
                        max: 32,
                        required: true,
                        message: 'Password length is out of range',
                      },
                    ],
                  })(
                    <Input
                      placeholder='Password'
                      type='password'
                      size={'large'}
                      fullwidth={'true'}
                    />,
                  )}
                </FormItem>
                <FormItem>
                  <Button
                    type={'primary'}
                    block
                    size={'large'}
                    htmlType={'submit'}
                    loading={loading}
                  >
                    Sign In
                  </Button>
                </FormItem>
              </Form>
            </Col>
          </Row>
        </Col>
      </StyledRow>
    </Fragment>
  );
};

const LoginPage = Form.create({ name: 'login_form' })(LoginPageComponent);
export default withRouter(LoginPage);
