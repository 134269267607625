/* eslint-disable camelcase */
import React, { useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Layout, Menu, Icon, List, Tooltip, Modal,
} from 'antd';
import styled from 'styled-components';

import Button from 'components/Button';

import { userLogout } from 'actions/auth';
import { getUserData } from 'selectors/auth';

import routes from 'configs/routeConfig';
import { messages } from 'messages';

import logo from 'assets/img/logo.png';


const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;

const { logOutMessage, settingsMessage } = messages.user;


const { confirm } = Modal;

const LogoContainer = styled.div`
  padding: 19px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
`;

const Logo = styled.img`
  width: 110px;
  height: 25px;
`;

const StyledHeader = styled(Header)`
  z-index: 1;
  padding: 0 40px;
  height: 60px;
  background: #fff;
  color: #4962f5;
  font-size: 18px;

  button {
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.85);
  }
`;

const StyledSider = styled(Sider)`
  overflow: auto;
  min-height: 100vh !important;
  max-width: unset !important;
  width: 250px !important;
  left: 0;
`;

const StyledContent = styled(Content)`
  min-height: calc(100vh - 70px);
  margin-right: 16px;
  padding: 24px;
`;

const StyledSubMenu = styled(SubMenu)`
  ul {
    -webkit-box-shadow: 0 1px rgba(0, 0, 0, 0.45) inset !important;
    box-shadow: 0 1px rgba(0, 0, 0, 0.45) inset !important;
    background: inherit !important;
  }

  .ant-menu-submenu-title {
    height: 100% !important;
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 18px;
`;

const StyledList = styled(List)`
  color: white;
  width: 100px;
  font-size: 10px;
  cursor: pointer;
`;

const StyledListItem = styled(List.Item)`
  padding: 10px;
  :not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
`;

const NavigationItem = styled.span`
  :last-child {
    font-weight: 700;
  }

  .nav-divider {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const BackButton = styled(Button)`
  padding-left: 0;
  margin: 0 !important;
`;

const StyledLink = styled(Link)`
  color: inherit;
`;

const getMenuItems = (currentRoutes) => currentRoutes.map((route, index) => (route.children ? (
  <StyledSubMenu
    theme='dark'
    key={route.title}
    title={
      <StyledLink to={route.paths && route.paths[0]} onClick={(e) => e.stopPropagation()}>
        <span>
          {route.icon && <Icon type={route.icon} theme='filled' />}
          <span>{route.title}</span>
        </span>
      </StyledLink>
    }
      >
    {getMenuItems(route.children)}
  </StyledSubMenu>
) : (
  <Menu.Item key={route.title}>
    {route.icon && <Icon type={route.icon} theme='filled' />}
    <span>{route.title}</span>
    <Link to={route.paths && route.paths[0]} />
  </Menu.Item>
)));

const find = (currentRoutes, pathname, initRouteArray) => {
  let routeArray;
  for (let i = 0; i < currentRoutes.length; i += 1) {
    routeArray = [...initRouteArray];
    const route = currentRoutes[i];
    if (route.paths && route.paths.includes(pathname)) {
      routeArray = [...routeArray, route];
      break;
    }
    if (route.children) {
      routeArray = [
        ...routeArray,
        route,
        ...find(route.children, pathname, routeArray),
      ];
      if (routeArray.find((r) => r.paths.includes(pathname))) { break; }
    }
  }

  return routeArray;
};

const PageContainer = (props) => {
  const dispatch = useDispatch();
  const routeArray = find(routes, props.location.pathname, []);
  const currentPathTitle = [...routeArray].splice(-1)[0].title;
  const userData = useSelector(getUserData);

  const { user_detail } = userData;

  const logOut = useCallback(
    () => {
      dispatch(userLogout.request());
    },
    [dispatch],
  );

  const showDeleteDialog = () => {
    confirm({
      title: 'Log Out?',
      onOk() {
        logOut();
      },
    });
  };

  return (
    <Layout>
      <StyledSider>
        <LogoContainer>
          <Logo src={logo} alt='logo' />
        </LogoContainer>
        <Menu theme='dark' mode='inline' selectedKeys={[currentPathTitle]}>
          {getMenuItems(routes)}
        </Menu>
      </StyledSider>
      <Layout>
        <StyledHeader>
          <div>
            <span style={{ float: 'left' }}>
              {routeArray.length > 1
              && <BackButton type='link'>
                <Link to={[...routeArray].splice(-2)[0].paths[0]}>
                  <StyledIcon type='arrow-left' />
                </Link>
              </BackButton>
              }
              {routeArray.map((route, index) => (
                <NavigationItem key={route.paths[0]}>
                  <Link to={route.paths[0]}>{route.title} </Link>
                  {index !== routeArray.length - 1 && (
                    <span className='nav-divider'>/</span>
                  )}
                </NavigationItem>
              ))}
            </span>
            <span style={{ float: 'right' }}>
              <Tooltip
                title={
                  <StyledList>
                    <StyledListItem>{settingsMessage}</StyledListItem>
                    <StyledListItem onClick={showDeleteDialog}>{logOutMessage}</StyledListItem>
                  </StyledList>
                }
                align={{ offset: [-13, 20] }}
                trigger='click'
                placement='bottomRight'
              >
                <Button type='link'>
                  {user_detail && `${user_detail.firstName} ${user_detail.lastName}`}
                  <StyledIcon type='user' />
                </Button>
              </Tooltip>
            </span>
          </div>
        </StyledHeader>
        <StyledContent>{props.children}</StyledContent>
      </Layout>
    </Layout>
  );
};

export default withRouter(PageContainer);
