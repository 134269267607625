import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

const { Title } = Typography;

const StyledTitle = styled(Title)`
    button {
      float: right;
      margin-right: 13px;
    }
  `;

const Button = (props) => <StyledTitle {...props} />;

export default Button;
