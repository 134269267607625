import {
  getServices, deleteServices, updateService,
} from 'actions/services';

const services = () => {
  const initialState = {};

  return (state = initialState, action) => {
    const { type, response } = action;
    switch (type) {
      case getServices.requestTypes.SUCCESS:
        return {
          ...state,
          ...response,
          isSuccessful: false,
        };
      case deleteServices.requestTypes.SUCCESS:
        return {
          ...state,
          [response.type]: { ...state[response.type], total: state.total - response.ids.length },
          type: response.type,
        };
      case updateService.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateService.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case updateService.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      default:
        return state;
    }
  };
};

export default services();
