import { createSelector } from 'reselect';
import {
  getLender,
  getLenders as getLendersAction,
  deleteLenders as deleteLendersAction,
  addNewLender,
  updateLender,
  addLenderLogo,
  importLenders,
  importLendersData,
  importLendersContacts,
} from 'actions/lenders';
import { getCategories } from 'actions/categories';
import { getLoanTypes } from 'actions/loanTypes';
import { getMarkets } from 'actions/markets';
import { getPropertyClasses } from 'actions/propertyClasses';
import { getPropertyTypes } from 'actions/propertyTypes';
import { getStateProvinces } from 'actions/stateProvinces';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getLendersState = (state) => state.lenders;
export const getErrorsState = (state) => state.error;
export const getUploadsState = (state) => state.uploads;

export const getSuccessState = createSelector(
  getLendersState,
  (state) => state.isSuccessful,
);

export const getLenders = createSelector(
  getLendersState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const getSelectedLender = createSelector(
  getLendersState,
  (data) => data.selectedLender,
);

export const getImportLendersInfo = createSelector(
  getLendersState,
  (state) => state.importLendersInfo,
);

export const importLendersLoading = createLoadingSelector(
  importLenders.actionName,
)();
export const importLendersError = createErrorSelector(importLenders.actionName)();

export const getImportLendersDataInfo = createSelector(
  getLendersState,
  (state) => state.importLendersDataInfo,
);

export const importLendersDataLoading = createLoadingSelector(
  importLendersData.actionName,
)();
export const importLendersDataError = createErrorSelector(importLendersData.actionName)();

export const getImportLendersContactsInfo = createSelector(
  getLendersState,
  (state) => state.importLendersContactsInfo,
);

export const importLendersContactsLoading = createLoadingSelector(
  importLendersContacts.actionName,
)();
export const importLendersContactsError = createErrorSelector(importLendersContacts.actionName)();

export const updateLenderLoading = createLoadingSelector(
  updateLender.actionName,
)();
export const updateLenderError = createErrorSelector(updateLender.actionName)();

export const newLenderLoading = createLoadingSelector(
  addNewLender.actionName,
)();
export const newLenderError = createErrorSelector(addNewLender.actionName)();
export const getLoading = createLoadingSelector(getLendersAction.actionName)();
export const getError = createErrorSelector(getLendersAction.actionName)();

export const deleteLoading = createLoadingSelector(
  deleteLendersAction.actionName,
)();
export const deleteError = createErrorSelector(
  deleteLendersAction.actionName,
)();

export const getLendersFormErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const lendersFormErrorsArray = [
      getLoanTypes.actionName,
      getPropertyClasses.actionName,
      getPropertyTypes.actionName,
      getCategories.actionName,
      getStateProvinces.actionName,
      getMarkets.actionName,
      addNewLender.actionName,
      updateLender.actionName,
      addLenderLogo.actionName,
    ];

    lendersFormErrorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);

export const getLenderLogo = createSelector(getUploadsState, (uploads) => uploads[addLenderLogo.actionName]);
export const lenderLogoLoading = createLoadingSelector(addLenderLogo.actionName)();

export const getSelectedLenderLoading = createLoadingSelector(getLender.actionName)();
