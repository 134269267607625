import {
  getUsers, deleteUsers, addNewUsers, updateUsers, getPageUsers,
} from 'actions/users';

const users = () => {
  const initialState = {};

  return (state = initialState, action) => {
    const {
      type, response, ids, companyId,
    } = action;
    switch (type) {
      case getPageUsers.requestTypes.SUCCESS:
      case getUsers.requestTypes.SUCCESS:
        return {
          ...response,
          companyId,
        };
      case deleteUsers.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      case addNewUsers.requestTypes.REQUEST:
      case updateUsers.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addNewUsers.requestTypes.SUCCESS:
      case updateUsers.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case addNewUsers.requestTypes.FAILURE:
      case updateUsers.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      default:
        return state;
    }
  };
};

export default users();
