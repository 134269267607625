import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';
import {
  getForms, getForm, addForm, updateForm, deleteForm,
} from 'actions/formBuilder';
import {
  getFormsApi, getFormApi, updateFormApi, createFormApi, deleteFormApi,
} from 'services/formBuilder';
import { getUserData } from 'selectors/auth';
import fetchEntity from './fetch-entity';
// get forms
const fetchForms = fetchEntity.bind(
  null,
  getForms.actions,
  getFormsApi,
);

export function* loadGetForms({ params }) {
  yield put(getForm.actions.failure({}, undefined));
  yield call(fetchForms, { ...params, '$sort[updatedAt]': -1 });
}

function* watchGetForms() {
  yield takeLatest(getForms.actionName, loadGetForms);
}
// get form
const fetchForm = fetchEntity.bind(
  null,
  getForm.actions,
  getFormApi,
);

export function* loadGetForm({ params }) {
  yield put(getForm.actions.failure({}, undefined));
  yield call(fetchForm, params);
}

function* watchGetForm() {
  yield takeLatest(getForm.actionName, loadGetForm);
}

export function* loadGetPageFormsOnChange({ params }) {
  const formBuilder = yield select((state) => state.formBuilder);
  const { total, skip, limit } = formBuilder;
  if (skip && skip >= total) {
    yield call(fetchForms, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchForms, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetPageFormsOnChange({ params }) {
  yield takeLatest([
    addForm.requestTypes.SUCCESS,
    deleteForm.requestTypes.SUCCESS,
  ], loadGetPageFormsOnChange);
}

// add form
const fetchAddForm = fetchEntity.bind(
  null,
  addForm.actions,
  createFormApi,
);

export function* loadAddForms({ params }) {
  const userData = yield select(getUserData);
  yield call(fetchAddForm, { ...params, createdBy: userData.id });
}

function* watchAddForms() {
  yield takeLatest(addForm.actionName, loadAddForms);
}

// update form
const fetchUpdateForm = fetchEntity.bind(
  null,
  updateForm.actions,
  updateFormApi,
);

export function* loadUpdateForm({ params }) {
  yield call(fetchUpdateForm, params);
}

function* watchUpdateForm() {
  yield takeLatest(updateForm.actionName, loadUpdateForm);
}

// delete form
const fetchDeleteForm = fetchEntity.bind(
  null,
  deleteForm.actions,
  deleteFormApi,
);

export function* loadDeleteForm({ params }) {
  yield call(fetchDeleteForm, params);
}

function* watchDeleteForm() {
  yield takeLatest(deleteForm.actionName, loadDeleteForm);
}

export default {
  watchGetForms,
  watchGetForm,
  watchUpdateForm,
  watchAddForms,
  watchGetPageFormsOnChange,
  watchDeleteForm,
};
