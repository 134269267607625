/**
 * @todo
 * As i see you are handling few pages in this file
 * I am suggesting to separate it, because it's difficult for me to read this code
 */
import React, {
  useEffect, useState, useCallback, useMemo, useRef,
} from 'react';
import { Button, Table, Title } from 'components';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Icon, Modal, message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPagePropertyClasses, deletePropertyClasses, updatePropertyClasses, addNewPropertyClasses,
} from 'actions/propertyClasses';
import {
  getPageMarkets, deleteMarkets, updateMarkets, addNewMarkets,
} from 'actions/markets';
import {
  getPageLoanTypes, deleteLoanTypes, updateLoanTypes, addNewLoanTypes,
} from 'actions/loanTypes';
import {
  getPagePropertyTypes, deletePropertyTypes, updatePropertyTypes, addNewPropertyTypes,
} from 'actions/propertyTypes';
import {
  getPageStateProvinces, deleteStateProvinces, updateStateProvinces, addNewStateProvinces,
} from 'actions/stateProvinces';
import {
  getPageCategories, deleteCategories, updateCategories, addNewCategories,
} from 'actions/categories';
import {
  getCategories as getConsultantCategories,
  addNewCategories as addNewConsultantCategories,
  updateCategories as updateConsultantCategories,
  deleteCategories as deleteConsultantCategories,
} from 'actions/consultantCategories';


import {
  getPropertyTypes,
  pagePropertyTypesLoading,
  getPropertyTypesAllErrors,
} from 'selectors/propertyTypes';
import { getPropertyClasses, pagePropertyClassesLoading, getPropertyClassesAllErrors } from 'selectors/propertyClasses';
import { getMarkets, pageMarketsLoading, getMarketsAllErrors } from 'selectors/markets';
import { getLoanTypes, pageLoanTypesLoading, getLoanTypesAllErrors } from 'selectors/loanTypes';
import { getStateProvinces, pageStateProvincesLoading, getStateProvincesAllErrors } from 'selectors/stateProvinces';
import { getCategories, pageCategoriesLoading, getCategoriesAllErrors } from 'selectors/categories';
import {
  getCategories as getConsultantCategoriesValues,
  categoriesLoading as consultantCategoriesLoading,
  getCategoriesAllErrors as getConsultantCategoriesAllErrors,
} from 'selectors/consultantCategories';


import { messages } from 'messages';
import AssetsFormModal from './AssetsFormModal';
import assetsConfig from './assetsConfig';

const {
  addNewButtonMessage,
  deleteAssetConfirmMessage,
  editButtonMessage,
  unselectButtonMassage,
} = messages.assets;

const { confirm } = Modal;

const Footer = styled.div`
    margin-top: 10px;
    float: right;
  
    button {
      margin-left: 10px;
      margin-right: 10px;
    }
  `;

const AssetCompinent = (props) => {
  const assetPath = props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1);
  let currentAssetType;
  switch (assetPath) {
    case 'property-classes':
      currentAssetType = 'propertyClasses';
      break;
    case 'markets':
      currentAssetType = 'markets';
      break;
    case 'loan-types':
      currentAssetType = 'loanTypes';
      break;
    case 'property-types':
      currentAssetType = 'propertyTypes';
      break;
    case 'state-provinces':
      currentAssetType = 'stateProvinces';
      break;
    case 'categories':
      currentAssetType = 'categories';
      break;
    case 'consultant-categories':
      currentAssetType = 'consultantCategories';
      break;
    default:
  }

  const PAGE_SIZE = 10;
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const getPropertyClassesErrors = useSelector(getPropertyClassesAllErrors);
  const getMarketsErrors = useSelector(getMarketsAllErrors);
  const getLoanTypesErrors = useSelector(getLoanTypesAllErrors);
  const getPropertyTypesErrors = useSelector(getPropertyTypesAllErrors);
  const getStateProvincesErrors = useSelector(getStateProvincesAllErrors);
  const getCategoriesErrors = useSelector(getCategoriesAllErrors);
  const getConsultantCategoriesErrors = useSelector(getConsultantCategoriesAllErrors);

  const propertyClasses = useSelector(getPropertyClasses);
  const markets = useSelector(getMarkets);
  const loanTypes = useSelector(getLoanTypes);
  const propertyTypes = useSelector(getPropertyTypes);
  const stateProvinces = useSelector(getStateProvinces);
  const categories = useSelector(getCategories);
  const consultantCategories = useSelector(getConsultantCategoriesValues);

  const isPropertyClassesLoading = useSelector(pagePropertyClassesLoading);
  const isMarketsLoading = useSelector(pageMarketsLoading);
  const isLoanTypesLoading = useSelector(pageLoanTypesLoading);
  const isPropertyTypesLoading = useSelector(pagePropertyTypesLoading);
  const isStateProvincesLoading = useSelector(pageStateProvincesLoading);
  const isCategoriesLoading = useSelector(pageCategoriesLoading);
  const isConsultantCategoriesLoading = useSelector(consultantCategoriesLoading);


  const [page, setPage] = useState(1);
  const [rowKeys, setRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rowDataState, setRowDataState] = useState(null);

  const fetchActions = {
    propertyClasses: getPagePropertyClasses,
    markets: getPageMarkets,
    loanTypes: getPageLoanTypes,
    propertyTypes: getPagePropertyTypes,
    stateProvinces: getPageStateProvinces,
    categories: getPageCategories,
    consultantCategories: getConsultantCategories,
  };

  const deleteActions = {
    propertyClasses: deletePropertyClasses,
    markets: deleteMarkets,
    loanTypes: deleteLoanTypes,
    propertyTypes: deletePropertyTypes,
    stateProvinces: deleteStateProvinces,
    categories: deleteCategories,
    consultantCategories: deleteConsultantCategories,
  };

  const createActions = {
    propertyClasses: addNewPropertyClasses,
    markets: addNewMarkets,
    loanTypes: addNewLoanTypes,
    propertyTypes: addNewPropertyTypes,
    stateProvinces: addNewStateProvinces,
    categories: addNewCategories,
    consultantCategories: addNewConsultantCategories,
  };

  const updateActions = {
    propertyClasses: updatePropertyClasses,
    markets: updateMarkets,
    loanTypes: updateLoanTypes,
    propertyTypes: updatePropertyTypes,
    stateProvinces: updateStateProvinces,
    categories: updateCategories,
    consultantCategories: updateConsultantCategories,
  };

  const loading = {
    propertyClasses: isPropertyClassesLoading,
    markets: isMarketsLoading,
    loanTypes: isLoanTypesLoading,
    propertyTypes: isPropertyTypesLoading,
    stateProvinces: isStateProvincesLoading,
    categories: isCategoriesLoading,
    consultantCategories: isConsultantCategoriesLoading,
  };

  const error = useMemo(() => ({
    propertyClasses: getPropertyClassesErrors,
    markets: getMarketsErrors,
    loanTypes: getLoanTypesErrors,
    propertyTypes: getPropertyTypesErrors,
    stateProvinces: getStateProvincesErrors,
    categories: getCategoriesErrors,
    consultantCategories: getConsultantCategoriesErrors,
  }), [
    getCategoriesErrors,
    getLoanTypesErrors,
    getMarketsErrors,
    getPropertyClassesErrors,
    getPropertyTypesErrors,
    getStateProvincesErrors,
    getConsultantCategoriesErrors,
  ]);

  const assets = {
    propertyClasses,
    markets,
    loanTypes,
    propertyTypes,
    stateProvinces,
    categories,
    consultantCategories,
  };

  useEffect(() => {
    dispatch(fetchActions[currentAssetType].request({ $skip: (page - 1) * PAGE_SIZE, '$sort[updatedAt]': -1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentAssetType]);

  useEffect(() => {
    setPage(1);
  }, [currentAssetType]);

  useEffect(() => {
    error[currentAssetType] && error[currentAssetType].forEach((err) => message.error(`Error ${err.code} ${err.message}`));
  }, [currentAssetType, error]);

  const columns = [
    ...assetsConfig[currentAssetType].columns,
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 100,
      render: (text, record, index) => record.hovered
        && <Button type='default' size='small' onClick={() => onEditAssetHandler(record)}>
          <Icon type='edit' theme='filled' size='large' />
            {editButtonMessage}
          </Button>
      ,
    },
  ];

  const deleteAssets = useCallback(
    () => {
      dispatch(deleteActions[currentAssetType].request({ 'id[$in]': rowKeys }));
      setRowKeys([]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowKeys],
  );

  const showDeleteDialog = useCallback(() => {
    confirm({
      title: `${deleteAssetConfirmMessage + assetsConfig[currentAssetType].name}(s)`,
      okType: 'danger',
      onOk() {
        deleteAssets();
      },
    });
  }, [currentAssetType, deleteAssets]);
  const handleModalCancel = useCallback(() => {
    const { form } = formRef.current;
    form.resetFields();
    setIsModalVisible(false);
  }, []);

  const handleModalSubmit = useCallback(() => {
    const { form } = formRef.current;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      rowDataState
        ? dispatch(updateActions[currentAssetType].request({ id: rowDataState.id, values }))
        : dispatch(createActions[currentAssetType].request(values));
      form.resetFields();
      setIsModalVisible(false);
    });
  }, [createActions, currentAssetType, dispatch, rowDataState, updateActions]);

  const onCreateNewAssetHandler = useCallback(() => {
    setRowDataState(null);
    setIsModalVisible(true);
  }, []);

  const onEditAssetHandler = useCallback((rowData) => {
    setRowDataState(rowData);
    setIsModalVisible(true);
  }, []);

  return (
    <div>
      <Title level={4}>
        {assetsConfig[currentAssetType].titleMessage}
        <Button type='success' size='large' onClick={onCreateNewAssetHandler}>
          {addNewButtonMessage + assetsConfig[currentAssetType].name}
        </Button>
      </Title>
      <div>
        <Table
          loading={loading[currentAssetType]}
          rowKey='id'
          pagination={{
            current: page,
            total: assets[currentAssetType].total,
            pageSize: PAGE_SIZE,
            onChange: (current) => setPage(current),
          }}
          dataSource={[...assets[currentAssetType].data]}
          columns={columns}
          rowSelection={{
            selectedRowKeys: rowKeys,
            onChange: (selectedRowKeys) => setRowKeys(selectedRowKeys),
          }}
          />
        <Footer>
          <Button
            type='danger'
            size='large'
            onClick={showDeleteDialog}
            disabled={!rowKeys.length}
            >
            <Icon type='delete' theme='filled' />
          </Button>
          <Button type='danger' size='large' onClick={() => setRowKeys([])}>
            {unselectButtonMassage}
            <Icon type='reload' size='large' />
          </Button>
        </Footer>
        <AssetsFormModal
          wrappedComponentRef={formRef}
          visible={isModalVisible}
          onCancel={handleModalCancel}
          onSubmit={handleModalSubmit}
          assetToEdit={rowDataState}
          currentAssetType={currentAssetType}
        />
      </div>
    </div>
  );
};

AssetCompinent.propTypes = {
  getPropertyClassesErrors: PropTypes.array,
  getMarketsErrors: PropTypes.array,
  getLoanTypesErrors: PropTypes.array,
  getPropertyTypesErrors: PropTypes.array,
  getStateProvincesErrors: PropTypes.array,
  getCategoriesErrors: PropTypes.array,
  propertyClasses: PropTypes.array,
  markets: PropTypes.array,
  loanTypes: PropTypes.array,
  propertyTypes: PropTypes.array,
  stateProvinces: PropTypes.array,
  categories: PropTypes.array,
  isPropertyClassesLoading: PropTypes.bool,
  isMarketsLoading: PropTypes.bool,
  isLoanTypesLoading: PropTypes.bool,
  isPropertyTypesLoading: PropTypes.bool,
  isStateProvincesLoading: PropTypes.bool,
  isCategoriesLoading: PropTypes.bool,
};

export default React.memo(AssetCompinent);
