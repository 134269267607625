import { getContacts, getPageContacts } from 'actions/contacts';

const contacts = () => {
  const initialState = {};

  return (state = initialState, { type, response, ids }) => {
    switch (type) {
      case getContacts.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getPageContacts.requestTypes.SUCCESS:
        return {
          ...response,
        };
      default:
        return state;
    }
  };
};

export default contacts();
