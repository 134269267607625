const routes = {
  userCompanies: {
    title: 'Borrower Companies',
    table: {
      pathname: '/user-companies',
    },
  },
  users: {
    title: 'Users',
    table: {
      pathname: '/users',
    },
    form: {
      pathname: '/users/form',
    },
  },
  lenders: {
    title: 'Lenders',
    table: {
      pathname: '/lenders',
    },
    form: {
      pathname: '/lenders/form',
    },
  },
  consultants: {
    title: 'Consultants',
    table: {
      pathname: '/consultants',
    },
    form: {
      pathname: '/consultants/form',
    },
  },
  services: {
    title: 'Services',
    pathname: '/services',
    drone: {
      title: 'Drones',
      table: {
        pathname: '/services/drone',
      },
      form: {
        pathname: '/services/drone/form',
      },
    },
    lease: {
      title: 'Leases',
      table: {
        pathname: '/services/lease',
      },
      form: {
        pathname: '/services/lease/form',
      },
    },
    inspection: {
      title: 'Inspections',
      table: {
        pathname: '/services/inspection',
      },
      form: {
        pathname: '/services/inspection/form',
      },
    },
  },
  roleManagement: {
    title: 'Role Management',
    pathname: '/role-management',
  },
  settings: {
    title: 'Settings',
    pathname: '/settings',
    manageAssets: {
      title: 'Manage Assets',
      pathname: '/manage-assets',
      propertyClasses: {
        title: 'Property Classes',
        pathname: '/property-classes',
      },
      markets: {
        title: 'Markets',
        pathname: '/markets',
      },
      loanTypes: {
        title: 'Loan Types',
        pathname: '/loan-types',
      },
      propertyTypes: {
        title: 'Property Types',
        pathname: '/property-types',
      },
      stateProvinces: {
        title: 'States and Provinces',
        pathname: '/state-provinces',
      },
      categories: {
        title: 'Lender Categories',
        pathname: '/categories',
      },
      consultantCategories: {
        title: 'Consultant Categories',
        pathname: '/consultant-categories',
      },
    },
    emailTemplates: {
      title: 'Email Templates',
      table: {
        pathname: '/email-templates',
      },
      form: {
        pathname: '/email-templates/form',
      },
    },
  },
  login: {
    pathname: '/login',
  },
  formBuilder: {
    title: 'Form builder',
    pathname: '/form-builder',
    pathnameConfig: '/form-builder/:id?',
  },
  contentImporter: {
    title: 'Content Importer',
    pathname: '/content-importer',
  },
};

export default routes;
