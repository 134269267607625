import { makeRequestAction } from './index';

export const getLoanTypes = makeRequestAction('GET_LOAN_TYPES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addNewLoanTypes = makeRequestAction('ADD_NEW_LOAN_TYPES');

export const deleteLoanTypes = makeRequestAction('DELETE_LOAN_TYPES', {
  onSuccess(params, response) {
    return {
      ids: response.map((len) => len.id),
    };
  },
});

export const updateLoanTypes = makeRequestAction('UPDATE_LOAN_TYPES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getPageLoanTypes = makeRequestAction('GET_PAGE_LOAN_TYPES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
