import { importBrokerListings } from 'actions/brokerListings';

const brokerListings = () => {
  const initialState = {};

  return (state = initialState, { type, response }) => {
    switch (type) {
      case importBrokerListings.requestTypes.SUCCESS:
        return {
          message: response,
        };
      default:
        return state;
    }
  };
};

export default brokerListings();
