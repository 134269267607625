import { createSelector } from 'reselect';
import { importBrokerListings } from 'actions/brokerListings';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getListingsState = (state) => state.brokerListings;
export const brokerListingsImported = createSelector(
  getListingsState,
  (state) => state,
);

export const importListingsLoading = createLoadingSelector(
  importBrokerListings.actionName,
)();
export const importListingsError = createErrorSelector(importBrokerListings.actionName)();
