import React, {
  useEffect, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Icon, Upload, message,
} from 'antd';
import { Button } from 'components';
import { importBrokerListings } from 'actions/brokerListings';
import {
  importListingsError,
  importListingsLoading,
  brokerListingsImported,
} from 'selectors/brokerListings';

const ContentImporter = () => {
  const dispatch = useDispatch();
  const getImportedListings = useSelector(brokerListingsImported);
  const getImportListingsError = useSelector(importListingsError);
  const getImportListingsLoading = useSelector(importListingsLoading);

  useEffect(() => {
    if (Object.keys(getImportedListings).length > 0) {
      message.success(JSON.stringify(getImportedListings));
    }

    if (getImportListingsError) {
      message.error(`Error: ${getImportListingsError.message}`);
    }
  }, [getImportedListings, getImportListingsError]);

  const importLisitngs = useCallback(async (file) => {
    if (file.type.includes('text/csv')) {
      dispatch(importBrokerListings.request({ file }));
    } else {
      message.error('File must be an CSV!');
    }
  }, [dispatch]);

  return (
    <Row>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={6}>
            <Upload
              accept='.csv'
              showUploadList={false}
              beforeUpload={(file) => {
                importLisitngs(file);
                return false;
              }}
            >
              <Button loading={getImportListingsLoading}>
                {!getImportListingsLoading && <Icon type='upload' />}{' '}
                {'Upload Broker Listings'}
              </Button>
            </Upload>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ContentImporter;
