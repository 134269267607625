import { API_ROOT } from 'configs/envVars';
import { makeRequestAction } from './index';

/**
 * Get lenders
 */
export const getLenders = makeRequestAction('GET_LENDERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getAllLenders = makeRequestAction('GET_ALL_LENDERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getLender = makeRequestAction('GET_LENDER', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const importLenders = makeRequestAction('IMPORT_LENDERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const importLendersData = makeRequestAction('IMPORT_LENDERS_DATA', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const importLendersContacts = makeRequestAction('IMPORT_LENDERS_CONTACTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});


export const addNewLender = makeRequestAction('ADD_NEW_LENDER');
export const deleteLenders = makeRequestAction('DELETE_LENDERS', {
  onSuccess(params, response) {
    return {
      ids: response.map((len) => len.id),
    };
  },
});

export const updateLender = makeRequestAction('UPDATE_LENDER');

export const addLenderLogo = makeRequestAction('ADD_LENDER_UPLOADS', {
  onSuccess(params, response) {
    return {
      url: `${API_ROOT}/file-storage/${response[0].hashedId}`,
    };
  },
});

export const deleteLenderLogo = makeRequestAction('DELETE_LENDER_UPLOADS', {
  onSuccess(params, response) {
    return {
      url: '',
    };
  },
});
