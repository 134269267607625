import {
  call, takeLatest, takeEvery, select,
} from 'redux-saga/effects';
import {
  getUsers, deleteUsers, addNewUsers, updateUsers, getPageUsers,
} from 'actions/users';
import {
  getUsersApi, deleteUsersApi, addNewUserApi, updateUsersApi,
} from 'services/users';
import fetchEntity from './fetch-entity';

const fetchUsers = fetchEntity.bind(null, getUsers.actions, getUsersApi);
const fetchPageUsers = fetchEntity.bind(null, getPageUsers.actions, getUsersApi);
const fetchAddNewUser = fetchEntity.bind(
  null,
  addNewUsers.actions,
  addNewUserApi,
);

const fetchUpdateUser = fetchEntity.bind(
  null,
  updateUsers.actions,
  updateUsersApi,
);

const fetchDeleteUsers = fetchEntity.bind(
  null,
  deleteUsers.actions,
  deleteUsersApi,
);

export function* loadGetPageUsers({ params }) {
  yield call(fetchPageUsers, { ...params, '$sort[updatedAt]': -1 });
}

function* watchGetPageUsers() {
  yield takeLatest([getPageUsers.actionName], loadGetPageUsers);
}

export function* loadGetPageUsersOnChange({ params }) {
  const users = yield select((state) => state.users);
  const { total, skip, limit } = users;
  if (skip >= total) {
    yield call(fetchPageUsers, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchPageUsers, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetPageUsersOnChange() {
  yield takeLatest([
    deleteUsers.requestTypes.SUCCESS,
    addNewUsers.requestTypes.SUCCESS,
    updateUsers.requestTypes.SUCCESS,
  ], loadGetPageUsersOnChange);
}

export function* loadGetUsers({ params }) {
  yield call(fetchUsers, { ...params, '$sort[updatedAt]': -1 });
}
function* watchGetUsers() {
  yield takeEvery(getUsers.actionName, loadGetUsers);
}

export function* loadUpdateUsers({ params }) {
  yield call(fetchUpdateUser, { ...params });
}

function* watchUpdateUsers() {
  yield takeLatest(updateUsers.actionName, loadUpdateUsers);
}

export function* loadAddNewUser({ params }) {
  yield call(fetchAddNewUser, { ...params });
}

function* watchAddNewUser() {
  yield takeLatest(addNewUsers.actionName, loadAddNewUser);
}
export function* loadDeleteUsers({ params }) {
  yield call(fetchDeleteUsers, { ...params });
}

function* watchDeleteUsers() {
  yield takeLatest(deleteUsers.actionName, loadDeleteUsers);
}

export default {
  watchGetUsers,
  watchUpdateUsers,
  watchAddNewUser,
  watchDeleteUsers,
  watchGetPageUsers,
  watchGetPageUsersOnChange,
};
