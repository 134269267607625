import { call, takeLatest } from 'redux-saga/effects';
import { importBrokerListings } from 'actions/brokerListings';
import { postImportListingsApi } from 'services/brokerListings';
import fetchEntity from './fetch-entity';

const fetchImportListings = fetchEntity.bind(
  null,
  importBrokerListings.actions,
  postImportListingsApi,
);

export function* loadImportListings({ params }) {
  yield call(fetchImportListings, { ...params });
}

function* watchImportListings() {
  yield takeLatest(importBrokerListings.actionName, loadImportListings);
}

export default {
  watchImportListings,
};
