import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal, message } from 'antd';
import {
  Button, Input, FormItem, Select,
} from 'components';

import { addForm } from 'actions/formBuilder';
import { getSuccessState, addFormLoading, addFormError } from 'selectors/formBuilder';

import { messages } from 'messages';
import formItemsConfig from './formItemsConfig';

const { Option } = Select;

const {
  formNameInput,
  loanTypeSelect,
} = formItemsConfig;

const {
  addNewButtonMessage,
  submitSuccessMessage,
} = messages.formBuilder.form;

const AddNewForm = (props) => {
  const { form } = props;
  const { getFieldDecorator } = form;

  const dispatch = useDispatch();
  const isAddLoading = useSelector(addFormLoading);
  const addError = useSelector(addFormError);
  const isSubmitSuccessful = useSelector(getSuccessState);

  const [modalVisibility, setModalVisibility] = useState(false);

  const closeModal = () => {
    setModalVisibility(false);
    form.resetFields();
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      message.success(submitSuccessMessage);
      closeModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    addError && message.error(`Error ${addError.code} ${addError.message}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(addError)]);

  const submitForm = useCallback((values) => {
    dispatch(addForm.request(values));
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        submitForm(values);
      }
      return true;
    });
  }, [form, submitForm]);

  return <>
    <Button
      type={'primary'}
      onClick={() => setModalVisibility(true)}
      style={{ marginLeft: 10 }}
    >
      {addNewButtonMessage}
    </Button>
    <Modal
      title={addNewButtonMessage}
      visible={modalVisibility}
      onOk={handleSubmit}
      okText={'Create'}
      okButtonProps={{
        loading: isAddLoading,
      }}
      onCancel={closeModal}
    >
      <Form
        layout={'vertical'}
        labelAlign={'left'}
        hideRequiredMark
       >
        <FormItem label={formNameInput.label}>
          {getFieldDecorator(formNameInput.fieldName, {
            ...formNameInput.decoratorOptions,
          })(<Input {...formNameInput.elementProps} />)}
        </FormItem>
        <FormItem label={loanTypeSelect.label}>
          {getFieldDecorator(loanTypeSelect.fieldName, {
            ...loanTypeSelect.decoratorOptions,
          })(<Select {...loanTypeSelect.elementProps}>
            {loanTypeSelect.options.map((option) => <Option key={option.value} value={option.value} >{option.name}</Option>)}
          </Select>)}
        </FormItem>
      </Form>
    </Modal>
  </>;
};

export default Form.create('add-new-form')(React.memo(AddNewForm));
