import { userLogin, userLogout } from 'actions/auth';
import { emptyState } from 'configs/localStorage';

const auth = () => {
  const initialState = {
    loggedInUser: null,
  };

  return (state = initialState, {
    type, error, record, loggedInUser,
  }) => {
    switch (type) {
      case userLogin.requestTypes.SUCCESS:
        return {
          ...state,
          loggedInUser,
        };
      case userLogout.requestTypes.SUCCESS:
        emptyState();
        return initialState;
      case userLogout.requestTypes.FAILURE:
        emptyState();
        return initialState;
      default:
        return state;
    }
  };
};
export default auth();
