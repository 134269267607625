import React from 'react';
import {
  Route, Switch, withRouter, Redirect,
} from 'react-router-dom';
import routes from 'constants/routes';
import { isAuth, isNotAuth } from 'configs/auth';

import UsersTable from 'containers/Users/Table';
import UsersForm from 'containers/Users/Form';
import LendersTable from 'containers/Lenders/Table';
import LendersForm from 'containers/Lenders/Form';
import SettingsPage from 'containers/Settings';
import LoginPage from 'containers/LoginPage';
import AssetComponent from 'containers/Assets/AssetComponent';
import ManageAssets from 'containers/Settings/ManageAssets';
import FormBuilderPage from 'containers/FormBuilder';
import ServicesTable from 'containers/Services/Table';
import ServicesForm from 'containers/Services/Form';
import UserCompaniesTable from 'containers/UserCompanies/Table';
import EmailTemplatesTable from 'containers/Settings/EmailTemplates/Table';
import RoleManagement from 'containers/RoleManagement';
import EmailTemplatesForm from 'containers/Settings/EmailTemplates/Form';
import ConsultantsTable from 'containers/Consultants/Table';
import ConsultantsForm from 'containers/Consultants/Form';
import ContentImporter from 'containers/ContentImporter';

const Routes = (props) => (
  <Switch>
    <Route exact path={routes.users.table.pathname} component={isAuth(UsersTable)} />
    <Route exact path={routes.lenders.table.pathname} component={isAuth(LendersTable)} />
    <Route exact path={routes.settings.pathname} component={isAuth(SettingsPage)} />
    <Route exact path={routes.login.pathname} component={isNotAuth(LoginPage)} />
    <Route exact path={routes.users.form.pathname} component={isAuth(UsersForm)} />
    <Route exact path={routes.lenders.form.pathname} component={isAuth(LendersForm)} />
    <Route exact path={routes.settings.pathname} component={isAuth(SettingsPage)} />
    <Route exact path={routes.settings.pathname} component={isAuth(SettingsPage)} />
    <Route exact path={routes.settings.manageAssets.pathname} component={isAuth(ManageAssets)} />
    <Route exact path={routes.settings.manageAssets.loanTypes.pathname} component={isAuth(AssetComponent)} />
    <Route exact path={routes.settings.manageAssets.markets.pathname} component={isAuth(AssetComponent)} />
    <Route exact path={routes.settings.manageAssets.propertyClasses.pathname} component={isAuth(AssetComponent)} />
    <Route exact path={routes.settings.manageAssets.propertyTypes.pathname} component={isAuth(AssetComponent)} />
    <Route exact path={routes.settings.manageAssets.stateProvinces.pathname} component={isAuth(AssetComponent)} />
    <Route exact path={routes.settings.manageAssets.categories.pathname} component={isAuth(AssetComponent)} />
    <Route exact path={routes.settings.manageAssets.consultantCategories.pathname} component={isAuth(AssetComponent)} />
    <Route exact path={routes.settings.emailTemplates.table.pathname} component={isAuth(EmailTemplatesTable)} />
    <Route exact path={routes.roleManagement.pathname} component={isAuth(RoleManagement)} />
    <Route exact path={routes.settings.emailTemplates.form.pathname} component={isAuth(EmailTemplatesForm)} />
    <Route exact path={routes.formBuilder.pathnameConfig} component={isAuth(FormBuilderPage)} />
    <Route exact path={`${routes.services.pathname}/:type`} component={isAuth(ServicesTable)} />
    <Route exact path={`${routes.services.pathname}/:type/form`} component={isAuth(ServicesForm)} />
    <Route exact path={routes.userCompanies.table.pathname} component={isAuth(UserCompaniesTable)} />
    <Route exact path={routes.consultants.table.pathname} component={isAuth(ConsultantsTable)} />
    <Route exact path={routes.consultants.form.pathname} component={isAuth(ConsultantsForm)} />
    <Route exact path='/' component={isAuth(() => <Redirect to={routes.lenders.table.pathname} />)} />
    <Route exact path={routes.contentImporter.pathname} component={isAuth(ContentImporter)} />
  </Switch>
);

export default withRouter(Routes);
