import { createSelector } from 'reselect';
import {
  getServiceRoles as fetchServiceRoles, getPageServiceRoles, addNewServiceRoles, deleteServiceRoles, updateServiceRoles,
} from 'actions/roleManagements';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getServiceRolesState = (state) => state.serviceRoles;
export const getErrorsState = (state) => state.error;

export const getServiceRoles = createSelector(
  getServiceRolesState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const getSuccessState = createSelector(
  getServiceRolesState,
  (state) => state.isSuccessful,
);

export const serviceRolesLoading = createLoadingSelector(fetchServiceRoles.actionName)();
export const serviceRolesError = createErrorSelector(fetchServiceRoles.actionName)();


export const pageServiceRolesLoading = createLoadingSelector(getPageServiceRoles.actionName)();
export const pageServiceRolesError = createErrorSelector(getPageServiceRoles.actionName)();

export const addNewServiceRolesLoading = createLoadingSelector(addNewServiceRoles.actionName)();
export const addNewServiceRolesError = createErrorSelector(addNewServiceRoles.actionName)();

export const deleteServiceRolesLoading = createLoadingSelector(deleteServiceRoles.actionName)();
export const deleteServiceRolesError = createErrorSelector(deleteServiceRoles.actionName)();

export const getServiceRolesAllErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      addNewServiceRoles.actionName,
      deleteServiceRoles.actionName,
      updateServiceRoles.actionName,
      getPageServiceRoles.actionName,
      getServiceRoles.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);
