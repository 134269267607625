import { messages } from 'messages';

const filterOption = (value, option) => option.props.children.toLowerCase().includes(value);

const {
  businessNameSelectMessages,
  emailInputMessages,
  passwordInputMessages,
  repeatPasswordInputMessages,
  verifiedSwitchMessages,
  approvedSwitchMessages,
  activeSwitchMessages,
  accountExpireDateInputMessages,
  userRoleSelectMessages,
  parentSelectMessages,
  firstLoginInputMessages,
  createdAtInputMessages,
  updatedAtInputMessages,
  dealEmailLimitInputMessages,
  firstNameInputMessages,
  lastNameInputMessages,
  jobTitleInputMessages,
  addressInputMessages,
  usernameInputMessages,
  postalInputMessages,
  phoneNumberInputMessages,
  faxInputMessages,
  accessLendersInputMessages,
  accessConsultantsInputMessages,
  isBDUserInputMessage,
  cityInputMessages,
  provinceInputMessages,
  countryInputMessages,
  timeZoneInputMessages,
} = messages.usersCrudMessages;

const formItemsConfig = {
  businessNameSelect: {
    label: businessNameSelectMessages.label,
    fieldName: 'userCompanyName',
    decoratorOptions: {
      rules: [{ required: true, message: businessNameSelectMessages.required }],
    },
    elementProps: {},
  },
  emailInput: {
    label: emailInputMessages.label,
    fieldName: 'email',
    decoratorOptions: {
      rules: [
        { required: true, message: emailInputMessages.required },
        { type: 'email', message: emailInputMessages.invalid },
      ],
    },
    elementProps: {},
  },

  usernameInput: {
    label: usernameInputMessages.label,
    fieldName: 'username',
    decoratorOptions: {
      rules: [
        { required: true, message: usernameInputMessages.required },
        { pattern: /^[a-z0-9]+$/i, message: usernameInputMessages.invalid },
      ],
    },
    elementProps: {},
  },
  passwordInput: {
    label: passwordInputMessages.label,
    fieldName: 'password',
    decoratorOptions: {
      rules: [
        { required: true, message: passwordInputMessages.required },
        { pattern: new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&])(?=.{6,})'), message: passwordInputMessages.invalid },
      ],
    },
    elementProps: {},
  },
  repeatPasswordInput: {
    label: repeatPasswordInputMessages.label,
    fieldName: 'repeatPassword',
    decoratorOptions: { rules: [{ required: true, message: repeatPasswordInputMessages.required }] },
    elementProps: {},
  },
  verifiedSwitch: {
    label: verifiedSwitchMessages.label,
    fieldName: 'isVerified',
    decoratorOptions: {
      valuePropName: 'checked',
    },
    elementProps: {},
  },
  approvedSwitch: {
    label: approvedSwitchMessages.label,
    fieldName: 'approved',
    decoratorOptions: {
      valuePropName: 'checked',
    },
    elementProps: {},
  },
  activeSwitch: {
    label: activeSwitchMessages.label,
    fieldName: 'isActive',
    decoratorOptions: {
      valuePropName: 'checked',
    },
    elementProps: {},
  },
  accountExpireDateInput: {
    label: accountExpireDateInputMessages.label,
    fieldName: 'accountExpireDate',
    decoratorOptions: {},
    elementProps: { style: { width: '100%' } },
  },
  userRoleSelect: {
    label: userRoleSelectMessages.label,
    fieldName: 'roles',
    decoratorOptions: {},
    options: ['Self Serve'],
    elementProps: {},
  },
  parentSelect: {
    label: parentSelectMessages.label,
    fieldName: 'parent',
    decoratorOptions: {},
    elementProps: {
      filterOption,
      showSearch: true,
    },
  },
  firstLoginInput: {
    label: firstLoginInputMessages.label,
    fieldName: 'firstLogin',
    decoratorOptions: {},
    elementProps: { disabled: true },
  },
  createdAtInput: {
    label: createdAtInputMessages.label,
    fieldName: 'createdAt',
    decoratorOptions: {},
    elementProps: { disabled: true },
  },
  updatedAtInput: {
    label: updatedAtInputMessages.label,
    fieldName: 'updatedAt',
    decoratorOptions: {},
    elementProps: { disabled: true },
  },
  accessLendersInput: {
    label: accessLendersInputMessages.label,
    fieldName: 'accessLenders',
    decoratorOptions: {},
    elementProps: {},
  },
  accessConsultantsInput: {
    label: accessConsultantsInputMessages.label,
    fieldName: 'accessConsultants',
    decoratorOptions: {},
    elementProps: {},
  },
  dealEmailLimitInput: {
    label: dealEmailLimitInputMessages.label,
    fieldName: 'dealEmailLimit',
    decoratorOptions: {},
    elementProps: {},
  },
  firstNameInput: {
    label: firstNameInputMessages.label,
    fieldName: 'firstName',
    decoratorOptions: {
      rules: [{ required: true, message: firstNameInputMessages.required }],
    },
    elementProps: {},
  },
  lastNameInput: {
    label: lastNameInputMessages.label,
    fieldName: 'lastName',
    decoratorOptions: {
      rules: [{ required: true, message: lastNameInputMessages.required }],
    },
    elementProps: {},
  },
  jobTitleInput: {
    label: jobTitleInputMessages.label,
    fieldName: 'jobTitle',
    decoratorOptions: {},
    elementProps: {},
  },
  addressInput: {
    label: addressInputMessages.label,
    fieldName: 'address',
    decoratorOptions: {},
    elementProps: {},
  },
  cityInput: {
    label: cityInputMessages.label,
    fieldName: 'city',
    decoratorOptions: {},
    elementProps: {},
  },
  provinceInput: {
    label: provinceInputMessages.label,
    fieldName: 'province',
    decoratorOptions: {},
    elementProps: {
      filterOption,
      showSearch: true,
    },
  },
  countryInput: {
    label: countryInputMessages.label,
    fieldName: 'country',
    decoratorOptions: {},
    elementProps: {},
  },
  timeZoneInput: {
    label: timeZoneInputMessages.label,
    fieldName: 'timeZone',
    decoratorOptions: {},
    elementProps: {
      filterOption,
      showSearch: true,
    },
  },
  postalInput: {
    label: postalInputMessages.label,
    fieldName: 'postal',
    decoratorOptions: {
      rules: [
        { pattern: /(^[0-9]{5}(?:-[0-9]{4})?$)|(^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$)/, message: postalInputMessages.invalid },
      ],
    },
    elementProps: {
      placeholder: 'e.g. 12345-6789, A1A 1A1',
    },
  },
  phoneNumberInput: {
    label: phoneNumberInputMessages.label,
    fieldName: 'phoneNumber',
    decoratorOptions: {
      rules: [
        { pattern: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/, message: phoneNumberInputMessages.invalid },
      ],
    },
    elementProps: {
      placeholder: 'e.g. (123) 456-7890',
      format: '(###) ###-####',
      mask: '_',
    },
  },
  faxInput: {
    label: faxInputMessages.label,
    fieldName: 'fax',
    decoratorOptions: {
      rules: [
        { pattern: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/, message: faxInputMessages.invalid },
      ],
    },
    elementProps: {
      placeholder: 'e.g. (123) 456-7890',
      format: '(###) ###-####',
      mask: '_',
    },
  },
  isBDUserInput: {
    label: isBDUserInputMessage.label,
    fieldName: 'isBDUser',
    decoratorOptions: {
      valuePropName: 'checked',
    },
    elementProps: {},
  },
};

export default formItemsConfig;
