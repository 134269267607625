import React, {
  useEffect, useState, useCallback,
} from 'react';
import { Button, Table, Title } from 'components';
import styled from 'styled-components';
import {
  emailTemplatesLoading as getLoadingSelector,
  pageEmailTemplatesLoading as getPageLoadingSelector,
  deleteEmailTemplatesLoading as deleteLoadingSelector,
  emailTemplatesError as getErrorSelector,
  deleteEmailTemplatesError as deleteErrorSelector,
  getEmailTemplates,
} from 'selectors/emailTemplates';
import {
  Icon, Modal, message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEmailTemplates as getEmailTemplatesAction,
  deleteEmailTemplates as deleteEmailTemplatesAction,
} from 'actions/emailTemplates';
import { Link } from 'react-router-dom';

import routes from 'constants/routes';
import { messages } from 'messages';

const {
  emailTemplateListTitleMessage,
  newEmailTemplateButtonMessage,
  unselectButtonMessage,
  emailTemplateEditButtonMessage,
  confirmDeleteMessage,
  templateNameColumnNameMessage,
  subjectColumnNameMessage,
  htmlColumnNameMessage,
  textColumnNameMessage,
} = messages.emailTemplates.table;

const { confirm } = Modal;

const columns = [
  {
    title: templateNameColumnNameMessage,
    dataIndex: 'templateName',
    key: 'templateName',
  },
  {
    title: subjectColumnNameMessage,
    dataIndex: 'subject',
    key: 'subject',
  },
  {
    title: textColumnNameMessage,
    dataIndex: 'text',
    key: 'text',
  },
  {
    title: htmlColumnNameMessage,
    dataIndex: 'html',
    key: 'html',
  },
  {
    title: '',
    dataIndex: 'edit',
    key: 'edit',
    width: 100,
    render: (text, record) => record.hovered
      && <Link
        to={{
          pathname: routes.settings.emailTemplates.form.pathname,
          state: { currentEmailTemplate: record },
        }}
      >
        <Button type='default' size='small'>
          <Icon type='edit' theme='filled' />
          {emailTemplateEditButtonMessage}
        </Button>
      </Link>,
  },
];

const Footer = styled.div`
    margin-top: 10px;
    float: right;
  
    button {
      margin-left: 10px;
      margin-right: 10px;
    }
  `;

const EmailTemplatesTable = (props) => {
  const PAGE_SIZE = 10;
  const dispatch = useDispatch();

  const emailTemplateCompanyId = props.location.state && props.location.state.emailTemplateCompanyId;
  const emailTemplates = useSelector(getEmailTemplates);
  const getLoading = useSelector(getLoadingSelector);
  const getPageLoading = useSelector(getPageLoadingSelector);
  const deleteLoading = useSelector(deleteLoadingSelector);
  const getError = useSelector(getErrorSelector);
  const deleteError = useSelector(deleteErrorSelector);

  const [page, setPage] = useState(1);
  const [rowKeys, setRowKeys] = useState([]);

  useEffect(() => {
    dispatch(getEmailTemplatesAction.request({ $skip: (page - 1) * PAGE_SIZE, companyId: emailTemplateCompanyId }));
  }, [dispatch, page, emailTemplateCompanyId]);

  useEffect(() => {
    getError && message.error(`Error ${getError.code}: ${getError.message}`);
    deleteError
        && message.error(`Error ${deleteError.code}: ${deleteError.message}`);
  }, [deleteError, getError]);

  const deleteEmailTemplates = useCallback(
    () => {
      dispatch(deleteEmailTemplatesAction.request({ 'templateName[$in]': rowKeys }));
      setRowKeys([]);
    }, [dispatch, rowKeys],
  );

  const showDeleteDialog = () => {
    confirm({
      title: confirmDeleteMessage,
      okType: 'danger',
      onOk() {
        deleteEmailTemplates();
      },
    });
  };

  return (
    <div>
      <Title level={4}>
        {emailTemplateListTitleMessage}
        <Link
          to={{
            pathname: routes.settings.emailTemplates.form.pathname,
            state: { currentEmailTemplate: null },
          }}
          >
          <Button type='success' size='large'>
            {newEmailTemplateButtonMessage}
          </Button>
        </Link>
      </Title>
      <div>
        <Table
          loading={getLoading || deleteLoading || getPageLoading}
          rowKey='templateName'
          pagination={{
            current: page,
            total: emailTemplates.total,
            pageSize: PAGE_SIZE,
            onChange: (current) => setPage(current),
          }}
          dataSource={emailTemplates.data}
          columns={columns}
          rowSelection={{
            selectedRowKeys: rowKeys,
            onChange: (selectedRowKeys) => setRowKeys(selectedRowKeys),
          }}
          />
        <Footer>
          <Button
            type='danger'
            size='large'
            onClick={showDeleteDialog}
            disabled={!rowKeys.length}
            >
            <Icon type='delete' theme='filled' />
          </Button>
          <Button type='danger' size='large' onClick={() => setRowKeys([])}>
            {unselectButtonMessage}
            <Icon type='reload' />
          </Button>
        </Footer>
      </div>
    </div>
  );
};

export default EmailTemplatesTable;
