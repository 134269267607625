import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Icon, Row, Col, Typography, Modal, Form, message,
} from 'antd';
import { StarFilled } from '@ant-design/icons';
import styled from 'styled-components';

import {
  Table, Button, FormItem, Input, Select, Switch,
} from 'components';
import { messages } from 'messages';
import {
  getConsultantContacts as fetchConsultantContacts,
  addNewConsultantContact,
  updateConsultantContacts,
  deleteConsultantContacts,
} from 'actions/consultantContacts';
import {
  getConsultantContacts,
  consultantContactsLoading,
  pageConsultantContactsLoading,
  addNewConsultantContactLoading,
  updateConsultantContactsLoading,
  deleteConsultantContactsLoading,
  getConsultantContactsAllErrors,
} from 'selectors/consultantContacts';
import { getConsultants, getLoading } from 'selectors/consultants';

import consultantContactFormConfig from './contactFormConfig';

const { Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const ConsultantContactAddButton = styled(Button)`
  position: absolute;
  right: 0 
`;

const {
  title,
  fullNameColumnName,
  featuredColumnName,
  approvedColumnName,
  titleColumnName,
  editButtonMessage,
  deleteButtonMessage,
  addButtonMessage,
  deleteConfirmMessage,
  createModalActionName,
  editModalActionName,
} = messages.landersCrudMessages.contactsMessages;

const {
  consultantSelect,
  featuredSwitch,
  createdByInput,
  approvedSwitch,
  emailInput,
  firstNameInput,
  lastNameInput,
  titleInput,
  phoneInput,
  linkedInUrlInput,
  notesInput,
  countrySelect,
  provinceSelect,
  cityInput,
  faxInput,
  mobileInput,
} = consultantContactFormConfig;


const ConsultantContacts = (props) => {
  const columns = [
    {
      title: fullNameColumnName,
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: titleColumnName,
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: featuredColumnName,
      dataIndex: 'featured',
      key: 'featured',
      render: (text, record) => (record.featured === 'true'
        ? <div style={{ width: '100%', marginLeft: '20px' }}><StarFilled style={{ color: '#1B90FF' }} /></div>
        : ''),
    },
    {
      title: approvedColumnName,
      dataIndex: 'approved',
      key: 'approved',
      render: (text, record) => <Switch disabled defaultChecked={record.approved === 'true'} />,
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 200,
      render: (text, record) => record.hovered
      && <div>
        <Button type='default' size='small' onClick={() => changeModalAction('Edit', record)}>
          <Icon type='edit' theme='filled' />
          {editButtonMessage}
        </Button>
        {' '}
        <Button type='danger' size='small' onClick={() => showDeleteDialog(record.id)}>
          <Icon type='delete' theme='filled' />
          {deleteButtonMessage}
        </Button>
      </div>,
    },
  ];

  const {
    form, consultantId, country, provinces,
  } = props;
  const { getFieldDecorator } = form;

  const [modalAction, setModalAction] = useState('');
  const [selectedRecord, setSelectedRecord] = useState({});

  const dispatch = useDispatch();
  const consultantContacts = useSelector(getConsultantContacts);
  const consultants = useSelector(getConsultants);
  const isGetConsultantContactsLoading = useSelector(consultantContactsLoading);
  const isGetPageConsultantContactsLoading = useSelector(pageConsultantContactsLoading);
  const isAddConsultantContactLoading = useSelector(addNewConsultantContactLoading);
  const isUpdateConsultantContactLoading = useSelector(updateConsultantContactsLoading);
  const isDeleteConsultantContactsLoading = useSelector(deleteConsultantContactsLoading);
  const isConsultantsLoading = useSelector(getLoading);
  const consultantContactFormErrors = useSelector(getConsultantContactsAllErrors);

  useEffect(() => {
    dispatch(fetchConsultantContacts.request({ companyId: consultantId }));
  }, [dispatch, consultantId]);

  useEffect(() => {
    if (!isUpdateConsultantContactLoading && !isAddConsultantContactLoading && !consultantContactFormErrors) {
      changeModalAction();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateConsultantContactLoading, isAddConsultantContactLoading, JSON.stringify(consultantContactFormErrors)]);

  useEffect(() => {
    consultantContactFormErrors && consultantContactFormErrors.forEach((error) => message.error(`Error ${error.code} ${error.message}`));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(consultantContactFormErrors)]);

  const showDeleteDialog = useCallback((id) => {
    confirm({
      title: deleteConfirmMessage,
      okType: 'danger',
      onOk() {
        dispatch(deleteConsultantContacts.request({ id }));
      },
    });
  }, [dispatch]);

  const changeModalAction = (action, record = {}) => {
    setModalAction(action);
    setSelectedRecord(record);
    form.resetFields();
  };

  const submitForm = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const modifiedValues = {
          ...values,
          companyId: values.companyId || consultantId,
          featured: values.featured ? 'true' : 'false',
        };
        dispatch(
          modalAction === 'Create'
            ? addNewConsultantContact.request(modifiedValues)
            : updateConsultantContacts.request({ id: selectedRecord.id, values: modifiedValues }),
        );
      }
    });
  };

  return <>
    <Row>
      <Col span={24}>
        <Title level={4}>
          {`${title}s`}
          <ConsultantContactAddButton type='primary' style={{ float: 'right' }} onClick={() => changeModalAction('Create')}>
            <Icon type='plus' />
            {addButtonMessage}
          </ConsultantContactAddButton>
        </Title>
        <Table
          pagination={false}
          columns={columns}
          dataSource={consultantContacts.data}
          loading={
            isGetConsultantContactsLoading
            || isDeleteConsultantContactsLoading
            || isGetPageConsultantContactsLoading
          }
          rowKey='id' />
        <Modal
          style={{ top: '7%' }}
          title={`${modalAction === 'Create' ? createModalActionName : editModalActionName} ${title}`}
          visible={!!modalAction}
          onOk={() => submitForm()}
          onCancel={() => changeModalAction()}
          okText={modalAction === 'Create' ? createModalActionName : editModalActionName}
          okButtonProps={{
            form: 'consultantContacts',
            loading: isAddConsultantContactLoading || isUpdateConsultantContactLoading,
          }}>
          <Form
            layout='horizontal'
            hideRequiredMark
            id='consultantContacts'
            onSubmit={submitForm}>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <FormItem label={createdByInput.label}>
                  {getFieldDecorator(createdByInput.fieldName, {
                    ...createdByInput.decoratorOptions,
                    initialValue: selectedRecord.createdBy,
                  })(
                    <Input type='createdBy' {...createdByInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={featuredSwitch.label}>
                  {getFieldDecorator(featuredSwitch.fieldName, {
                    ...featuredSwitch.decoratorOptions,
                    initialValue: selectedRecord.featured === 'true',
                  })(
                    <Switch {...featuredSwitch.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={approvedSwitch.label}>
                  {getFieldDecorator(approvedSwitch.fieldName, {
                    ...approvedSwitch.decoratorOptions,
                    initialValue: selectedRecord.approved === 'true',
                  })(
                    <Switch {...approvedSwitch.elementProps} />,
                  )}
                </FormItem>
              </Col>

              {modalAction === 'Edit'
            && <Col span={24}>
              <FormItem label={consultantSelect.label}>
                {getFieldDecorator(consultantSelect.fieldName, {
                  ...consultantSelect.decoratorOptions,
                  initialValue: selectedRecord.companyId || consultantId,
                })(
                  <Select loading={isConsultantsLoading}>
                    {consultants.data.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.companyName}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>}
              <Col span={12}>
                <FormItem label={emailInput.label}>
                  {getFieldDecorator(emailInput.fieldName, {
                    ...emailInput.decoratorOptions,
                    initialValue: selectedRecord.email,
                  })(
                    <Input type='email' {...emailInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={firstNameInput.label}>
                  {getFieldDecorator(firstNameInput.fieldName, {
                    ...firstNameInput.decoratorOptions,
                    initialValue: selectedRecord.firstName,
                  })(
                    <Input {...firstNameInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={lastNameInput.label}>
                  {getFieldDecorator(lastNameInput.fieldName, {
                    ...lastNameInput.decoratorOptions,
                    initialValue: selectedRecord.lastName,
                  })(
                    <Input {...lastNameInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={titleInput.label}>
                  {getFieldDecorator(titleInput.fieldName, {
                    ...titleInput.decoratorOptions,
                    initialValue: selectedRecord.title,
                  })(
                    <Input {...titleInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label={countrySelect.label}>
                  {getFieldDecorator(countrySelect.fieldName, {
                    ...countrySelect.decoratorOptions,
                    initialValue: selectedRecord.country || country,
                  })(
                    <Select {...countrySelect.elementProps}>
                      {countrySelect.options.map((option) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label={provinceSelect.label}>
                  {getFieldDecorator(provinceSelect.fieldName, {
                    ...provinceSelect.decoratorOptions,
                    initialValue: selectedRecord.provinceId,
                  })(
                    <Select {...provinceSelect.elementProps}>
                      {provinces.map((option) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label={cityInput.label}>
                  {getFieldDecorator(cityInput.fieldName, {
                    ...cityInput.decoratorOptions,
                    initialValue: selectedRecord.city,
                  })(
                    <Input {...cityInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label={phoneInput.label}>
                  {getFieldDecorator(phoneInput.fieldName, {
                    ...phoneInput.decoratorOptions,
                    initialValue: selectedRecord.phone,
                  })(
                    <Input {...phoneInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label={faxInput.label}>
                  {getFieldDecorator(faxInput.fieldName, {
                    ...faxInput.decoratorOptions,
                    initialValue: selectedRecord.fax,
                  })(
                    <Input {...faxInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label={mobileInput.label}>
                  {getFieldDecorator(mobileInput.fieldName, {
                    ...mobileInput.decoratorOptions,
                    initialValue: selectedRecord.mobile,
                  })(
                    <Input {...mobileInput.elementProps} />,
                  )}
                </FormItem>
              </Col>

              <Col span={24}>
                <FormItem label={linkedInUrlInput.label}>
                  {getFieldDecorator(linkedInUrlInput.fieldName, {
                    ...linkedInUrlInput.decoratorOptions,
                    initialValue: selectedRecord.linkedInUrl,
                  })(
                    <Input {...linkedInUrlInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label={notesInput.label}>
                  {getFieldDecorator(notesInput.fieldName, {
                    ...notesInput.decoratorOptions,
                    initialValue: selectedRecord.notes,
                  })(
                    <Input.TextArea {...notesInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Col>
    </Row>
  </>;
};

export default Form.create({ name: 'consultantContacts-form' })(ConsultantContacts);
