import { API_ROOT } from 'configs/envVars';
import { makeRequestAction } from './index';

export const getUserCompanies = makeRequestAction('GET_USER_COMPANIES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getPageUserCompanies = makeRequestAction('GET_PAGE_USER_COMPANIES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addNewUserCompany = makeRequestAction('ADD_NEW_USER_COMPANY');

export const updateUserCompanies = makeRequestAction('UPDATE_USER_COMPANY');

export const deleteUserCompanies = makeRequestAction('DELETE_USER_COMPANIES', {
  onSuccess(params, response) {
    return {
      ids: [response.id],
    };
  },
});

export const addUserCompanyLogo = makeRequestAction('ADD_USER_COMPANY_UPLOADS', {
  onSuccess(params, response) {
    return {
      url: `${API_ROOT}/file-storage/${response[0].hashedId}`,
    };
  },
});

export const deleteUserCompanyLogo = makeRequestAction('DELETE_USER_COMPANY_UPLOADS', {
  onSuccess(params, response) {
    return {
      url: '',
    };
  },
});
