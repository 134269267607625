import { API_ROOT } from 'configs/envVars';
import { makeRequestAction } from './index';

export const getServices = makeRequestAction('GET_SERVICES', {
  onSuccess(params, response) {
    return {
      response: {
        [params.type]: response,
      },
    };
  },
});

export const updateService = makeRequestAction('UPDATE_SERVICE', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const deleteServices = makeRequestAction('DELETE_SERVICE', {
  onSuccess(params, response) {
    return {
      response: { ids: [response.id], type: response.type },
    };
  },
});

export const addServiceUploads = makeRequestAction('ADD_SERVICE_UPLOADS', {
  onSuccess(params, response) {
    return {
      files: response.map((file) => ({
        uid: file.hashedId,
        name: file.fileName,
        url: `${API_ROOT}/file-storage/${file.hashedId}`,
      })),
    };
  },
});

export const deleteServiceUploads = makeRequestAction('DELETE_SERVICE_UPLOADS', {
  onSuccess(params, response) {
    return {
      deletedFileId: response.hashedId,
    };
  },
});
