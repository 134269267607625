import { createSelector } from 'reselect';
import {
  userLogin,
  userLogout,
} from 'actions/auth';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getUserState = (state) => state.auth;

export const getUser = createSelector(
  getUserState,
  (state) => state.loggedInUser,
);

export const getUserData = createSelector(
  getUser,
  (state) => state.data,
);

export const userIsAuth = createSelector(
  getUser,
  (user) => user && !!user.accessToken && user.data.roles === 'Super Admin',
);

export const loginLoading = createLoadingSelector(userLogin.actionName)();
export const loginError = createErrorSelector(userLogin.actionName)();

export const logoutLoading = createLoadingSelector(userLogout.actionName)();
export const logoutError = createErrorSelector(userLogout.actionName)();
