export const fieldTypes = {
  text: 'text',
  textarea: 'textarea',
  number: 'number',
  date: 'date',
  media: 'media',
  selectBox: 'selectBox',
  map: 'map',
};

export const dataSetSources = {
  options_territories: 'Territories',
  options_property_classes: 'Property classes',
  options_loan_types: 'Loan types',
  options_markets: 'markets',
  options_property_types: 'Property types',
  state_province: 'State/province',
};

export const fileExtensions = ['png', 'jpg', 'jpeg', 'xls', 'xlsx', 'pdf'];
