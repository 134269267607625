import React, { Fragment, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-monokai';
import {
  Row,
  Col,
  Form,
  Icon,
  Typography,
  Modal,
  message,
} from 'antd';
import {
  Button,
  Input,
  FormItem,
} from 'components';
import {
  getEmailTemplates as fetchEmailTemplates,
  addNewEmailTemplate,
  updateEmailTemplates,
} from 'actions/emailTemplates';
import {
  addNewEmailTemplateLoading,
  getEmailTemplatesAllErrors,
  getSuccessState,
} from 'selectors/emailTemplates';

import { messages } from 'messages';
import routes from 'constants/routes';
import formItemsConfig from './formItemsConfig';

const { Title } = Typography;
const {
  newEmailTemplateTitleMessage,
  editEmailTemplateTitleMessage,
  editButtonMessage,
  createButtonMessage,
  confirmModalMessage,
} = messages.emailTemplates.form;

const {
  templateNameInput,
  subjectInput,
  textAreaInput,
  htmlEditorInput,
} = formItemsConfig;

const { confirm } = Modal;

const TitleRow = styled(Row)`
  margin-bottom: 25px;
`;

const EmailTemplatesForm = (props) => {
  const { form, history } = props;
  const { currentEmailTemplate } = props.location.state || {};
  const { getFieldDecorator } = form;

  const dispatch = useDispatch();
  const emailTemplatesFormErrors = useSelector(getEmailTemplatesAllErrors);
  const isSubmitloading = useSelector(addNewEmailTemplateLoading);
  const isSubmitSuccessful = useSelector(getSuccessState);

  useEffect(() => {
    dispatch(fetchEmailTemplates.request());
  }, [dispatch]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      message.success('Record has been successfully saved');
      !currentEmailTemplate && history.push(routes.settings.emailTemplates.table.pathname);
    }
  }, [currentEmailTemplate, history, isSubmitSuccessful]);

  useEffect(() => {
    emailTemplatesFormErrors
      && emailTemplatesFormErrors.forEach((error) => message.error(`Error ${error.code} ${error.message}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(emailTemplatesFormErrors)]);

  const getRequestValues = useCallback(
    (values) => {
      const {
        accountExpireDate,
        createdAt,
        updatedAt,
        firstLogin,
        firstName,
        lastName,
        jobTitle,
        address,
        postal,
        phoneNumber,
        fax,
        email,
        password,
        repeatPassword,
        ...otherValues
      } = values;

      const requestValues = {
        accountExpireDate: accountExpireDate
          ? accountExpireDate.toISOString()
          : accountExpireDate,
        emailTemplate_detail: {
          firstName,
          lastName,
          jobTitle,
          address,
          postal,
          phoneNumber,
          fax,
        },
        ...otherValues,
      };

      if (!currentEmailTemplate) {
        requestValues.email = email;
        if (password && repeatPassword) {
          requestValues.password = password;
          requestValues.repeatPassword = repeatPassword;
        }
      } else {
        requestValues.password = password;
        requestValues.repeatPassword = repeatPassword;
      }

      return requestValues;
    },
    [currentEmailTemplate],
  );

  const submitForm = useCallback(
    (values) => {
      const modifyedValues = getRequestValues(values);
      currentEmailTemplate
        ? dispatch(
          updateEmailTemplates.request({ id: currentEmailTemplate.templateName, values: modifyedValues }),
        )
        : dispatch(addNewEmailTemplate.request(modifyedValues));
    },
    [currentEmailTemplate, dispatch, getRequestValues],
  );

  const showConfirm = useCallback(
    (values) => {
      confirm({
        title: confirmModalMessage,
        confirmLoading: true,
        onOk() {
          submitForm(values);
        },
        onCancel() {},
      });
    },
    [submitForm],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          showConfirm(values);
        }
        return true;
      });
    },
    [form, showConfirm],
  );

  const handleReset = (e) => {
    e.preventDefault();
    form.resetFields();
  };

  const SHORT_ITEM_SPAN = 8;
  const LARG_ITEM_SPAN = SHORT_ITEM_SPAN * 2 + 2;

  return (
    <Fragment>
      <Row type={'flex'} align={'middle'}>
        <Col span={23}>
          <Form
            onSubmit={handleSubmit}
            layout={'vertical'}
            labelAlign={'left'}
            hideRequiredMark
          >
            <TitleRow>
              <Col md={12}>
                <Title ellipsis level={4}>
                  {currentEmailTemplate ? editEmailTemplateTitleMessage : newEmailTemplateTitleMessage}
                </Title>
              </Col>
              <Col md={12} style={{ textAlign: 'right' }}>
                <Button
                  type='danger'
                  size='large'
                  onClick={handleReset}
                  style={{ margin: '0 10px' }}
                >
                  <Icon type='delete' theme='filled' />
                </Button>
                <Button
                  type='success'
                  size='large'
                  htmlType='submit'
                  loading={isSubmitloading}
                  style={{ margin: '0 10px' }}
                >
                  {currentEmailTemplate ? editButtonMessage : createButtonMessage}
                </Button>
              </Col>
            </TitleRow>
            <Row>
              <Col span={SHORT_ITEM_SPAN} >
                <FormItem label={templateNameInput.label}>
                  {getFieldDecorator(templateNameInput.fieldName, {
                    ...templateNameInput.decoratorOptions,
                    initialValue: currentEmailTemplate ? currentEmailTemplate.templateName : null,
                  })(
                    <Input {...templateNameInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
              <Col span={SHORT_ITEM_SPAN} offset={2}>
                <FormItem label={subjectInput.label}>
                  {getFieldDecorator(subjectInput.fieldName, {
                    ...subjectInput.decoratorOptions,
                    initialValue: currentEmailTemplate ? currentEmailTemplate.subject : null,
                  })(
                    <Input {...subjectInput.elementProps} />,
                  )}
                </FormItem>
              </Col>
            </Row>
            <FormItem label={textAreaInput.label} wrapperCol={{ span: LARG_ITEM_SPAN }}>
              {getFieldDecorator(textAreaInput.fieldName, {
                ...textAreaInput.decoratorOptions,
                initialValue: currentEmailTemplate ? currentEmailTemplate.text : null,
              })(
                <Input.TextArea {...textAreaInput.elementProps} />,
              )}
            </FormItem>
            <FormItem
              label={htmlEditorInput.label}
              wrapperCol={{ span: LARG_ITEM_SPAN }}
            >
              {getFieldDecorator(htmlEditorInput.fieldName, {
                ...htmlEditorInput.decoratorOptions,
                initialValue: currentEmailTemplate ? currentEmailTemplate.html : '',
              })(<AceEditor
                mode='html'
                theme='monokai'
                name='html_Editor'
                showGutter
                highlightActiveLine
                editorProps={{ $blockScrolling: true }}
              />)}
            </FormItem>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

EmailTemplatesForm.propTypes = {
  form: PropTypes.object,
  isSubmitloading: PropTypes.bool,
};

export default React.memo(Form.create({ name: 'email-templates-form' })(EmailTemplatesForm));
