import {
  call, takeLatest,
} from 'redux-saga/effects';
import {
  userLogin,
  userLogout,
} from 'actions/auth';
import {
  userLoginApi,
  userLogoutApi,
} from 'services/auth';
import fetchEntity from './fetch-entity';

const fetchLogin = fetchEntity.bind(null, userLogin.actions, userLoginApi);
const fetchUserLogout = fetchEntity.bind(null, userLogout.actions, userLogoutApi);

export function* loadUserLogin({ params }) {
  yield call(
    fetchLogin,
    { ...params, strategy: 'local' },
  );
}

function* watchUserLogin() {
  yield takeLatest(userLogin.actionName, loadUserLogin);
}

export function* loadUserLogout() {
  yield call(fetchUserLogout, {});
}

function* watchUserLogout() {
  yield takeLatest(userLogout.actionName, loadUserLogout);
}


export default {
  watchUserLogin,
  watchUserLogout,
};
