import {
  getConsultants, deleteConsultants, addNewConsultant, updateConsultant, importConsultants, importConsultantsContacts,
} from 'actions/consultants';

const consultant = () => {
  const initialState = {};

  return (state = initialState, action) => {
    const { type, response, ids } = action;
    switch (type) {
      case getConsultants.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case deleteConsultants.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      case addNewConsultant.requestTypes.REQUEST:
      case updateConsultant.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addNewConsultant.requestTypes.SUCCESS:
      case updateConsultant.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case addNewConsultant.requestTypes.FAILURE:
      case updateConsultant.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case importConsultants.requestTypes.SUCCESS:
        return {
          ...state,
          importConsultantsInfo: {
            ...response,
          },
        };
      case importConsultantsContacts.requestTypes.SUCCESS:
        return {
          ...state,
          importConsultantsContactsInfo: {
            ...response,
          },
        };
      default:
        return state;
    }
  };
};

export default consultant();
