import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getLoanTypes, deleteLoanTypes, addNewLoanTypes, updateLoanTypes, getPageLoanTypes,
} from 'actions/loanTypes';
import {
  getLoanTypesApi, deleteLoanTypesApi, addNewLoanTypeApi, updateLoanTypesApi,
} from 'services/loanTypes';
import fetchEntity from './fetch-entity';

const fetchLoanTypes = fetchEntity.bind(null, getLoanTypes.actions, getLoanTypesApi);
const fetchPageLoanTypes = fetchEntity.bind(null, getPageLoanTypes.actions, getLoanTypesApi);
const fetchAddNewLoanType = fetchEntity.bind(
  null,
  addNewLoanTypes.actions,
  addNewLoanTypeApi,
);

const fetchUpdateLoanType = fetchEntity.bind(
  null,
  updateLoanTypes.actions,
  updateLoanTypesApi,
);

const fetchDeleteLoanTypes = fetchEntity.bind(
  null,
  deleteLoanTypes.actions,
  deleteLoanTypesApi,
);

export function* loadGetPageLoanTypes({ params }) {
  yield call(fetchPageLoanTypes, { ...params });
}

function* watchGetPageLoanTypes() {
  yield takeLatest([getPageLoanTypes.actionName], loadGetPageLoanTypes);
}

export function* loadGetPageLoanTypesOnChange({ params }) {
  const loanTypes = yield select((state) => state.loanTypes);
  const { total, skip, limit } = loanTypes;
  if (skip && skip >= total) {
    yield call(fetchPageLoanTypes, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchPageLoanTypes, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetPageLoanTypesOnChange() {
  yield takeLatest([
    deleteLoanTypes.requestTypes.SUCCESS,
    addNewLoanTypes.requestTypes.SUCCESS,
    updateLoanTypes.requestTypes.SUCCESS,
  ], loadGetPageLoanTypesOnChange);
}

export function* loadGetLoanTypes({ params }) {
  yield call(fetchLoanTypes, { ...params });
}
function* watchGetLoanTypes() {
  yield takeLatest(getLoanTypes.actionName, loadGetLoanTypes);
}

export function* loadUpdateLoanTypes({ params }) {
  yield call(fetchUpdateLoanType, { ...params });
}

function* watchUpdateLoanTypes() {
  yield takeLatest(updateLoanTypes.actionName, loadUpdateLoanTypes);
}

export function* loadAddNewLoanType({ params }) {
  yield call(fetchAddNewLoanType, { ...params });
}

function* watchAddNewLoanType() {
  yield takeLatest(addNewLoanTypes.actionName, loadAddNewLoanType);
}
export function* loadDeleteLoanTypes({ params }) {
  yield call(fetchDeleteLoanTypes, { ...params });
}

function* watchDeleteLoanTypes() {
  yield takeLatest(deleteLoanTypes.actionName, loadDeleteLoanTypes);
}

export default {
  watchGetLoanTypes,
  watchUpdateLoanTypes,
  watchAddNewLoanType,
  watchDeleteLoanTypes,
  watchGetPageLoanTypes,
  watchGetPageLoanTypesOnChange,
};
