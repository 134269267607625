import api from './api';

export const postNewLenderApi = (data) => api().post('/lender-company', data);
export const getLendersApi = (params) => api().get('/lender-company', { params });
export const getLenderApi = ({ id, ...params }) => api().get(`/lender-company/${id}`, { params });
export const deleteLendersApi = (params) => api().delete('/lender-company', { params });
export const updateLenderApi = (params) => api().patch(`/lender-company/${params.id}`, params.values);

export const postImportLendersApi = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => (
    key === 'file' && Array.isArray(data[key])
      ? data[key].forEach((file) => formData.append(key, file))
      : formData.append(key, data[key])
  ));
  return api({ 'Content-type': 'application/x-www-form-urlencoded' }).post('/lenders-import', formData);
};

export const postImportLendersContactsApi = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => (
    key === 'file' && Array.isArray(data[key])
      ? data[key].forEach((file) => formData.append(key, file))
      : formData.append(key, data[key])
  ));
  return api({ 'Content-type': 'application/x-www-form-urlencoded' }).post('/lenders-contacts-import', formData);
};

export const postImportLendersDataApi = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => (
    key === 'file' && Array.isArray(data[key])
      ? data[key].forEach((file) => formData.append(key, file))
      : formData.append(key, data[key])
  ));
  return api({ 'Content-type': 'application/x-www-form-urlencoded' }).post('/lenders-import', formData);
};
