import { call, takeLatest } from 'redux-saga/effects';
import { getCountries } from 'actions/countries';
import { getCountriesApi } from 'services/countries';
import fetchEntity from './fetch-entity';

const fetchCountries = fetchEntity.bind(
  null,
  getCountries.actions,
  getCountriesApi,
);

export function* loadGetCountries({ params }) {
  yield call(fetchCountries, { ...params });
}

function* watchGetCountries() {
  yield takeLatest(getCountries.actionName, loadGetCountries);
}

export default {
  watchGetCountries,
};
