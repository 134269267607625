export default {
  general: {
    paginationShowingMessage: 'Showing',
  },
  user: {
    settingsMessage: 'Settings',
    logOutMessage: 'Log Out',
  },
  services: {
    table: {
      editButtonMessage: 'Save Changes',
      deleteButtonMessage: 'Delete',
      deleteConfirmMessage: 'Delete this',
      propertyNameColumnName: 'Property Name',
      createdAtColumnName: 'Date',
      statusColumnName: 'Status',
      addressColumnName: 'Address',
    },
    form: {
      propertyNameInputMessages: {
        label: 'Property (Name)',
      },
      addressInputMessages: {
        label: 'Address',
      },
      usernameInputMessages: {
        label: 'Username',
      },
      leasesNumberInputMessages: {
        label: 'Leases',
        invalid: 'Leases must be a positive integer!',
      },
      timingInputMessages: {
        label: 'Timing (Business Days)',
        invalid: 'Timing should be a positive integer!',
      },
      statusSelectMessages: {
        label: 'Status',
      },
      purposeTextAreaMessages: {
        label: 'Purpose',
      },
      linkInputMessages: {
        label: 'Link (Leases)',
        invalid: 'Please input a valid URL!',
      },
      attachmentsMessages: {
        label: 'Attachments',
      },
      editMessage: 'Edit',
      submitSuccessMessage: 'Record has been successfully saved!',
      backToServicesMessage: 'Back to services',
    },
  },
  userCompanies: {
    table: {
      addNewButtonMessage: 'Add New Borrower Company',
      unselectButtonMessage: 'Unselect All',
      titleMessage: 'Borrower Companies',
      editButtonMessage: 'Edit',
      deleteButtonMessage: 'Delete',
      deleteConfirmMessage: 'Delete this borrower company?',
      companyNameColumnName: 'Company Name',
      logoColumnName: 'Logo',
      numberOfUsersColumnName: 'Number of users',
    },
    form: {
      title: 'Borrower Company',
      createModalActionName: 'Create',
      editModalActionName: 'Edit',
      chooseLogoMessage: 'Choose Logo',
      companyNameInputMessages: {
        label: 'Company Name',
        required: 'Please input company name!',
      },
      logoInputMessages: {
        label: 'Logo',
      },
      aboutCompanyHTMLEditorMessages: {
        label: 'About',
      },
    },
  },
  usersCrudMessages: {
    businessNameSelectMessages: {
      label: 'Business Name',
      required: 'Please input business name!',
    },
    emailInputMessages: {
      label: 'Email',
      required: 'Please input email!',
      invalid: 'Please input a valid email!',
    },
    passwordInputMessages: {
      label: 'Password',
      required: 'Password required!',
      invalid: 'Password must contain at least 1 letter, 1 number and 1 symbol',
    },
    repeatPasswordInputMessages: {
      label: 'Repeat Password',
      required: 'Repeat Password required!',
      invalid: 'Passwords don\'t match',
    },
    verifiedSwitchMessages: {
      label: 'Verified',
    },
    approvedSwitchMessages: {
      label: 'Approved',
    },
    activeSwitchMessages: {
      label: 'Active',
    },
    accountExpireDateInputMessages: {
      label: 'Account Expiry Date',
    },
    userRoleSelectMessages: {
      label: 'Role',
    },
    parentSelectMessages: {
      label: 'Parent',
    },
    firstLoginInputMessages: {
      label: 'First Login',
    },
    createdAtInputMessages: {
      label: 'Created At',
    },
    updatedAtInputMessages: {
      label: 'Updated At',
    },
    accessLendersInputMessages: {
      label: 'Access Lenders (P1)',
    },
    accessConsultantsInputMessages: {
      label: 'Access Consultants (P1)',
    },
    dealEmailLimitInputMessages: {
      label: 'Deal Email Limit',
    },
    firstNameInputMessages: {
      label: 'First Name',
      required: 'First Name is required!',
    },
    lastNameInputMessages: {
      label: 'Last Name',
      required: 'Last Name is required!',
    },
    jobTitleInputMessages: {
      label: 'Job Title',
    },
    addressInputMessages: {
      label: 'Address',
    },
    cityInputMessages: {
      label: 'City',
    },
    provinceInputMessages: {
      label: 'State/Province',
    },
    countryInputMessages: {
      label: 'Country',
    },
    timeZoneInputMessages: {
      label: 'Time Zone',
    },
    usernameInputMessages: {
      label: 'Username',
      required: 'Username required!',
      invalid: 'Username should contain alphabets and numbers only.',
    },
    postalInputMessages: {
      label: 'Postal Code',
      invalid: 'Please input a valid US or Canadian postal code!',
    },
    phoneNumberInputMessages: {
      label: 'Phone Number',
      invalid: 'Please input a valid US or Canadian phone number in the specified format!',
    },
    faxInputMessages: {
      label: 'Fax',
      invalid: 'Please input a valid US or Canadian fax in the specified format!',
    },
    newUserTitleMessage: 'Add New User',
    editUserTitleMessage: 'Edit User',
    editButtonMessage: 'Save',
    createButtonMessage: 'Create',
    confirmModalMessage: 'Are you sure you want to submit this user?',
    isBDUserInputMessage: {
      label: 'BD User',
    },
  },
  landersCrudMessages: {
    companyNameInputMessages: {
      label: 'Company Name',
      required: 'Please input your company name!',
    },
    ratingInputMessages: {
      label: 'Rating',
    },
    relationshipLenderInputMessages: {
      label: 'Relationship Lender',
    },
    statusSelelctMessages: {
      label: 'Status',
      required: 'Please select status!',
    },
    countrySelelctMessages: {
      label: 'Country',
      required: 'Please select country!',
    },
    categorySelelctMessages: {
      label: 'Category',
      required: 'Please select category!',
    },
    websiteInputMessages: {
      label: 'Website',
    },
    addressInputMessages: {
      label: 'Address',
    },
    logoInputMessages: {
      label: 'Logo',
    },
    abStructureInputMessages: {
      label: 'AB Structure',
    },
    minLoanInputMessages: {
      label: 'Min Loan ($)',
    },
    maxLoanInputMessages: {
      label: 'Max Loan ($)',
      maxCompareToMin: 'Max Loan cannot be smaller than Min Loan ',
    },
    minDSCRInputMessages: {
      label: ' Min DSCR (x)',
    },
    maxLTVInputMessages: {
      label: 'Max LTV (%)',
    },
    feeMaxInputMessages: {
      label: 'Fee Max (bps)',
      maxCompareToMin: 'Max Fee cannot be smaller than Min Fee ',
    },
    feeMinInputMessages: {
      label: 'Fee Min (bps)',
    },
    maxTermInputMessages: {
      label: 'Max term',
      maxCompareToMin: 'Max Term cannot be smaller than Min Term ',
    },
    minTermInputMessages: {
      label: 'Min Term',
    },
    syndicateSelelctMessages: {
      label: 'Syndicate',
    },
    syndicateNoteInputMessages: {
      label: 'Syndicate Note',
    },
    portfolioSizeInputMessages: {
      label: 'Portfolio Size ($)',
    },
    numberOfLoansInputMessages: {
      label: 'Number of Loans (#)',
    },
    minAmortizationInputMessages: {
      label: 'Min Amrt (# yrs)',
    },
    maxAmortizationInputMessages: {
      label: 'Max Amrt (# yrs)',
      maxCompareToMin: 'Max Amortization cannot be smaller than Min Amortization ',
    },
    interestOnlySwitchMessages: {
      label: 'IO',
    },
    propertyClassSelelctMessages: {
      label: 'Property Class',
    },
    marketsSelelctMessages: {
      label: 'Markets',
    },
    loanTypeSelelctMessages: {
      label: 'Loan Type',
    },
    propertyTypeSelelctMessages: {
      label: 'Property Type',
    },
    stateProvinceSelectMessages: {
      label: 'State/Province',
    },
    notesInputMessages: {
      label: 'Notes',
    },
    aboutCombanyHTMLMessages: {
      label: 'About Company',
    },
    recourseSelectMessages: {
      label: 'Recourse',
    },
    rateTypeSelectMessages: {
      label: 'Rate Type',
    },
    portfolioMixConstructionInputMessages: {
      label: 'Construction',
    },
    portfolioMixBridgeInputMessages: {
      label: 'Bridge',
    },
    portfolioMixTermInputMessages: {
      label: 'Term',
    },
    portfolioMixCMHCInputMessages: {
      label: 'CMHC',
    },
    portfolioMixMessages: {
      title: 'Portfolio Mix',
      sumError: 'Sum of portfolio mix values cannot exceed 100%',
    },
    originationsYearSelectMessages: {
      label: 'Year',
      required: 'Year is required! Either input it or delete this origination!',
    },
    originationsValueInputMessages: {
      label: 'Value',
    },
    originationsMessages: {
      title: 'Originations',
      addOrigination: 'Add Origination',
    },
    pricingModelsMessages: {
      title: 'Pricing Models',
      addPricingModel: 'Add Pricing Model',
    },
    pricingModelLoanTypesSelectMessages: {
      label: 'Loan Types',
      required: 'Please select a loan type!',
    },
    pricingModelIndexTypeSelectMessages: {
      label: 'Index Type',
      required: 'Index Type is required!',
    },
    pricingModelSpreadMinInputMessages: {
      label: 'Spread Min',
      required: 'Please input spreadMin!',
    },
    pricingModelSpreadMaxInputMessages: {
      label: 'Spread Max',
      required: 'Please input spreadMax!',
    },
    pricingModelNonRecourseSwitchMessages: {
      label: 'Non-recourse',
    },
    contactsMessages: {
      title: 'Contact',
      fullNameColumnName: 'Full Name',
      titleColumnName: 'Title',
      featuredColumnName: 'Featured',
      approvedColumnName: 'Approved',
      addButtonMessage: 'Create Contact',
      editButtonMessage: 'Edit',
      deleteButtonMessage: 'Delete',
      deleteConfirmMessage: 'Delete this contact?',
      createModalActionName: 'Create',
      editModalActionName: 'Edit',
      saveModalActionName: 'Save',
      form: {
        lenderSelectMessages: {
          label: 'Lender Company',
        },
        consultantSelectMessages: {
          label: 'Consultant',
        },
        featuredSwitchMessages: {
          label: 'Featured',
        },
        approvedSwitchMessages: {
          label: 'Approved',
        },
        createdByInputMessages: {
          label: 'Created By',
        },
        emailInputMessages: {
          label: 'Email',
          required: 'Email is required!',
          invalid: 'Please input a valid email address!',
        },
        firstNameInputMessages: {
          label: 'First Name',
          required: 'First Name is required!',
        },
        lastNameInputMessages: {
          label: 'Last Name',
          required: 'Last Name is required!',
        },
        titleInputMessages: {
          label: 'Title',
          required: 'Title is required!',
        },
        phoneInputMessages: {
          label: 'Phone Number',
          invalid: 'Please input a valid US or Canadian phone number in the specified format!',
          required: 'Phone Number is required!',
        },
        linkedInUrlInputMessages: {
          label: 'LinkedIn URL',
          invalid: 'Please input a valid URL!',
        },
        notesInputMessages: {
          label: 'Notes',
        },
        countrySelectMessages: {
          label: 'Country',
          required: 'Please select country!',
        },
        provinceSelectMessages: {
          label: 'Province',
          required: 'Province is required!',
        },
        cityInputMessages: {
          label: 'City',
        },
        faxInputMessages: {
          label: 'Fax',
        },
        mobileInputMessages: {
          label: 'Mobile',
        },
      },
    },
    newLenderTitleMessage: 'Add New Lender',
    editLenderTitleMessage: 'Edit Lender',
    createButtonMessage: 'Create',
    editButtonMessage: 'Save Changes',
    confirmModalMessage: 'Are you sure you want to submit this lender?',
    logoDeleteConfirmModalMessage: 'Delete lender logo?',
    chooseLogoMessage: 'Choose File',
  },
  landersArchiveMessages: {
    lenderListTitleMessage: 'Lender List',
    newLenderButtonMessage: 'Add New Lender',
    unselectButtonMessage: 'Unselect All',
    lenderEditButtonMessage: 'Edit',
    companyNameColumnName: 'Company Name',
    companyCountry: 'Country',
    portfolioSizeColumnName: 'Portfolio Size',
    notesColumnName: 'Notes',
    confirmDeleteMessage: 'Are you sure you want to delete the selected lender(s)?',
    importLendersButtonMessage: 'Import Lenders',
    importLendersDataButtonMessage: 'Import Lenders Data',
    importLendersContactsButtonMessage: 'Import Lenders Contacts',
  },
  consultants: {
    table: {
      consultantsTitleMessage: 'Consultants',
      newConsultantButtonMessage: 'Add New Consultant',
      unselectButtonMessage: 'Unselect All',
      consultantEditButtonMessage: 'Edit',
      confirmDeleteMessage: 'Are you sure you want to delete the selected consultant(s)?',
      companyNameColumnName: 'Company Name',
      categoryColumnName: 'Category',
      importConsultantsButtonMessage: 'Import Consultants',
      importConsultantsContactsButtonMessage: 'Import Consultants Contacts',
    },
    form: {
      companyNameInputMessages: {
        label: 'Company Name',
        required: 'Company Name is required!',
      },
      categorySelelctMessages: {
        label: 'Category',
        required: 'Category is required!',
      },
      websiteInputMessages: {
        label: 'Website',
        required: 'Website is required!',
      },
      cityInputMessages: {
        label: 'City',
        required: 'City is required!',
      },
      addressInputMessages: {
        label: 'Address',
        required: 'Address is required!',
      },
      countrySelectMessages: {
        label: 'Country',
        required: 'Please select country!',
      },
      phoneInputMessages: {
        label: 'Phone',
        required: 'Phone is required!',
      },
      stateProvinceSelectMessages: {
        label: 'Province',
        required: 'Province is required!',
      },
      notesInputMessages: {
        label: 'Notes',
      },
      aboutCombanyHTMLMessages: {
        label: 'About',
      },
      newConsultantTitleMessage: 'Add New Consultant',
      editConsultantTitleMessage: 'Edit Consultant',
      editButtonMessage: 'Save Changes',
      createButtonMessage: 'Create',
      confirmModalMessage: 'Are you sure you want to submit this consultant?',
      confirmDeleteMessage: 'Are you sure you want to delete this consultant?',
    },
  },
  usersTableMessages: {
    userListTitleMessage: 'Users List',
    newUserButtonMessage: 'Add New User',
    unselectButtonMessage: 'Unselect All',
    userEditButtonMessage: 'Edit',
    emailColumnNameMessage: 'Username/Email',
    isVerifiedColumnNameMessage: 'Verified',
    isActiveColumnNameMessage: 'Active',
    isApprovedColumnNameMessage: 'Approved',
    userRoleComulnNameMessage: 'Role',
    companyNameColumnNameMessage: 'Company Name',
    confirmDeleteMessage: 'Are you sure you want to delete the selected user(s)?',
    userExpiryColumnNameMessage: 'Account Expiry Date',
    userParentAccountEmail: 'Parent Account',
  },
  roleManagementMessages: {
    titleMassage: 'Role Management',
    serviceNameMassage: 'Service Name',
    serviceNameRequiredMassage: 'Pleas input service name',
    serviceRolesMassage: 'Allowed Roles',
    addNewButtonMessage: 'Add New Service Roles',
    deleteConfirmMessage: 'Are You sure you want to delete the selected Service Roles?',
    editButtonMessage: 'Edit',
    unselectButtonMassage: 'Unselect All',
    formModalEditTitleMassage: 'Edit',
    formModalCreateTitleMassage: 'Create',
    formModalEditMassage: 'Edit',
    formModalCreateMassage: 'Create',
    nameColumnMassage: 'Service Name',
    submitSuccessMassage: 'Record has been successfully saved',
  },
  assets: {
    addNewButtonMessage: 'Add New ',
    deleteAssetConfirmMessage: 'Are You sure you want to delete selected ',
    editButtonMessage: 'Edit',
    unselectButtonMassage: 'Unselect All',
    formModalEditTitle: 'Edit',
    formModalCreateTitle: 'Create',
    propertyClassesMessages: {
      title: 'Property Classes',
      name: 'Property Classes',
      nameColumn: 'name',
      nameRequired: 'Please input Property Classes name!',
    },
    marketsMessages: {
      title: 'Markets',
      name: 'Market',
      nameColumn: 'name',
      nameRequired: 'Please input Market name!',
    },
    loanTypesMessages: {
      title: 'Loan Types',
      name: 'Loan Types',
      nameColumn: 'name',
      nameRequired: 'Please input Loan Type name!',
    },
    propertyTypesMessages: {
      title: 'Property Types',
      name: 'Property Types',
      nameColumn: 'name',
      nameRequired: 'Please input Property Type name!',
    },
    stateProvincesMessages: {
      title: 'State Provinces',
      name: 'State Provinces',
      country: 'Country',
      abbreviation: 'Abbreviation',
      nameColumn: 'name',
      abbreviationColumn: 'abbreviation',
      countryIdColumn: 'country',
      nameRequired: 'Please input State Province name!',
      abbreviationRequired: 'Please input state abbreviation!',
      countryRequired: 'Please input state Country!',
    },
    categoriesMessages: {
      title: 'Lender Categories',
      name: 'Category',
      nameColumn: 'name',
      nameRequired: 'Please input category name!',
    },
    consultantCategoriesMessages: {
      title: 'Consultant Categories',
      name: 'Consultant Categories',
      nameColumn: 'name',
      nameRequired: 'Please input category name!',
    },
  },
  manageAssets: {
    manageAssetsTitleMessage: 'Manage Assets',
    manageAssetButtonMessage: 'Manage',
    assetTypeColumnName: 'Asset Type',
    notesColumnName: 'Notes',
  },
  formBuilder: {
    form: {
      addNewButtonMessage: 'Create New Form',
      submitSuccessMessage: 'Record has been successfully saved!',
      formNameInputMessages: {
        label: 'Form Name',
        required: 'Form name is required!',
      },
      loanTypeSelectMessages: {
        label: 'Loan Type',
        required: 'Loan Type is required!',
      },
    },
  },
  emailTemplates: {
    table: {
      emailTemplateListTitleMessage: 'Email Template',
      newEmailTemplateButtonMessage: 'Add New Template',
      unselectButtonMessage: 'Unselect All',
      emailTemplateEditButtonMessage: 'Edit',
      confirmDeleteMessage: 'Delete template(s)?',
      templateNameColumnNameMessage: 'Template Name',
      subjectColumnNameMessage: 'Subject',
      textColumnNameMessage: 'Text',
      htmlColumnNameMessage: 'HTML',
    },
    form: {
      templateNameInputMessages: {
        label: 'Template Name',
        required: 'Please input template name!',

      },
      subjectInputMessages: {
        label: 'Subject',
        required: 'Please input subject!',
      },
      textAreaInputMessages: {
        label: 'Text',
        required: 'Please input text!',
      },
      htmlEditorInputMessages: {
        label: 'HTML',
        required: 'Please input HTML!',
      },
      newEmailTemplateTitleMessage: 'Add New Template',
      editEmailTemplateTitleMessage: 'Edit Template',
      editButtonMessage: 'Edit',
      createButtonMessage: 'Create',
      confirmModalMessage: 'Are you sure you want to submit this template?',
    },
  },
};
