import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getServices,
  deleteServices,
  updateService,
  addServiceUploads,
  deleteServiceUploads,
} from 'actions/services';
import {
  getServicesApi,
  deleteServicesApi,
  updateServiceApi,
} from 'services/services';
import { addUploadApi, deleteUploadApi } from 'services/uploads';
import fetchEntity from './fetch-entity';

const fetchServices = fetchEntity.bind(null, getServices.actions, getServicesApi);

const fetchUpdateService = fetchEntity.bind(
  null,
  updateService.actions,
  updateServiceApi,
);

const fetchDeleteServices = fetchEntity.bind(
  null,
  deleteServices.actions,
  deleteServicesApi,
);

const fetchAddServiceUploads = fetchEntity.bind(
  null,
  addServiceUploads.actions,
  addUploadApi,
);

const fetchDeleteServiceUploads = fetchEntity.bind(
  null,
  deleteServiceUploads.actions,
  deleteUploadApi,
);

export function* loadUpdateServices({ params }) {
  yield call(fetchUpdateService, { ...params });
}

function* watchUpdateServices() {
  yield takeLatest(updateService.actionName, loadUpdateServices);
}

export function* loadGetServices({ params }) {
  yield call(fetchServices, {
    ...params,
    '$sort[updatedAt]': -1,
  });
}

function* watchGetServices() {
  yield takeLatest([getServices.actionName], loadGetServices);
}

export function* loadGetServicesOnChange({ params }) {
  const type = yield select((state) => state.services.type);
  const services = yield select((state) => state.services[type]);
  const { total, skip, limit } = services;
  if (skip && skip >= total) {
    yield call(fetchServices, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
      type,
    });
  } else {
    yield call(fetchServices, {
      $skip: skip,
      '$sort[updatedAt]': -1,
      type,
    });
  }
}

function* watchGetServicesOnChange() {
  yield takeLatest([deleteServices.requestTypes.SUCCESS], loadGetServicesOnChange);
}

export function* loadDeleteServices({ params }) {
  yield call(fetchDeleteServices, { ...params });
}

function* watchDeleteServices() {
  yield takeLatest(deleteServices.actionName, loadDeleteServices);
}

export function* loadAddServiceUploads({ params }) {
  yield call(fetchAddServiceUploads, { ...params });
}

function* watchAddServiceUploads() {
  yield takeLatest(addServiceUploads.actionName, loadAddServiceUploads);
}

export function* loadDeleteServiceUploads({ params }) {
  yield call(fetchDeleteServiceUploads, { ...params });
}

function* watchDeleteServiceUploads() {
  yield takeLatest(deleteServiceUploads.actionName, loadDeleteServiceUploads);
}

export default {
  watchGetServices,
  watchDeleteServices,
  watchUpdateServices,
  watchAddServiceUploads,
  watchDeleteServiceUploads,
  watchGetServicesOnChange,
};
