import React, { Fragment } from 'react';
import {
  Form,
  Select,
  Radio,
  Checkbox,
} from 'antd';
import { dataSetSources } from 'constants/formBuilder';

const { Option } = Select;

const prefixes = ['$'];
const postfixes = ['%', 'x', ' years', 'sf'];

const SettingsTab = React.memo(({
  type, modalData, setModalData, getFieldDecorator, setFieldsValue,
}) => {
  const { settings } = modalData;
  const dataSetType = (settings && settings.datasetType) || 'custom';
  switch (type) {
    case 'textarea':
      return <Fragment>
        <Form.Item>
          {getFieldDecorator('is_rich_editor', {
            initialValue: (settings && settings.is_rich_editor) || false,
            valuePropName: 'checked',
          })(
            <Checkbox onChange={(e) => setModalData({
              ...modalData,
              settings: {
                ...modalData.settings,
                is_rich_editor: e.target.checked,
              },
            })}> Rich Text Editor </Checkbox>,
          )}
        </Form.Item>
      </Fragment>;
    case 'number':
      return <Fragment>
        <Form.Item>
          {getFieldDecorator('allow_decimals', {
            initialValue: (settings && settings.allow_decimals) || false,
            valuePropName: 'checked',
          })(
            <Checkbox onChange={(e) => setModalData({
              ...modalData,
              settings: {
                ...modalData.settings,
                allow_decimals: e.target.checked,
              },
            })}>Allow Decimals</Checkbox>,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('money_format', {
            initialValue: (settings && settings.money_format) || false,
            valuePropName: 'checked',
          })(
            <Checkbox onChange={(e) => setModalData({
              ...modalData,
              settings: {
                ...modalData.settings,
                money_format: e.target.checked,
              },
            })}> Format as money </Checkbox>,
          )}
        </Form.Item>
        <Form.Item label={'Prefix'}>
          {getFieldDecorator('prefix', {
            initialValue: settings && settings.prefix,
          })(
            <Select
              allowClear
              onChange={(value) => setModalData({
                ...modalData,
                settings: {
                  ...modalData.settings,
                  prefix: value,
                },
              })}>{prefixes.map((prefix) => <Option key={prefix} value={prefix}>{prefix}</Option>)}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label={'Postfix'}>
          {getFieldDecorator('postfix', {
            initialValue: settings && settings.postfix,
          })(
            <Select
              allowClear
              onChange={(value) => setModalData({
                ...modalData,
                settings: {
                  ...modalData.settings,
                  postfix: value,
                },
              })}>{postfixes.map((postfix) => <Option key={postfix} value={postfix} >{postfix}</Option>)}
            </Select>,
          )}
        </Form.Item>
      </Fragment>;
    case 'selectBox':
      return (<Fragment>
        <Form.Item
          label={'Is multiple choice'}
        >
          {getFieldDecorator('is_multiple', {
            initialValue: (settings && settings.is_multiple) || false,
            valuePropName: 'checked',
          })(
            <Checkbox onChange={(e) => setModalData({
              ...modalData,
              settings: {
                ...modalData.settings,
                is_multiple: e.target.checked,
              },
            })} />,
          )}
        </Form.Item>
        <Form.Item
          label={'Dataset type'}
          >
          {getFieldDecorator('datasetType', {
            initialValue: (settings && settings.datasetType) || 'custom',
          })(
            <Radio.Group onChange={(e) => {
              setFieldsValue({
                datasets: undefined,
              });
              setModalData({
                ...modalData,
                settings: {
                  ...modalData.settings,
                  datasetType: e.target.value,
                  datasets: undefined,
                },
              });
            }}>
              <Radio key={'custom'} value={'custom'}>Custom</Radio>
              <Radio key={'from_source'} value={'from_source'}>From Source</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item
          label={'Dataset'}
          validateStatus={modalData.type === 'selectBox' && !settings && settings.datasets ? 'error' : ''}
          >
          {getFieldDecorator('datasets', {
            initialValue: settings && settings.datasets,
            rules: [{ required: true, message: 'Please select data' }],
          })(
            <Select
              mode={dataSetType === 'custom' ? 'tags' : 'default'}
              style={{ width: '100%' }}
              placeholder='Please select or add data'
              onChange={(value) => setModalData({
                ...modalData,
                settings: {
                  ...modalData.settings,
                  datasets: value,
                },
              })}>
              {(settings && settings.datasetType === 'from_source')
                ? Object.keys(dataSetSources).map((res) => <Option value={res} key={res}>{dataSetSources[res]}</Option>) : ''}
            </Select>,
          )}
        </Form.Item>
      </Fragment>);
    default:
      return '';
  }
});

export default SettingsTab;
