import { messages } from 'messages';

const {
  companyNameInputMessages,
  ratingInputMessages,
  statusSelelctMessages,
  countrySelelctMessages,
  categorySelelctMessages,
  websiteInputMessages,
  addressInputMessages,
  logoInputMessages,
  minLoanInputMessages,
  maxLoanInputMessages,
  minDSCRInputMessages,
  maxLTVInputMessages,
  feeMaxInputMessages,
  feeMinInputMessages,
  maxTermInputMessages,
  minTermInputMessages,
  syndicateSelelctMessages,
  syndicateNoteInputMessages,
  portfolioSizeInputMessages,
  numberOfLoansInputMessages,
  minAmortizationInputMessages,
  maxAmortizationInputMessages,
  interestOnlySwitchMessages,
  propertyClassSelelctMessages,
  marketsSelelctMessages,
  loanTypeSelelctMessages,
  propertyTypeSelelctMessages,
  stateProvinceSelectMessages,
  notesInputMessages,
  aboutCombanyHTMLMessages,
  rateTypeSelectMessages,
  portfolioMixConstructionInputMessages,
  portfolioMixBridgeInputMessages,
  portfolioMixTermInputMessages,
  portfolioMixCMHCInputMessages,
  originationsValueInputMessages,
  originationsYearSelectMessages,
  pricingModelLoanTypesSelectMessages,
  pricingModelIndexTypeSelectMessages,
  pricingModelNonRecourseSwitchMessages,
  pricingModelSpreadMinInputMessages,
  pricingModelSpreadMaxInputMessages,
  relationshipLenderInputMessages,
} = messages.landersCrudMessages;

const generateYears = () => {
  const years = [];
  for (let i = 2010; i <= (new Date()).getFullYear(); i += 1) {
    years.push(i);
  }
  return years;
};

const maxInt = 2147483647;
const formItemsConfig = {
  companyNameInput: {
    label: companyNameInputMessages.label,
    fieldName: 'companyName',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: companyNameInputMessages.required,
        },
        { max: 250 },
      ],
    },
    elementProps: {},
  },
  ratingInput: {
    label: ratingInputMessages.label,
    fieldName: 'rating',
    decoratorOptions: {
      rules: [{ min: 0, max: 100, type: 'integer' }],
    },
    elementProps: {
      style: { width: '100%' },
    },
  },
  relationshipLenderInput: {
    label: relationshipLenderInputMessages.label,
    fieldName: 'relationshipLender',
    decoratorOptions: {
      rules: [],
      valuePropName: 'checked',
    },
    elementProps: {},
  },
  statusSelelct: {
    label: statusSelelctMessages.label,
    fieldName: 'status',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: statusSelelctMessages.required,
        },
        { type: 'enum', enum: ['active', 'deleted'] },
      ],
      initialValue: 'active',
    },
    elementProps: {},
    options: ['active', 'deleted'],
  },
  countrySelelct: {
    label: countrySelelctMessages.label,
    fieldName: 'country',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: countrySelelctMessages.required,
        },
      ],
    },
    elementProps: {},
    options: [
      { id: 'ca', name: 'Canada' },
      { id: 'us', name: 'USA' },
    ],
  },
  categorySelelct: {
    label: categorySelelctMessages.label,
    fieldName: 'categoryId',
    decoratorOptions: {
      rules: [{ required: true, message: categorySelelctMessages.required }],
    },
    elementProps: {},
    options: [],
  },
  websiteInput: {
    label: websiteInputMessages.label,
    fieldName: 'website',
    decoratorOptions: {
      rules: [{ max: 60, type: 'url' }],
    },
    elementProps: {},
  },
  addressInput: {
    label: addressInputMessages.label,
    fieldName: 'address',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  logoInput: {
    label: logoInputMessages.label,
    fieldName: 'logo',
    decoratorOptions: {
      rules: [{ max: 250, type: 'url' }],
    },
    elementProps: {},
  },
  minLoanInput: {
    label: minLoanInputMessages.label,
    fieldName: 'minLoan',
    decoratorOptions: {
      rules: [{ max: Number.MAX_SAFE_INTEGER, min: 0, type: 'integer' }],
    },
    elementProps: {
      style: { width: '100%' },
      min: 0,
      formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      parser: (value) => value.replace(/\$\s?|(,*)/g, ''),
    },
  },
  maxLoanInput: {
    label: maxLoanInputMessages.label,
    fieldName: 'maxLoan',
    decoratorOptions: {
      rules: [{ max: Number.MAX_SAFE_INTEGER, min: 0, type: 'integer' }],
    },
    elementProps: {
      style: { width: '100%' },
      min: 0,
      formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      parser: (value) => value.replace(/\$\s?|(,*)/g, ''),
    },
  },
  minDSCRInput: {
    label: minDSCRInputMessages.label,
    fieldName: 'minDSCR',
    decoratorOptions: {
      rules: [{ max: 10, min: 0, type: 'number' }],
    },
    elementProps: { style: { width: '100%' }, min: 0 },
  },
  maxLTVInput: {
    label: maxLTVInputMessages.label,
    fieldName: 'maxLTV',
    decoratorOptions: {
      rules: [{ max: 100, min: 0, type: 'integer' }],
    },
    elementProps: { style: { width: '100%' }, min: 0 },
  },
  feeMaxInput: {
    label: feeMaxInputMessages.label,
    fieldName: 'feeMax',
    decoratorOptions: {
      rules: [{ max: maxInt, min: 0, type: 'integer' }],
    },
    elementProps: { style: { width: '100%' }, min: 0 },
  },
  feeMinInput: {
    label: feeMinInputMessages.label,
    fieldName: 'feeMin',
    decoratorOptions: {
      rules: [{ max: maxInt, min: 0, type: 'integer' }],
    },
    elementProps: { style: { width: '100%' }, min: 0 },
  },
  maxTermInput: {
    label: maxTermInputMessages.label,
    fieldName: 'maxTerm',
    decoratorOptions: {
      rules: [{ max: 20, min: 0, type: 'number' }],
    },
    elementProps: { style: { width: '100%' }, min: 0, precision: 2 },
  },
  minTermInput: {
    label: minTermInputMessages.label,
    fieldName: 'minTerm',
    decoratorOptions: {
      rules: [
        {
          max: 20,
          min: 0,
          type: 'number',
        }],
    },
    elementProps: {
      style: { width: '100%' },
      min: 0,
      precision: 2,
    },
  },
  syndicateSelelct: {
    label: syndicateSelelctMessages.label,
    fieldName: 'syndicate',
    decoratorOptions: {
      rules: [],
    },
    options: ['Both', 'Lead', 'Secondary', 'None'],
    elementProps: {},
  },
  syndicateNoteInput: {
    label: syndicateNoteInputMessages.label,
    fieldName: 'syndicateNote',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  portfolioSizeInput: {
    label: portfolioSizeInputMessages.label,
    fieldName: 'portfolioSize',
    decoratorOptions: {
      rules: [{ max: Number.MAX_SAFE_INTEGER, min: 0, type: 'integer' }],
    },
    elementProps: {
      style: { width: '100%' },
      min: 0,
      formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      parser: (value) => value.replace(/\$\s?|(,*)/g, ''),
    },
  },
  numberOfLoansInput: {
    label: numberOfLoansInputMessages.label,
    fieldName: 'numberOfLoans',
    decoratorOptions: {
      rules: [{ max: maxInt, min: 0, type: 'integer' }],
    },
    elementProps: { style: { width: '100%' }, min: 0 },
  },
  minAmortizationInput: {
    label: minAmortizationInputMessages.label,
    fieldName: 'minAmortization',
    decoratorOptions: {
      rules: [{ max: 50, min: 0, type: 'integer' }],
    },
    elementProps: { style: { width: '100%' }, min: 0 },
  },
  maxAmortizationInput: {
    label: maxAmortizationInputMessages.label,
    fieldName: 'maxAmortization',
    decoratorOptions: {
      rules: [{ max: 50, min: 0, type: 'integer' }],
    },
    elementProps: { style: { width: '100%' }, min: 0 },
  },
  interestOnlySwitch: {
    label: interestOnlySwitchMessages.label,
    fieldName: 'io',
    decoratorOptions: {
      rules: [],
      initialValue: false,
      valuePropName: 'checked',
    },
    elementProps: {},
  },
  propertyClassSelelct: {
    label: propertyClassSelelctMessages.label,
    fieldName: 'propertyClasses',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {
      mode: 'multiple',
    },
    options: [],
  },
  marketsSelelct: {
    label: marketsSelelctMessages.label,
    fieldName: 'markets',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {
      mode: 'multiple',
    },
    options: [],
  },
  loanTypeSelelct: {
    label: loanTypeSelelctMessages.label,
    fieldName: 'loanTypes',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {
      mode: 'multiple',
    },
    options: [],
  },
  propertyTypeSelelct: {
    label: propertyTypeSelelctMessages.label,
    fieldName: 'propertyTypes',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {
      mode: 'multiple',
    },
    options: [],
  },
  stateProvinceSelect: {
    label: stateProvinceSelectMessages.label,
    fieldName: 'stateProvinces',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {
      mode: 'multiple',
    },
    options: [],
  },
  notesInput: {
    label: notesInputMessages.label,
    fieldName: 'notes',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {
      rows: 4,
    },
  },
  aboutCombanyHTMLEditor: {
    label: aboutCombanyHTMLMessages.label,
    fieldName: 'aboutCompany',
    decoratorOptions: {
      rules: [],
    },
    elementProps: { style: { height: '300px' } },
  },
  rateTypeSelect: {
    label: rateTypeSelectMessages.label,
    fieldName: 'rateType',
    decoratorOptions: {
      rules: [{ type: 'enum', enum: ['Fixed', 'Variable', 'Both'] }],
    },
    options: [
      { id: 'Fixed', name: 'Fixed' },
      { id: 'Variable', name: 'Variable' },
      { id: 'Both', name: 'Both' },
    ],
  },
  portfolioMixConstructionInput: {
    label: portfolioMixConstructionInputMessages.label,
    fieldName: 'portfolioMixConstruction',
    decoratorOptions: {
      rules: [{ type: 'integer', min: 0, max: 100 }],
    },
    elementProps: { style: { width: '100%' } },
  },
  portfolioMixBridgeInput: {
    label: portfolioMixBridgeInputMessages.label,
    fieldName: 'portfolioMixBridge',
    decoratorOptions: {
      rules: [{ type: 'integer', min: 0, max: 100 }],
    },
    elementProps: { style: { width: '100%' } },
  },
  portfolioMixTermInput: {
    label: portfolioMixTermInputMessages.label,
    fieldName: 'portfolioMixTerm',
    decoratorOptions: {
      rules: [{ type: 'integer', min: 0, max: 100 }],
    },
    elementProps: { style: { width: '100%' } },
  },
  portfolioMixCMHCInput: {
    label: portfolioMixCMHCInputMessages.label,
    fieldName: 'portfolioMixCmhc',
    decoratorOptions: {
      rules: [{ type: 'integer', min: 0, max: 100 }],
    },
    elementProps: { style: { width: '100%' } },
  },
  pricingModelSpreadMinInput: {
    label: pricingModelSpreadMinInputMessages.label,
    fieldName: 'pricingModelSpreadMin',
    decoratorOptions: {
      rules: [{ type: 'number', min: 0, max: 100 },
        { required: true, message: pricingModelSpreadMinInputMessages.required }],
    },
    elementProps: { style: { width: '100%' } },
  },
  pricingModelSpreadMaxInput: {
    label: pricingModelSpreadMaxInputMessages.label,
    fieldName: 'pricingModelSpreadMax',
    decoratorOptions: {
      rules: [{ type: 'number', min: 0, max: 100 },
        { required: true, message: pricingModelSpreadMaxInputMessages.required }],
    },
    elementProps: { style: { width: '100%' } },
  },
  originationsYearSelect: {
    label: originationsYearSelectMessages.label,
    fieldName: 'originationsYears',
    decoratorOptions: {
      rules: [
        { type: 'integer', min: 2010, max: Number.MAX_SAFE_INTEGER },
        { required: true, message: originationsYearSelectMessages.required }],
    },
    options: generateYears(),
    elementProps: {},
  },
  originationsValueInput: {
    label: originationsValueInputMessages.label,
    fieldName: 'originationsValues',
    decoratorOptions: {
      rules: [{ type: 'integer', min: 0, max: Number.MAX_SAFE_INTEGER }],
    },
    elementProps: {
      style: { width: '100%' },
      formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      parser: (value) => value.replace(/\$\s?|(,*)/g, ''),
    },
  },
  pricingModelLoanTypesSelect: {
    label: pricingModelLoanTypesSelectMessages.label,
    fieldName: 'pricingModelLoanTypes',
    decoratorOptions: {
      rules: [{ required: true, message: pricingModelLoanTypesSelectMessages.required }],
    },
    elementProps: {
      dropdownMatchSelectWidth: false,
    },
    options: [],
  },
  pricingModelIndexTypeSelect: {
    label: pricingModelIndexTypeSelectMessages.label,
    fieldName: 'pricingModelIndexType',
    decoratorOptions: {
      rules: [
        { required: true, message: pricingModelIndexTypeSelectMessages.required },
        { type: 'enum', enum: ['AMERIBOR', 'BANK PRIME', 'SOFR', 'US-T', 'BA', 'GOC', 'PRIME', 'SWAP', 'CDOR'] },
      ],
    },
    options: {
      us: ['AMERIBOR', 'BANK PRIME', 'SOFR', 'US-T'],
      ca: ['BA', 'GOC', 'PRIME', 'SWAP', 'CDOR'],
    },
    elementProps: {},
  },
  pricingModelNonRecourseSwitch: {
    label: pricingModelNonRecourseSwitchMessages.label,
    fieldName: 'pricingModelNonRecourse',
    decoratorOptions: {
      rules: [],
      initialValue: false,
      valuePropName: 'checked',
    },
    elementProps: {},
  },
};

export default formItemsConfig;
