import { makeRequestAction } from './index';

export const getMarkets = makeRequestAction('GET_MARKETS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addNewMarkets = makeRequestAction('ADD_NEW_MARKET');

export const deleteMarkets = makeRequestAction('DELETE_MARKET', {
  onSuccess(params, response) {
    return {
      ids: response.map((len) => len.id),
    };
  },
});

export const updateMarkets = makeRequestAction('UPDATE_MARKET', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getPageMarkets = makeRequestAction('GET_PAGE_MARKETS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
