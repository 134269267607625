import { messages } from 'messages';

const {
  companyNameInputMessages,
  aboutCompanyHTMLEditorMessages,
  logoInputMessages,
} = messages.userCompanies.form;

const formItemsConfig = {
  companyNameInput: {
    label: companyNameInputMessages.label,
    fieldName: 'name',
    decoratorOptions: {
      rules: [{ required: true, message: companyNameInputMessages.required }],
    },
    elementProps: {},
  },
  logoInput: {
    label: logoInputMessages.label,
    fieldName: 'logo',
    decoratorOptions: {
      rules: [{ max: 250, type: 'url' }],
    },
    elementProps: {},
  },
  aboutCompanyHTMLEditor: {
    label: aboutCompanyHTMLEditorMessages.label,
    fieldName: 'about',
    decoratorOptions: {
      rules: [],
    },
    elementProps: { style: { height: '300px' } },
  },
};

export default formItemsConfig;
