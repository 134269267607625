import { makeRequestAction } from './index';

export const getCategories = makeRequestAction('GET_CONSULTANT_CATEGORIES', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const addNewCategories = makeRequestAction('ADD_NEW_CONSULTANT_CATEGORY');

export const deleteCategories = makeRequestAction('DELETE_CONSULTANT_CATEGORY', {
  onSuccess(params, response) {
    return {
      response: { ids: response.map((len) => len.id) },
    };
  },
});

export const updateCategories = makeRequestAction('UPDATE_CONSULTANT_CATEGORY', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const getPageCategories = makeRequestAction('GET_PAGE_CONSULTANT_CATEGORIES', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
