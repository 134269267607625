import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getEmailTemplates, deleteEmailTemplates, addNewEmailTemplate, updateEmailTemplates, getPageEmailTemplates,
} from 'actions/emailTemplates';
import {
  getEmailTemplatesApi, deleteEmailTemplatesApi, addNewEmailTemplateApi, updateEmailTemplatesApi,
} from 'services/emailTemplates';
import fetchEntity from './fetch-entity';

const fetchEmailTemplates = fetchEntity.bind(null, getEmailTemplates.actions, getEmailTemplatesApi);
const fetchPageEmailTemplates = fetchEntity.bind(null, getPageEmailTemplates.actions, getEmailTemplatesApi);
const fetchAddNewEmailTemplate = fetchEntity.bind(
  null,
  addNewEmailTemplate.actions,
  addNewEmailTemplateApi,
);

const fetchUpdateEmailTemplate = fetchEntity.bind(
  null,
  updateEmailTemplates.actions,
  updateEmailTemplatesApi,
);

const fetchDeleteEmailTemplates = fetchEntity.bind(
  null,
  deleteEmailTemplates.actions,
  deleteEmailTemplatesApi,
);

export function* loadGetPageEmailTemplates({ params }) {
  yield call(fetchPageEmailTemplates, { ...params, '$sort[updatedAt]': -1 });
}

function* watchGetPageEmailTemplates() {
  yield takeLatest([getPageEmailTemplates.actionName], loadGetPageEmailTemplates);
}

export function* loadGetPageEmailTemplatesOnChange(action) {
  const emailTemplates = yield select((state) => state.emailTemplates);
  const { total, skip, limit } = emailTemplates;
  if (skip && skip >= total) {
    yield call(fetchPageEmailTemplates, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchPageEmailTemplates, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetPageEmailTemplatesOnChange() {
  yield takeLatest([
    deleteEmailTemplates.requestTypes.SUCCESS,
    addNewEmailTemplate.requestTypes.SUCCESS,
    updateEmailTemplates.requestTypes.SUCCESS,
  ], loadGetPageEmailTemplatesOnChange);
}

export function* loadGetEmailTemplates({ params }) {
  yield call(fetchEmailTemplates, { ...params, '$sort[updatedAt]': -1 });
}
function* watchGetEmailTemplates() {
  yield takeLatest(getEmailTemplates.actionName, loadGetEmailTemplates);
}

export function* loadUpdateEmailTemplates({ params }) {
  yield call(fetchUpdateEmailTemplate, { ...params });
}

function* watchUpdateEmailTemplates() {
  yield takeLatest(updateEmailTemplates.actionName, loadUpdateEmailTemplates);
}

export function* loadAddNewEmailTemplate({ params }) {
  yield call(fetchAddNewEmailTemplate, { ...params });
}

function* watchAddNewEmailTemplate() {
  yield takeLatest(addNewEmailTemplate.actionName, loadAddNewEmailTemplate);
}
export function* loadDeleteEmailTemplates({ params }) {
  yield call(fetchDeleteEmailTemplates, { ...params });
}

function* watchDeleteEmailTemplates() {
  yield takeLatest(deleteEmailTemplates.actionName, loadDeleteEmailTemplates);
}

export default {
  watchGetEmailTemplates,
  watchUpdateEmailTemplates,
  watchAddNewEmailTemplate,
  watchDeleteEmailTemplates,
  watchGetPageEmailTemplates,
  watchGetPageEmailTemplatesOnChange,
};
