import React, { useEffect, useState } from 'react';
import { useDidUpdateEffect } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Icon, Switch, message, Modal, Form,
} from 'antd';
import {
  Table, Button, FormItem, Input,
} from 'components';
import {
  getForms,
  getFormBuilderLoading,
  getFormBuilderError,
  addFormLoading,
  addFormError,
  deleteFormLoading,
} from 'selectors/formBuilder';
import { getForms as fetchForms, addForm, deleteForm } from 'actions/formBuilder';

export default Form.create('clone-modal-form')(React.memo(({
  history, updateForm, isUpdating, form,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getFormBuilderLoading);
  const error = useSelector(getFormBuilderError);
  const addError = useSelector(addFormError);
  const addLoading = useSelector(addFormLoading);
  const isDeleteLoading = useSelector(deleteFormLoading);
  const forms = useSelector(getForms);

  const [page, setPage] = useState(1);
  const [currentRecord, setRecord] = useState();

  const columns = [
    {
      title: 'Form Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Created By',
      dataIndex: 'authorName',
      key: 'authorName',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('LL'),
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive, record) => <Switch
        size={'small'}
        loading={isUpdating}
        onChange={(value) => {
          updateForm({
            ...record,
            isActive: value,
          });
        }}
        defaultChecked={isActive} />,
    },
    {
      title: '',
      dataIndex: '_id',
      key: '_id',
      width: 130,
      render: (id, record, index) => record.hovered
        && <>
          <Button
            type='default'
            size='small'
            onClick={() => {
              setRecord(record);
            }}
          >
            <Icon type='copy' theme='filled' size='large' />
          </Button>
          {' '}
          <Button
            type='default'
            size='small'
            onClick={() => history.push(`/form-builder/${id}`)}
          >
            <Icon type='edit' theme='filled' size='large' />
          </Button>
          {' '}
          <Button
            type='danger'
            size='small'
            onClick={() => showDeleteConfirm(id)}
            disabled={record.id && record.id.includes('template')}
          >
            <Icon type='delete' theme='filled' size='large' />
          </Button>
        </>
      ,
    },
  ];

  const showDeleteConfirm = (_id) => {
    Modal.confirm({
      title: 'Permanently delete this form?',
      onOk() {
        dispatch(deleteForm.request({ _id }));
      },
    });
  };

  const closeModal = () => {
    setRecord();
    form.resetFields();
  };

  useEffect(() => {
    dispatch(fetchForms.request({ $skip: (page - 1) * 10 }));
  }, [dispatch, page]);

  useEffect(() => {
    if (error && error.message) {
      message.error(error.message);
    }
  }, [error]);

  useDidUpdateEffect(() => {
    if (!addError && !addLoading) {
      closeModal();
    }
  }, [addError, addLoading]);

  return <>
    <Table
      columns={columns}
      rowKey={'_id'}
      pagination={{
        current: page,
        total: forms.total,
        pageSize: 10,
        onChange: (current) => setPage(current),
      }}
      dataSource={forms.data}
      loading={isLoading || isDeleteLoading}
  />
    <Modal
      title='Clone Form'
      onOk={() => {
        form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            const { _id, ...filteredRecord } = currentRecord;
            dispatch(addForm.request({ ...filteredRecord, name: values.Name }));
          }
          return true;
        });
      }}
      onCancel={closeModal}
      okButtonProps={{
        loading: addLoading,
      }}
      visible={!!currentRecord}>
      <Form hideRequiredMark layout={'vertical'}>
        <FormItem label='Cloned Form Name'>
          {form.getFieldDecorator('Name', { rules: [{ required: true }] })(
            <Input />,
          )}
        </FormItem>
      </Form>
    </Modal>
  </>;
}));
