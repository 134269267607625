import { createSelector } from 'reselect';
import {
  getPropertyClasses as fetchPropertyClasses, getPagePropertyClasses, addNewPropertyClasses, deletePropertyClasses, updatePropertyClasses,
} from 'actions/propertyClasses';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getPropertyClassesState = (state) => state.propertyClasses;
export const getErrorsState = (state) => state.error;

export const getPropertyClasses = createSelector(
  getPropertyClassesState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const propertyClassesLoading = createLoadingSelector(fetchPropertyClasses.actionName)();
export const propertyClassesError = createErrorSelector(fetchPropertyClasses.actionName)();


export const pagePropertyClassesLoading = createLoadingSelector(getPagePropertyClasses.actionName)();
export const pagePropertyClassesError = createErrorSelector(getPagePropertyClasses.actionName)();

export const addNewPropertyClassesLoading = createLoadingSelector(addNewPropertyClasses.actionName)();
export const addNewPropertyClassesError = createErrorSelector(addNewPropertyClasses.actionName)();

export const deletePropertyClassesLoading = createLoadingSelector(deletePropertyClasses.actionName)();
export const deletePropertyClassesError = createErrorSelector(deletePropertyClasses.actionName)();

export const getPropertyClassesAllErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      addNewPropertyClasses.actionName,
      deletePropertyClasses.actionName,
      updatePropertyClasses.actionName,
      getPagePropertyClasses.actionName,
      getPropertyClasses.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);
