import { all, fork } from 'redux-saga/effects';
import lenders from './lenders';
import markets from './markets';
import loanTypes from './loanTypes';
import propertyTypes from './propertyTypes';
import stateProvinces from './stateProvinces';
import propertyClasses from './propertyClasses';
import categories from './categories';
import auth from './auth';
import users from './users';
import countries from './countries';
import formBuilder from './formBuilder';
import userCompanies from './userCompanies';
import contacts from './contacts';
import services from './services';
import emailTemplates from './emailTemplates';
import consultants from './consultants';
import consultantCategories from './consultantCategories';
import consultantContacts from './consultantContacts';
import roles from './roles';
import serviceRoles from './roleManagements';
import brokerListings from './brokerListings';

const combinedSagas = {
  ...lenders,
  ...markets,
  ...loanTypes,
  ...propertyTypes,
  ...stateProvinces,
  ...propertyClasses,
  ...categories,
  ...users,
  ...countries,
  ...formBuilder,
  ...auth,
  ...userCompanies,
  ...contacts,
  ...services,
  ...emailTemplates,
  ...consultants,
  ...consultantCategories,
  ...consultantContacts,
  ...roles,
  ...serviceRoles,
  ...brokerListings,
};

export default function* rootSaga() {
  yield all(Object.values(combinedSagas).map(fork));
}
