import { makeRequestAction } from './index';

export const getPropertyClasses = makeRequestAction('GET_PROPERTY_CLASSES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addNewPropertyClasses = makeRequestAction('ADD_NEW_PROPERTY_CLASSE');

export const deletePropertyClasses = makeRequestAction('DELETE_PROPERTY_CLASSE', {
  onSuccess(params, response) {
    return {
      ids: response.map((len) => len.id),
    };
  },
});

export const updatePropertyClasses = makeRequestAction('UPDATE_PROPERTY_CLASSE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getPagePropertyClasses = makeRequestAction('GET_PAGE_PROPERTY_CLASSES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
