import { createSelector } from 'reselect';
import {
  getForms as fetchForms, getForm, updateForm, addForm, deleteForm,
} from 'actions/formBuilder';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getFormBuilder = (state) => state.formBuilder;

export const getSuccessState = createSelector(
  getFormBuilder,
  (state) => state.isSuccessful,
);

export const getForms = createSelector(
  getFormBuilder,
  (state) => ({ ...state.forms, data: state.forms ? state.forms.data : [] }),
);

export const getSelectedForm = createSelector(
  getFormBuilder,
  (data) => data.selectedForm,
);

export const getFormBuilderLoading = createLoadingSelector(
  fetchForms.actionName,
)();
export const getFormBuilderError = createErrorSelector(
  fetchForms.actionName,
)();

export const getSelectedFormLoading = createLoadingSelector(
  getForm.actionName,
)();
export const getSelectedFormError = createErrorSelector(
  getForm.actionName,
)();

export const getUpdateFormLoading = createLoadingSelector(
  updateForm.actionName,
)();
export const getUpdateFormError = createErrorSelector(
  updateForm.actionName,
)();

export const addFormLoading = createLoadingSelector(addForm.actionName)();
export const addFormError = createErrorSelector(addForm.actionName)();

export const deleteFormLoading = createLoadingSelector(deleteForm.actionName)();
export const deleteFormError = createErrorSelector(deleteForm.actionName)();
