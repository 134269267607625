import React from 'react';
import { Button as AntButton } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(AntButton)`
  ${(props) => props.type === 'success'
    && `
    // color: #ffffff !important;
    background-color: #85ae00;

    :hover {
      background-color: #9bba1e;
      border-color: #9bba1e !important;
    }

    :active {
      background-color: #638700 !important;
      border-color: #638700 !important;
    }

    :focus {
      background-color: #9bba1e;
      border-color: #9bba1e !important;
    }
  `}
`;

const Button = (props) => <StyledButton {...props} />;

export default Button;
