import { getPropertyTypes, getPagePropertyTypes, deletePropertyTypes } from 'actions/propertyTypes';

const propertyTypes = () => {
  const initialState = {};

  return (state = initialState, { type, response, ids }) => {
    switch (type) {
      case getPropertyTypes.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getPagePropertyTypes.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case deletePropertyTypes.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      default:
        return state;
    }
  };
};

export default propertyTypes();
