import { makeRequestAction } from './index';

export const getConsultantContacts = makeRequestAction('GET_CONSULTANT_CONTACTS', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const getPageConsultantContacts = makeRequestAction('GET_PAGE_CONSULTANT_CONTACTS', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const addNewConsultantContact = makeRequestAction('ADD_NEW_CONSULTANT_CONTACT', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const deleteConsultantContacts = makeRequestAction('DELETE_CONSULTANT_CONTACT', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const updateConsultantContacts = makeRequestAction('UPDATE_CONSULTANT_CONTACT', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
