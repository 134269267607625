import React, {
  useEffect, useState, useCallback,
} from 'react';
import {
  Button, Table, Title, Input,
} from 'components';
import styled from 'styled-components';
import {
  usersLoading as getLoadingSelector,
  pageUsersLoading as getPageLoadingSelector,
  deleteUsersLoading as deleteLoadingSelector,
  usersError as getErrorSelector,
  deleteUsersError as deleteErrorSelector,
  getUsers,
} from 'selectors/users';
import {
  Icon, Modal, message, Checkbox,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUsers as getUsersAction,
  deleteUsers as deleteUsersAction,
  updateUsers,
} from 'actions/users';
import { Link } from 'react-router-dom';

import routes from 'constants/routes';
import { messages } from 'messages';

const {
  userListTitleMessage,
  newUserButtonMessage,
  unselectButtonMessage,
  userEditButtonMessage,
  confirmDeleteMessage,
  emailColumnNameMessage,
  userRoleComulnNameMessage,
  // isVerifiedColumnNameMessage,
  // isActiveColumnNameMessage,
  // isApprovedColumnNameMessage,
  companyNameColumnNameMessage,
  userParentAccountEmail,
  // userExpiryColumnNameMessage,
} = messages.usersTableMessages;

const { confirm } = Modal;

const Footer = styled.div`
  margin-top: 10px;
  float: right;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const UsersPage = (props) => {
  const PAGE_SIZE = 15;
  const dispatch = useDispatch();

  const userCompanyId = props.location.state && props.location.state.userCompanyId;
  const users = useSelector(getUsers);
  const getLoading = useSelector(getLoadingSelector);
  const getPageLoading = useSelector(getPageLoadingSelector);
  const deleteLoading = useSelector(deleteLoadingSelector);
  const getError = useSelector(getErrorSelector);
  const deleteError = useSelector(deleteErrorSelector);

  const [page, setPage] = useState(1);
  const [rowKeys, setRowKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    if (users.data.length > 0) {
      setUsersData(users);
    }
    // eslint-disable-next-line
  }, [users]);

  const handleAccessChange = (e, userObj, accessType) => {
    const getUserSettings = (accessTypeValue, checked) => {
      const baseSettings = {
        dealEmailLimit: userObj.user_setting.dealEmailLimit,
        accessLendersBasic: false,
        accessLendersIntermediate: false,
        accessLendersAdvanced: false,
        accessConsultantsBasic: userObj.user_setting.accessConsultantsBasic,
        accessConsultantsIntermediate: userObj.user_setting.accessConsultantsIntermediate,
        accessConsultantsAdvanced: userObj.user_setting.accessConsultantsAdvanced,
      };

      switch (accessTypeValue) {
        case 'accessLendersBasic':
          baseSettings.accessLendersBasic = checked;
          break;
        case 'accessLendersIntermediate':
          baseSettings.accessLendersIntermediate = checked;
          break;
        case 'accessLendersAdvanced':
          baseSettings.accessLendersAdvanced = checked;
          break;
        default:
          break;
      }

      return baseSettings;
    };

    const modifiedValues = {
      accountExpireDate: userObj.accountExpireDate,
      username: userObj.username,
      dealEmailLimit: userObj.user_setting.dealEmailLimit,
      user_detail: userObj.user_detail,
      parent: userObj.parent,
      userCompanyName: userObj.user_company.name,
      user_setting: getUserSettings(accessType, e.target.checked),
    };

    dispatch(
      updateUsers.request({ id: userObj.id, values: modifiedValues }),
    );
  };

  useEffect(() => {
    dispatch(getUsersAction.request({ companyId: userCompanyId }));
  }, [dispatch, userCompanyId]);

  useEffect(() => {
    getError && message.error(`Error ${getError.code}: ${getError.message}`);
    deleteError
      && message.error(`Error ${deleteError.code}: ${deleteError.message}`);
  }, [deleteError, getError]);

  const deleteUsers = useCallback(
    () => {
      dispatch(deleteUsersAction.request({ 'id[$in]': rowKeys }));
      setRowKeys([]);
    }, [dispatch, rowKeys],
  );

  const showDeleteDialog = () => {
    confirm({
      title: confirmDeleteMessage,
      okType: 'danger',
      onOk() {
        deleteUsers();
      },
    });
  };

  const handleSearch = (name) => {
    dispatch(
      getUsersAction.request({
        'email[$iLike]': `%${name}%`,
      }),
    );
  };
  const columns = [
    {
      title: companyNameColumnNameMessage,
      dataIndex: 'userCompanyName',
      key: 'userCompanyName',
      render: (text, record) => record.user_company && record.user_company.name,
      sorter: (a, b) => (a.user_company.name.toLowerCase() < b.user_company.name.toLowerCase() ? -1 : 1),
    },
    {
      title: emailColumnNameMessage,
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => (a.email.toLowerCase() < b.email.toLowerCase() ? -1 : 1),
    },
    {
      title: userRoleComulnNameMessage,
      dataIndex: 'roles',
      key: 'roles',
      sorter: (a, b) => (a.roles.toLowerCase() < b.roles.toLowerCase() ? -1 : 1),
    },
    // delete the commented code below if not needed in future
    // {
    //   title: isVerifiedColumnNameMessage,
    //   dataIndex: 'isVerified',
    //   key: 'isVerified',
    //   render: (text, record) => <Checkbox defaultChecked={record.isVerified} disabled />,
    // },
    // {
    //   title: isActiveColumnNameMessage,
    //   dataIndex: 'isActive',
    //   key: 'isActive',
    //   render: (text, record) => <Checkbox defaultChecked={record.isActive} disabled />,
    // },
    // {
    //   title: isApprovedColumnNameMessage,
    //   dataIndex: 'approved',
    //   key: 'approved',
    //   render: (text, record) => <Checkbox defaultChecked={record.approved} disabled />,
    // },
    {
      title: 'Basic Access',
      dataIndex: 'accessLendersBasic',
      key: 'accessLendersBasic',
      sorter: (a, b) => (a.user_setting.accessLendersBasic < b.user_setting.accessLendersBasic ? -1 : 1),
      render: (text, record) => <Checkbox
        checked={record.user_setting && record.user_setting.accessLendersBasic}
        disabled={record.user_setting && record.user_setting.accessLendersBasic}
        onChange={(e) => handleAccessChange(e, record, 'accessLendersBasic')}

      />,
    },
    {
      title: 'Intermediate Access',
      dataIndex: 'accessLendersIntermediate',
      key: 'accessLendersIntermediate',
      sorter: (a, b) => (a.user_setting.accessLendersIntermediate < b.user_setting.accessLendersIntermediate ? -1 : 1),
      render: (text, record) => <Checkbox
        checked={record.user_setting && record.user_setting.accessLendersIntermediate}
        disabled={record.user_setting && record.user_setting.accessLendersIntermediate}
        onChange={(e) => handleAccessChange(e, record, 'accessLendersIntermediate')}
       />,
    },
    {
      title: 'Advanced Access',
      dataIndex: 'accessLendersAdvanced',
      key: 'accessLendersAdvanced',
      sorter: (a, b) => (a.user_setting.accessLendersAdvanced < b.user_setting.accessLendersAdvanced ? -1 : 1),
      render: (text, record) => <Checkbox
        checked={record.user_setting && record.user_setting.accessLendersAdvanced}
        disabled={record.user_setting && record.user_setting.accessLendersAdvanced}
        onChange={(e) => handleAccessChange(e, record, 'accessLendersAdvanced')}
      />,
    },
    {
      // This is a placeholder for Parent Account column, do NOT delete!
    },
    // {
    //   title: userExpiryColumnNameMessage,
    //   dataIndex: 'expiry',
    //   key: 'expiry',
    //   render: (text, record) => (record.accountExpireDate ? record.accountExpireDate.slice(0, 10) : ''),
    // },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 100,
      render: (text, record) => record.hovered
      && <Link
        to={{
          pathname: routes.users.form.pathname,
          state: { currentUser: record },
        }}
      >
        <Button type='default' size='small'>
          <Icon type='edit' theme='filled' />
          {userEditButtonMessage}
        </Button>
      </Link>,
    },
  ];


  const parentAccountColumn = {
    title: userParentAccountEmail,
    dataIndex: 'parent',
    key: 'parent',
    // eslint-disable-next-line consistent-return
    render: (text, record) => {
      for (const user of users.data) {
        if (user.id === record.parent) {
          return user.email;
        }
      }
    },
    sorter: (a, b) => (a.parent < b.parent ? -1 : 1),
  };
  columns[6] = parentAccountColumn;

  return (
    <div>
      <Title level={4}>
        {userListTitleMessage}
        <Link
          to={{
            pathname: routes.users.form.pathname,
            state: { currentUser: null },
          }}
        >
          <Button type='success' size='large'>
            {newUserButtonMessage}
          </Button>
        </Link>
      </Title>
      <div style={{ padding: 8 }}>
        <Input
          placeholder={'Search by name'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys[0])}
          style={{ width: 300, marginBottom: 8, display: 'block' }}
    />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys[0])}
          icon='search'
          size='small'
          style={{ width: 192, marginRight: 8 }}>Search
        </Button>
        <Button onClick={() => handleSearch('')} size='small' style={{ width: 100 }}>Reset</Button>
      </div>
      <div>
        <Table
          loading={getLoading || deleteLoading || getPageLoading}
          rowKey='id'
          pagination={{
            current: page,
            total: usersData.total,
            pageSize: PAGE_SIZE,
            onChange: (current) => setPage(current),
          }}
          dataSource={usersData.data}
          columns={columns}
          rowSelection={{
            selectedRowKeys: rowKeys,
            onChange: (selectedRowKeys) => setRowKeys(selectedRowKeys),
          }}
        />
        <Footer>
          <Button
            type='danger'
            size='large'
            onClick={showDeleteDialog}
            disabled={!rowKeys.length}
          >
            <Icon type='delete' theme='filled' />
          </Button>
          <Button type='danger' size='large' onClick={() => setRowKeys([])}>
            {unselectButtonMessage}
            <Icon type='reload' />
          </Button>
        </Footer>
      </div>
    </div>
  );
};

export default UsersPage;
