import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getLender,
  getAllLenders,
  getLenders,
  addNewLender,
  deleteLenders,
  updateLender,
  addLenderLogo,
  deleteLenderLogo,
  importLenders,
  importLendersData,
  importLendersContacts,
} from 'actions/lenders';
import {
  getLenderApi,
  getLendersApi,
  postNewLenderApi,
  deleteLendersApi,
  updateLenderApi,
  postImportLendersApi,
  postImportLendersDataApi,
  postImportLendersContactsApi,
} from 'services/lenders';
import { addUploadApi, deleteUploadApi } from 'services/uploads';
import fetchEntity from './fetch-entity';

const fetchLenders = fetchEntity.bind(null, getLenders.actions, getLendersApi);
const fetchAllLenders = fetchEntity.bind(
  null,
  getAllLenders.actions,
  getLendersApi,
);
const fetchAddNewLender = fetchEntity.bind(
  null,
  addNewLender.actions,
  postNewLenderApi,
);

const fetchUpdateLender = fetchEntity.bind(
  null,
  updateLender.actions,
  updateLenderApi,
);

const fetchDeleteLenders = fetchEntity.bind(
  null,
  deleteLenders.actions,
  deleteLendersApi,
);

const fetchAddLenderLogo = fetchEntity.bind(
  null,
  addLenderLogo.actions,
  addUploadApi,
);

const fetchDeleteLenderLogo = fetchEntity.bind(
  null,
  deleteLenderLogo.actions,
  deleteUploadApi,
);

const fetchImportLenders = fetchEntity.bind(
  null,
  importLenders.actions,
  postImportLendersApi,
);

const fetchImportLendersData = fetchEntity.bind(
  null,
  importLendersData.actions,
  postImportLendersDataApi,
);

const fetchImportLendersContacts = fetchEntity.bind(
  null,
  importLendersContacts.actions,
  postImportLendersContactsApi,
);
const fetchLender = fetchEntity.bind(null, getLender.actions, getLenderApi);

export function* loadGetLender({ params }) {
  // yield put(getLender.actions.failure({}, undefined));
  yield call(fetchLender, params);
}

function* watchGetLender() {
  yield takeLatest(getLender.actionName, loadGetLender);
}

export function* loadUpdateLenders({ params }) {
  yield call(fetchUpdateLender, { ...params });
}

function* watchUpdateLenders() {
  yield takeLatest(updateLender.actionName, loadUpdateLenders);
}

export function* loadGetLenders({ params }) {
  yield call(fetchLenders, { ...params, '$sort[updatedAt]': -1 });
}

function* watchGetLenders() {
  yield takeLatest([getLenders.actionName], loadGetLenders);
}

export function* loadGetAllLenders({ params }) {
  yield call(fetchAllLenders, {
    ...params,
    $limit: 1000,
    '$sort[updatedAt]': -1,
  });
}

function* watchGetAllLenders() {
  yield takeLatest([getAllLenders.actionName], loadGetAllLenders);
}

export function* loadGetLendersOnChange({ params }) {
  const lenders = yield select((state) => state.lenders);
  const { total, skip, limit } = lenders;
  if (skip && skip >= total) {
    yield call(fetchLenders, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchLenders, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetLendersOnChange() {
  yield takeLatest(
    [deleteLenders.requestTypes.SUCCESS],
    loadGetLendersOnChange,
  );
}

export function* loadAddNewLender({ params }) {
  yield call(fetchAddNewLender, { ...params });
}

function* watchAddNewLender() {
  yield takeLatest(addNewLender.actionName, loadAddNewLender);
}

export function* loadDeleteLenders({ params }) {
  yield call(fetchDeleteLenders, { ...params });
}

function* watchDeleteLenders() {
  yield takeLatest(deleteLenders.actionName, loadDeleteLenders);
}

export function* loadAddLenderLogo({ params }) {
  yield call(fetchAddLenderLogo, { ...params });
}

function* watchAddLenderLogo() {
  yield takeLatest(addLenderLogo.actionName, loadAddLenderLogo);
}

export function* loadDeleteLenderLogo({ params }) {
  yield call(fetchDeleteLenderLogo, { ...params });
}

function* watchDeleteLenderLogo() {
  yield takeLatest(deleteLenderLogo.actionName, loadDeleteLenderLogo);
}

export function* loadImportLenders({ params }) {
  yield call(fetchImportLenders, { ...params });
}

function* watchImportLenders() {
  yield takeLatest(importLenders.actionName, loadImportLenders);
}

export function* loadImportLendersData({ params }) {
  yield call(fetchImportLendersData, { ...params });
}

function* watchImportLendersData() {
  yield takeLatest(importLendersData.actionName, loadImportLendersData);
}

export function* loadImportLendersContacts({ params }) {
  yield call(fetchImportLendersContacts, { ...params });
}

function* watchImportLendersContacts() {
  yield takeLatest(importLendersContacts.actionName, loadImportLendersContacts);
}

export default {
  watchGetLender,
  watchGetLenders,
  watchAddNewLender,
  watchDeleteLenders,
  watchUpdateLenders,
  watchAddLenderLogo,
  watchDeleteLenderLogo,
  watchGetLendersOnChange,
  watchImportLenders,
  watchImportLendersContacts,
  watchGetAllLenders,
  watchImportLendersData,
};
