import React, { useState } from 'react';
import styled from 'styled-components';
import uniqid from 'uniqid';
import {
  Button,
} from 'antd';
import GridModal from './gridModal';

const ButtonWrapper = styled.div`
  marginTop: 20px;
  text-align: center;
`;
export default React.memo(({
  updateForm,
  formData,
  ...otherProps
}) => {
  const [gridPopupVisibility, setGridPopupVisibility] = useState(false);
  return (
    <ButtonWrapper>
      <Button
        type={'primary'}
        shape='circle'
        onClick={() => setGridPopupVisibility(true)}
        {...otherProps}>
          +
      </Button>
      <GridModal
        visible={gridPopupVisibility}
        onGridModalOk={(grid) => {
          updateForm({
            ...formData,
            fields: [
              ...formData.fields,
              {
                id: uniqid(),
                order: formData.fields.length + 1,
                sectionName: '',
                child: [...Array(grid.columns).keys()].map((res) => ({
                  id: uniqid(),
                  order: res + 1,
                  col: grid.size,
                  fields: [],
                })),
              },
            ],
          });
          setGridPopupVisibility(false);
        }}
        onCancel={() => setGridPopupVisibility(false)}
      />
    </ButtonWrapper>
  );
});
