import api from './api';

export const postNewConsultantApi = (data) => api().post('/consultants', data);
export const getConsultantsApi = (params) => api().get('/consultants', { params });
export const deleteConsultantsApi = (params) => api().delete('/consultants', { params });
export const updateConsultantApi = (params) => api().patch(`/consultants/${params.id}`, params.values);

export const postImportConsultantsApi = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => (
    key === 'file' && Array.isArray(data[key])
      ? data[key].forEach((file) => formData.append(key, file))
      : formData.append(key, data[key])
  ));
  return api({ 'Content-type': 'application/x-www-form-urlencoded' }).post('/consultants-import', formData);
};

export const postImportConsultantsContactsApi = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => (
    key === 'file' && Array.isArray(data[key])
      ? data[key].forEach((file) => formData.append(key, file))
      : formData.append(key, data[key])
  ));
  return api({ 'Content-type': 'application/x-www-form-urlencoded' }).post('/consultants-contacts-import', formData);
};
