import { getConsultantContacts, getPageConsultantContacts } from 'actions/consultantContacts';

const consultantContacts = () => {
  const initialState = {};

  return (state = initialState, { type, response, ids }) => {
    switch (type) {
      case getConsultantContacts.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getPageConsultantContacts.requestTypes.SUCCESS:
        return {
          ...response,
        };
      default:
        return state;
    }
  };
};

export default consultantContacts();
