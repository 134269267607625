import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  Tabs,
} from 'antd';
import styled from 'styled-components';
import { RichTextEditor } from 'components';
import slugify from 'slugify';
import { fieldTypes } from 'constants/formBuilder';
import SettingsTab from './SettingsTab';
import ValidationTab from './ValidationTab';

const StyledRichTextEditor = styled(RichTextEditor)`
  line-height: 20px;
  height: 150px;
  .ql-container { 
    height: 85%
  }
`;

const { Option } = Select;
const { TabPane } = Tabs;
const defaultFieldsSettings = {
  [fieldTypes.selectBox]: {
    datasetType: 'custom',
    datasets: undefined,
    allowed_rich_text: false,
  },
  [fieldTypes.number]: {
    money_format: false,
    allow_decimals: false,
    prefix: null,
    postfix: null,
  },
  [fieldTypes.textarea]: {
    is_rich_editor: false,
  },
};
const fieldsWithSettings = Object.keys(defaultFieldsSettings);

const FieldModal = React.memo(({
  visible, onAdd, onCancel, fieldSettings, busySlugs, form: {
    validateFields,
    getFieldDecorator,
    resetFields,
    setFieldsValue,
  },
}) => {
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [modalData, setModalData] = useState(fieldSettings || {
    settings: {},
    validations: {},
  });

  useEffect(() => {
    if (fieldSettings) {
      setModalData(fieldSettings);
    }
  }, [fieldSettings]);

  return (<Modal
    title='Field Settings'
    visible={visible}
    width={'80%'}
    onOk={() => {
      validateFields((err, values) => {
        if (!err) {
          if (modalData.type === 'selectBox' && !modalData.settings.datasets) {
            setActiveTabKey('2');
            validateFields(['datasets']);
            return;
          }
          onAdd(modalData);
          onCancel();
          resetFields();
          setActiveTabKey('1');
          setModalData({
            validations: {},
          });
          return;
        }
        if (err.datasets) {
          setActiveTabKey('2');
          validateFields(['datasets']);
        }
      });
    }}
    okText={!modalData.id ? 'Add' : 'Update'}
    onCancel={() => {
      onCancel();
      resetFields();
      setActiveTabKey('1');
      setModalData({
        validations: {},
      });
    }}
  >
    <Form>
      <Tabs
        tabPosition={'left'}
        activeKey={activeTabKey}
        onChange={(key) => setActiveTabKey(key)}
      >
        <TabPane tab='Field' key='1'>
          <Form.Item label={'Label'}>
            {getFieldDecorator('label', {
              initialValue: modalData.label,
              rules: [{
                required: true,
                message: 'Please input Label name',
              }],
            })(
              <Input
                onChange={(e) => {
                  setModalData({
                    ...modalData,
                    label: e.target.value,
                    slug: slugify(e.target.value, '_'),
                  });
                  setFieldsValue({ slug: slugify(e.target.value, '_') });
                }}
              />,
            )}
          </Form.Item>
          <Form.Item
            label={'Slug'}
          >
            {getFieldDecorator('slug', {
              initialValue: modalData.slug,
              rules: [
                { required: true, message: 'Please input slug name' },
                {
                  validator: (rule, value, callback) => {
                    if (value && busySlugs.includes(value) && !modalData.id) {
                      callback('This slug is already in use. Slugs must be unique!');
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(
              <Input
                onChange={(e) => {
                  setModalData({
                    ...modalData,
                    slug: e.target.value,
                  });
                }}
              />,
            )}
          </Form.Item>
          <Form.Item
            label={'Field Type'}
          >
            {getFieldDecorator('type', {
              initialValue: modalData.type,
              rules: [{ required: true, message: 'Please select field type' }],
            })(
              <Select
                placeholder={'Select Field Type'}
                onChange={(value) => setModalData({
                  ...modalData,
                  type: value,
                  settings: defaultFieldsSettings[value],
                  validations: {
                    required: false,
                    min_length: undefined,
                    max_length: undefined,
                    multi_upload: undefined,
                    max_upload_size: undefined,
                    allowed_file_type: undefined,
                  },
                })}
                style={{ width: '100%' }} >
                {Object.keys(fieldTypes).map((res) => <Option
                  key={`op-${res}`}
                  value={res}>{fieldTypes[res]}</Option>)}
              </Select>,
            )}
          </Form.Item>
          <Form.Item
            label={'Hint'}
          >
            {getFieldDecorator('hint', {
              initialValue: modalData.hint,
            })(
              <Input
                onChange={(e) => {
                  setModalData({
                    ...modalData,
                    hint: e.target.value,
                  });
                }}
              />,
            )}
          </Form.Item>
          <Form.Item label={'Description'}>
            <StyledRichTextEditor
              defaultValue={modalData.description || ''}
              onChange={(value) => {
                setModalData({
                  ...modalData,
                  description: value,
                });
              }} />
          </Form.Item>
        </TabPane>
        <TabPane tab='Settings' disabled={!fieldsWithSettings.includes(modalData.type)} key='2'>
          {fieldsWithSettings.includes(modalData.type)
            && <SettingsTab
              type={modalData.type}
              modalData={modalData}
              setModalData={setModalData}
              getFieldDecorator={getFieldDecorator}
              setFieldsValue={setFieldsValue}
            />
          }
        </TabPane>
        <TabPane tab='Validation' disabled={!modalData.type} key='3'>
          <ValidationTab
            type={modalData.type}
            modalData={modalData}
            setModalData={setModalData}
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
          />
        </TabPane>
      </Tabs>
    </Form>
  </Modal>);
});

export default Form.create({ name: 'field_settings' })(FieldModal);
