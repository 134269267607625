import React from 'react';
import { Button, Icon } from 'antd';
import { Link } from 'react-router-dom';

import { Table, Title } from 'components';

import { messages } from 'messages';
import routes from 'constants/routes';

const {
  manageAssetsTitleMessage, assetTypeColumnName, notesColumnName, manageAssetButtonMessage,
} = messages.manageAssets;


const columns = [
  {
    title: assetTypeColumnName,
    dataIndex: 'assetType',
    key: 'assetType',
    width: 200,
  },
  {
    title: notesColumnName,
    dataIndex: 'notes',
    key: 'notes',
  },
  {
    title: '',
    dataIndex: 'edit',
    key: 'edit',
    width: 100,
    render: (text, record) => record.hovered
      && <Link
        to={routes.settings.manageAssets[record.key]}
      >
        <Button type='default' size='small'>
          <Icon type='unordered-list' />
          {manageAssetButtonMessage}
        </Button>
      </Link>,
  },
];

const dataSource = [
  {
    key: 'propertyClasses',
    assetType: 'Property Classes',
    notes: 'Some Notes',
  },
  {
    key: 'markets',
    assetType: 'Markets',
    notes: 'Some Notes',
  },
  {
    key: 'stateProvinces',
    assetType: 'States and Provinces',
    notes: 'Some Notes',
  },
  {
    key: 'propertyTypes',
    assetType: 'Property Types',
    notes: 'Some Notes',
  },
  {
    key: 'loanTypes',
    assetType: 'Loan Types',
    notes: 'Some Notes',
  },
  {
    key: 'categories',
    assetType: 'Lender Categories',
    notes: 'Some Notes',
  },
  {
    key: 'consultantCategories',
    assetType: 'Consultant Categories',
    notes: 'Some Notes',
  },
];

const ManageAssets = () => (
  <div>
    <Title level={4}>
      {manageAssetsTitleMessage}
    </Title>
    <Table columns={columns} dataSource={dataSource} />
  </div>
);

export default ManageAssets;
