import { getPropertyClasses, deletePropertyClasses, getPagePropertyClasses } from 'actions/propertyClasses';

const propertyClasses = () => {
  const initialState = {};

  return (state = initialState, { type, response, ids }) => {
    switch (type) {
      case getPropertyClasses.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getPagePropertyClasses.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case deletePropertyClasses.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      default:
        return state;
    }
  };
};

export default propertyClasses();
