import { createSelector } from 'reselect';
import {
  getConsultants as getConsultantsAction,
  deleteConsultants as deleteConsultantsAction,
  addNewConsultant,
  updateConsultant,
  importConsultants,
  importConsultantsContacts,
} from 'actions/consultants';
import { getCategories } from 'actions/categories';
import { getStateProvinces } from 'actions/stateProvinces';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getConsultantsState = (state) => state.consultants;
export const getErrorsState = (state) => state.error;
export const getUploadsState = (state) => state.uploads;

export const getSuccessState = createSelector(
  getConsultantsState,
  (state) => state.isSuccessful,
);

export const getConsultants = createSelector(
  getConsultantsState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const getImportConsultantaInfo = createSelector(
  getConsultantsState,
  (state) => state.importConsultantsInfo,
);

export const getImportConsultantsContactsInfo = createSelector(
  getConsultantsState,
  (state) => state.importConsultantsContactsInfo,
);

export const importConsultantsLoading = createLoadingSelector(
  importConsultants.actionName,
)();
export const importConsultantsError = createErrorSelector(importConsultants.actionName)();

export const importConsultantsContactsLoading = createLoadingSelector(
  importConsultantsContacts.actionName,
)();
export const importConsultantsContactsError = createErrorSelector(importConsultantsContacts.actionName)();

export const updateConsultantLoading = createLoadingSelector(
  updateConsultant.actionName,
)();
export const updateConsultantError = createErrorSelector(updateConsultant.actionName)();

export const newConsultantLoading = createLoadingSelector(
  addNewConsultant.actionName,
)();
export const newConsultantError = createErrorSelector(addNewConsultant.actionName)();
export const getLoading = createLoadingSelector(getConsultantsAction.actionName)();
export const getError = createErrorSelector(getConsultantsAction.actionName)();

export const deleteLoading = createLoadingSelector(
  deleteConsultantsAction.actionName,
)();
export const deleteError = createErrorSelector(
  deleteConsultantsAction.actionName,
)();

export const getConsultantsFormErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const consultantsFormErrorsArray = [
      getCategories.actionName,
      getStateProvinces.actionName,
      addNewConsultant.actionName,
      updateConsultant.actionName,
    ];

    consultantsFormErrorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);
