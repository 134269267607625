import { makeRequestAction } from './index';


export const userLogin = makeRequestAction('USER_LOGIN', {
  onSuccess(params, response) {
    return {
      loggedInUser: response,
    };
  },
});

export const userLogout = makeRequestAction('USER_LOGOUT');
