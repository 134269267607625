import { createSelector } from 'reselect';
import {
  getServices as getServicesAction,
  deleteServices as deleteServicesAction,
  updateService,
  addServiceUploads,
  deleteServiceUploads,
} from 'actions/services';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';
import getUploadsState from './uploads';

export const getServicesState = (state) => state.services;
export const getErrorsState = (state) => state.error;

export const getSuccessState = createSelector(
  getServicesState,
  (state) => state.isSuccessful,
);

export const getServices = createSelector(
  getServicesState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const updateServiceLoading = createLoadingSelector(
  updateService.actionName,
)();
export const updateServiceError = createErrorSelector(updateService.actionName)();

export const getLoading = createLoadingSelector(getServicesAction.actionName)();
export const getError = createErrorSelector(getServicesAction.actionName)();

export const deleteLoading = createLoadingSelector(
  deleteServicesAction.actionName,
)();
export const deleteError = createErrorSelector(
  deleteServicesAction.actionName,
)();

export const getServicesAllErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      deleteServicesAction.actionName,
      updateService.actionName,
      getServices.actionName,
      addServiceUploads.actionName,
      deleteServiceUploads.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);


export const getServiceUploads = createSelector(getUploadsState, (uploads) => uploads[addServiceUploads.actionName]);

export const addServiceUploadsLoading = createLoadingSelector(addServiceUploads.actionName)();
export const deleteServiceUploadsLoading = createLoadingSelector(deleteServiceUploads.actionName)();
