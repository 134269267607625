import { createSelector } from 'reselect';
import {
  getLoanTypes as fetchLoanTypes, getPageLoanTypes, addNewLoanTypes, deleteLoanTypes, updateLoanTypes,
} from 'actions/loanTypes';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getLoanTypesState = (state) => state.loanTypes;
export const getErrorsState = (state) => state.error;

export const getLoanTypes = createSelector(
  getLoanTypesState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const loanTypesLoading = createLoadingSelector(fetchLoanTypes.actionName)();
export const loanTypesError = createErrorSelector(fetchLoanTypes.actionName)();


export const pageLoanTypesLoading = createLoadingSelector(getPageLoanTypes.actionName)();
export const pageLoanTypesError = createErrorSelector(getPageLoanTypes.actionName)();

export const addNewLoanTypesLoading = createLoadingSelector(addNewLoanTypes.actionName)();
export const addNewLoanTypesError = createErrorSelector(addNewLoanTypes.actionName)();

export const deleteLoanTypesLoading = createLoadingSelector(deleteLoanTypes.actionName)();
export const deleteLoanTypesError = createErrorSelector(deleteLoanTypes.actionName)();

export const getLoanTypesAllErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      getLoanTypes.actionName,
      addNewLoanTypes.actionName,
      deleteLoanTypes.actionName,
      updateLoanTypes.actionName,
      getPageLoanTypes.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);
