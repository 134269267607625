import { messages } from 'messages';

const {
  consultantSelectMessages,
  createdByInputMessages,
  featuredSwitchMessages,
  approvedSwitchMessages,
  emailInputMessages,
  firstNameInputMessages,
  lastNameInputMessages,
  titleInputMessages,
  phoneInputMessages,
  linkedInUrlInputMessages,
  notesInputMessages,
  countrySelectMessages,
  provinceSelectMessages,
  cityInputMessages,
  faxInputMessages,
  mobileInputMessages,
} = messages.landersCrudMessages.contactsMessages.form;

const contactFormConfig = {
  consultantSelect: {
    label: consultantSelectMessages.label,
    fieldName: 'companyId',
    decoratorOptions: {
      rules: [{ required: true }],
    },
    elementProps: {},
  },
  createdByInput: {
    label: createdByInputMessages.label,
    fieldName: 'createdBy',
    decoratorOptions: {},
    elementProps: { disabled: true },
  },
  featuredSwitch: {
    label: featuredSwitchMessages.label,
    fieldName: 'featured',
    decoratorOptions: {
      rules: [],
      initialValue: false,
      valuePropName: 'checked',
    },
    elementProps: {},
  },
  approvedSwitch: {
    label: approvedSwitchMessages.label,
    fieldName: 'approved',
    decoratorOptions: {
      rules: [],
      initialValue: false,
      valuePropName: 'checked',
    },
    elementProps: {},
  },
  emailInput: {
    label: emailInputMessages.label,
    fieldName: 'email',
    decoratorOptions: {
      rules: [
        { type: 'email', message: emailInputMessages.invalid },
        { required: true, message: emailInputMessages.required },
      ],
    },
    elementProps: {},
  },
  firstNameInput: {
    label: firstNameInputMessages.label,
    fieldName: 'firstName',
    decoratorOptions: {
      rules: [
        { required: true, message: firstNameInputMessages.required },
      ],
    },
    elementProps: {},
  },
  lastNameInput: {
    label: lastNameInputMessages.label,
    fieldName: 'lastName',
    decoratorOptions: {
      rules: [
        { required: true, message: lastNameInputMessages.required },
      ],
    },
    elementProps: {},
  },
  titleInput: {
    label: titleInputMessages.label,
    fieldName: 'title',
    decoratorOptions: {
      rules: [
        { required: true, message: titleInputMessages.required },
      ],
    },
    elementProps: {},
  },
  countrySelect: {
    label: countrySelectMessages.label,
    fieldName: 'country',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: countrySelectMessages.required,
        },
      ],
    },
    elementProps: { disabled: true },
    options: [
      { id: 'ca', name: 'Canada' },
      { id: 'us', name: 'USA' },
    ],
  },
  provinceSelect: {
    label: provinceSelectMessages.label,
    fieldName: 'provinceId',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: provinceSelectMessages.required,
        },
      ],
    },
    elementProps: {},
    options: [],
  },
  cityInput: {
    label: cityInputMessages.label,
    fieldName: 'city',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  faxInput: {
    label: faxInputMessages.label,
    fieldName: 'fax',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  mobileInput: {
    label: mobileInputMessages.label,
    fieldName: 'mobile',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {},
  },
  phoneInput: {
    label: phoneInputMessages.label,
    fieldName: 'phone',
    decoratorOptions: {
      rules: [
        { required: true, message: phoneInputMessages.required },
      ],
    },
    elementProps: {},
  },
  linkedInUrlInput: {
    label: linkedInUrlInputMessages.label,
    fieldName: 'linkedInUrl',
    decoratorOptions: {
      rules: [
        { type: 'url', message: linkedInUrlInputMessages.invalid },
      ],
    },
    elementProps: {},
  },
  notesInput: {
    label: notesInputMessages.label,
    fieldName: 'notes',
    decoratorOptions: {},
    elementProps: {},
  },
};

export default contactFormConfig;
