import { call, takeLatest } from 'redux-saga/effects';
import { getRoles } from 'actions/roles';
import { getRolesApi } from 'services/roles';
import fetchEntity from './fetch-entity';

const fetchRoles = fetchEntity.bind(
  null,
  getRoles.actions,
  getRolesApi,
);

export function* loadGetRoles({ params }) {
  yield call(fetchRoles, { ...params });
}

function* watchGetRoles() {
  yield takeLatest(getRoles.actionName, loadGetRoles);
}

export default {
  watchGetRoles,
};
