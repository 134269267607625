import { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

export const useDidUpdateEffect = (fn, deps) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) { fn(); } else { didMountRef.current = true; }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};


export const useReduxAction = (action, data = {}, dependencies = [], condition = true, cleanUp = () => { }) => {
  useEffect(() => {
    if (condition) {
      action(data);
    }
    return cleanUp;
  }, [action, cleanUp, condition, data]);
};

export const useMergedState = (initialState) => {
  const [state, setState] = useState(initialState);
  return [state, (nextState) => setState((prevState) => ({ ...prevState, ...nextState }))];
};

export const useWithInitialState = (initialState) => {
  const [state, setState] = useState(initialState);
  return [state, (nextState) => setState(() => ({ ...initialState, ...nextState }))];
};

export const useUpdater = (value, initialState = '') => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    setState(value);
  }, [value, setState]);
  return [state, setState];
};

export const useRedux = () => {
  const dispatch = useDispatch;
  const select = useSelector;
  return [dispatch, select];
};

export const useReduxDispatch = (
  {
    action,
    selector,
    actionData = {},
    dependencies = [],
    condition = true,
    cleanUp = () => {},
    loadingSelector = () => ({}),
    errorSelector = () => ({}),
  },
) => {
  const dispatch = useDispatch();
  const data = useSelector(selector);
  useEffect(() => {
    if (condition) {
      dispatch(action(actionData));
    }
    return cleanUp;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, action, dispatch]);
  const isLoading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);
  return [data, isLoading, error];
};
