import { makeRequestAction } from './index';

/**
 * Get Forms
 */
export const getForms = makeRequestAction('GET_FORMS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

/**
 * Get Form
 */
export const getForm = makeRequestAction('GET_FORM', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

/**
 * Add Form
 */
export const addForm = makeRequestAction('ADD_FORM', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

/**
 * Update Form
 */
export const updateForm = makeRequestAction('UPDATE_FORM', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});


export const deleteForm = makeRequestAction('DELETE_FORM', {
  onSuccess(params, response) {
    return {
      // eslint-disable-next-line no-underscore-dangle
      response: { ids: [response._id] },
    };
  },
});
