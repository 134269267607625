import api from './api';

export const getFormsApi = (params) => api().get('/form-builder', { params });

export const getFormApi = ({ id, ...params }) => api().get(`/form-builder/${id}`, { params });

export const createFormApi = (data) => api().post('/form-builder', data);

export const updateFormApi = ({ _id, ...data }) => api().patch(`/form-builder/${_id}`, { ...data });

export const deleteFormApi = ({ _id, ...params }) => api().delete(`/form-builder/${_id}`, { params });
