import { createSelector } from 'reselect';
import {
  getUsers as fetchUsers, getPageUsers, addNewUsers, deleteUsers, updateUsers,
} from 'actions/users';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getUsersState = (state) => state.users;
export const getErrorsState = (state) => state.error;

export const getUsers = createSelector(
  getUsersState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const getSuccessState = createSelector(
  getUsersState,
  (state) => state.isSuccessful,
);

export const getAuthState = (state) => state.auth;
export const getCurrentUserId = createSelector(
  getAuthState,
  (state) => state.loggedInUser.data.id,
);

export const usersLoading = createLoadingSelector(fetchUsers.actionName)();
export const usersError = createErrorSelector(fetchUsers.actionName)();


export const pageUsersLoading = createLoadingSelector(getPageUsers.actionName)();
export const pageUsersError = createErrorSelector(getPageUsers.actionName)();

export const addNewUsersLoading = createLoadingSelector(addNewUsers.actionName)();
export const addNewUsersError = createErrorSelector(addNewUsers.actionName)();

export const deleteUsersLoading = createLoadingSelector(deleteUsers.actionName)();
export const deleteUsersError = createErrorSelector(deleteUsers.actionName)();

export const getUsersFormErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      addNewUsers.actionName,
      deleteUsers.actionName,
      updateUsers.actionName,
      getPageUsers.actionName,
      getUsers.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);
