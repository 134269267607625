import routes from 'constants/routes';

const menuRoutes = [
  {
    title: routes.userCompanies.title,
    paths: [routes.userCompanies.table.pathname],
    icon: 'money-collect',
  },
  {
    title: routes.users.title,
    paths: [routes.users.table.pathname, routes.users.form.pathname],
    icon: 'idcard',
  },
  {
    title: routes.lenders.title,
    paths: [routes.lenders.table.pathname, routes.lenders.form.pathname],
    icon: 'bank',
  },
  {
    title: routes.consultants.title,
    paths: [routes.consultants.table.pathname, routes.consultants.form.pathname],
    icon: 'tags',
  },
  {
    title: routes.formBuilder.title,
    paths: [routes.formBuilder.pathname, '/form-builder'],
    icon: 'project',
  },
  {
    title: routes.services.title,
    paths: [routes.services.pathname],
    icon: 'container',
    children: [
      {
        title: routes.services.drone.title,
        paths: [routes.services.drone.table.pathname, routes.services.drone.form.pathname],
        icon: '',
      },
      {
        title: routes.services.lease.title,
        paths: [routes.services.lease.table.pathname, routes.services.lease.form.pathname],
        icon: '',
      },
      {
        title: routes.services.inspection.title,
        paths: [routes.services.inspection.table.pathname, routes.services.inspection.form.pathname],
        icon: '',
      },
    ],
  },
  {
    title: routes.roleManagement.title,
    paths: [routes.roleManagement.pathname],
    icon: 'unlock',
  },
  {
    title: routes.contentImporter.title,
    paths: [routes.contentImporter.pathname],
    icon: 'appstore',
  },
  {
    title: routes.settings.title,
    paths: [routes.settings.pathname],
    icon: 'setting',
    children: [
      {
        title: routes.settings.emailTemplates.title,
        paths: [routes.settings.emailTemplates.table.pathname, routes.settings.emailTemplates.form.pathname],
        icon: 'mail',
      },
      {
        title: routes.settings.manageAssets.title,
        paths: [routes.settings.manageAssets.pathname],
        icon: '',
        children: [
          {
            title: routes.settings.manageAssets.propertyClasses.title,
            paths: [routes.settings.manageAssets.propertyClasses.pathname],
            icon: '',
          },
          {
            title: routes.settings.manageAssets.markets.title,
            paths: [routes.settings.manageAssets.markets.pathname],
            icon: '',
          },
          {
            title: routes.settings.manageAssets.stateProvinces.title,
            paths: [routes.settings.manageAssets.stateProvinces.pathname],
            icon: '',
          },
          {
            title: routes.settings.manageAssets.propertyTypes.title,
            paths: [routes.settings.manageAssets.propertyTypes.pathname],
            icon: '',
          },
          {
            title: routes.settings.manageAssets.loanTypes.title,
            paths: [routes.settings.manageAssets.loanTypes.pathname],
            icon: '',
          },
          {
            title: routes.settings.manageAssets.categories.title,
            paths: [routes.settings.manageAssets.categories.pathname],
            icon: '',
          },
          {
            title: routes.settings.manageAssets.consultantCategories.title,
            paths: [routes.settings.manageAssets.consultantCategories.pathname],
            icon: '',
          },
        ],
      },
    ],
  },
];

export default menuRoutes;
