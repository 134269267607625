import React, {
  useEffect, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Icon, Upload, message, Modal, notification,
} from 'antd';

import {
  Button,
  Table,
  Title,
  Input,
} from 'components';

import {
  getLoading as getLoadingSelector,
  deleteLoading as deleteLoadingSelector,
  getError as getErrorSelector,
  deleteError as deleteErrorSelector,
  getLenders,
  importLendersLoading,
  importLendersDataLoading,
  getImportLendersDataInfo,
  importLendersContactsLoading,
} from 'selectors/lenders';
import {
  getLenders as getLendersAction,
  deleteLenders as deleteLendersAction,
  importLenders as importLendersAction,
  importLendersData as importLendersDataAction,
  importLendersContacts as importLendersContactsAction,
} from 'actions/lenders';

import routes from 'constants/routes';
import { messages } from 'messages';

const {
  lenderListTitleMessage,
  newLenderButtonMessage,
  unselectButtonMessage,
  lenderEditButtonMessage,
  companyNameColumnName,
  companyCountry,
  portfolioSizeColumnName,
  confirmDeleteMessage,
  notesColumnName,
  importLendersButtonMessage,
  importLendersDataButtonMessage,
  importLendersContactsButtonMessage,
} = messages.landersArchiveMessages;

const { confirm } = Modal;

const Footer = styled.div`
  margin-top: 10px;
  float: right;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const LendersPage = (props) => {
  const PAGE_SIZE = 10;
  const dispatch = useDispatch();

  const lenders = useSelector(getLenders);
  const getLoading = useSelector(getLoadingSelector);
  const deleteLoading = useSelector(deleteLoadingSelector);
  const getError = useSelector(getErrorSelector);
  const deleteError = useSelector(deleteErrorSelector);
  const getImportLendersLoading = useSelector(importLendersLoading);
  const importedLendersData = useSelector(getImportLendersDataInfo);
  const getImportLendersDataLoading = useSelector(importLendersDataLoading);
  const getImportLendersContactsLoading = useSelector(importLendersContactsLoading);

  const [searchName, setSearchName] = useState('');
  const [searchPage, setSearchPage] = useState(1);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [pageChanged, setPageChanged] = useState(false);

  useEffect(() => {
    if (importedLendersData) {
      const { importedLenders, notFoundLenders, emptySheet } = importedLendersData;
      if (importedLenders.length > 0) {
        notification.success({
          duration: 0,
          className: 'success',
          message: 'Success!',
          description: `Total lenders imported - ${importedLenders.length}`,
        });
      }

      if (notFoundLenders.length > 0) {
        notification.error({
          duration: 0,
          className: 'failed',
          message: 'Failed!',
          description: `Sheets with no lender matches - ${notFoundLenders.map((item) => item.sheetName).join(', ')},
          `,
        });
      }

      if (emptySheet.length > 0) {
        notification.info({
          duration: 0,
          className: 'empty',
          message: 'Empty!',
          description: `Empty sheets - ${emptySheet.map((item) => item.sheetName).join(', ')}`,
        });
      }
    }
  }, [importedLendersData]);

  const handleSearch = (name) => {
    setSearchName(name);
    dispatch(
      getLendersAction.request({
        'companyName[$iLike]': `%${name}%`,
        $skip: (searchPage - 1) * PAGE_SIZE,
      }),
    );
  };

  const handlePageChage = (current) => {
    setPageChanged(true);
    setSearchPage(current);
  };

  const columns = [
    {
      title: companyNameColumnName,
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: companyCountry,
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: portfolioSizeColumnName,
      dataIndex: 'portfolioSize',
      key: 'portfolioSize',
    },
    {
      title: notesColumnName,
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 100,
      render: (text, record) => record.hovered && (
      <Link
        to={{
          pathname: routes.lenders.form.pathname,
          state: { currentLender: record },
        }}
          >
        <Button type='default' size='small'>
          <Icon type='edit' theme='filled' />
          {lenderEditButtonMessage}
        </Button>
      </Link>
      ),
    },
  ];

  const [page, setPage] = useState(1);
  const [rowKeys, setRowKeys] = useState([]);

  useEffect(() => {
    dispatch(getLendersAction.request({ $skip: (page - 1) * PAGE_SIZE }));
  }, [dispatch, page]);

  useEffect(() => {
    if (pageChanged && searchName) {
      dispatch(
        getLendersAction.request({
          'companyName[$iLike]': `%${searchName}%`,
          $skip: (searchPage - 1) * PAGE_SIZE,
        }),
      );
    }
  }, [dispatch, searchPage, searchName, pageChanged]);

  useEffect(() => {
    getError && message.error(`Error ${getError.code}: ${getError.message}`);
    deleteError
      && message.error(`Error ${deleteError.code}: ${deleteError.message}`);
  }, [deleteError, getError]);

  const deleteLenders = useCallback(() => {
    dispatch(deleteLendersAction.request({ 'id[$in]': rowKeys }));
    setRowKeys([]);
  }, [dispatch, rowKeys]);

  const showDeleteDialog = () => {
    confirm({
      title: confirmDeleteMessage,
      okType: 'danger',
      onOk() {
        deleteLenders();
      },
    });
  };
  const importLenders = useCallback(async (file) => {
    if (file.type.includes('text/csv')) {
      dispatch(importLendersAction.request({ file, source: 'lenders' }));
    } else {
      message.error('File must be an CSV!');
    }
  }, [dispatch]);

  const importLendersContacts = useCallback(async (file) => {
    if (file.type.includes('text/csv')) {
      dispatch(importLendersContactsAction.request({ file, source: 'lenders-contacts' }));
    } else {
      message.error('File must be an CSV!');
    }
  }, [dispatch]);

  const importLendersData = useCallback(async (file) => (
    dispatch(importLendersDataAction.request({ file, source: 'lenders-data' }))
  ), [dispatch]);

  return (
    <div>
      <Upload
        accept='.csv'
        showUploadList={false}
        beforeUpload={(file) => {
          importLenders(file);
          return false;
        }}
      >
        <Button loading={getImportLendersLoading}>
          {!getImportLendersLoading && <Icon type='upload' />}{' '}
          {importLendersButtonMessage}
        </Button>
      </Upload>
      {' '}
      <Upload
        accept='.csv'
        showUploadList={false}
        beforeUpload={(file) => {
          importLendersContacts(file);
          return false;
        }}>
        <Button loading={getImportLendersContactsLoading}>
          {!getImportLendersContactsLoading && <Icon type='upload' />} {importLendersContactsButtonMessage}
        </Button>
      </Upload>
      {' '}
      <Upload
        accept='.xlsx'
        showUploadList={false}
        beforeUpload={(file) => {
          importLendersData(file);
          return false;
        }}>
        <Button loading={getImportLendersDataLoading}>
          {!getImportLendersDataLoading && <Icon type='upload' />}
          {importLendersDataButtonMessage}
        </Button>
      </Upload>
      <Title level={4} style={{ paddingTop: '30px' }}>
        {lenderListTitleMessage}
        <Link
          to={{
            pathname: routes.lenders.form.pathname,
            state: { currentLender: null },
          }}
        >
          <Button type='success' size='large'>
            {newLenderButtonMessage}
          </Button>
        </Link>
      </Title>
      <div style={{ padding: 8 }}>
        <Input
          placeholder={'Search by name'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys[0])}
          style={{ width: 300, marginBottom: 8, display: 'block' }}
      />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys[0])}
          icon='search'
          size='small'
          style={{ width: 192, marginRight: 8 }}>Search
        </Button>
        <Button onClick={() => handleSearch('')} size='small' style={{ width: 100 }}>Reset</Button>
      </div>
      <div>
        <Table
          loading={getLoading || deleteLoading}
          rowKey='id'
          pagination={{
            current: searchName !== '' ? searchPage : page,
            total: lenders.total,
            pageSize: PAGE_SIZE,
            onChange: (current) => (searchName !== '' ? handlePageChage(current) : setPage(current)),
          }}
          dataSource={lenders.data}
          columns={columns}
          rowSelection={{
            selectedRowKeys: rowKeys,
            onChange: (selectedRowKeys) => setRowKeys(selectedRowKeys),
          }}
        />
        <Footer>
          <Button
            type='danger'
            size='large'
            onClick={showDeleteDialog}
            disabled={!rowKeys.length}
          >
            <Icon type='delete' theme='filled' />
          </Button>
          <Button type='danger' size='large' onClick={() => setRowKeys([])}>
            {unselectButtonMessage}
            <Icon type='reload' />
          </Button>
        </Footer>
      </div>
    </div>
  );
};

export default LendersPage;
