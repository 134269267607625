import { combineReducers } from 'redux';
import entities from './entities';
import lenders from './lenders';
import loading from './loading';
import error from './error';
import markets from './markets';
import loanTypes from './loanTypes';
import propertyTypes from './propertyTypes';
import stateProvinces from './stateProvinces';
import propertyClasses from './propertyClasses';
import categories from './categories';
import auth from './auth';
import countries from './countries';
import uploads from './uploads';
import formBuilder from './formBuilder';
import users from './users';
import userCompanies from './userCompanies';
import contacts from './contacts';
import services from './services';
import emailTemplates from './emailTemplates';
import consultants from './consultants';
import consultantCategories from './consultantCategories';
import consultantContacts from './consultantContacts';
import roles from './roles';
import serviceRoles from './roleManagements';
import brokerListings from './brokerListings';

const appReducer = combineReducers({
  entities,
  uploads,
  lenders,
  loading,
  error,
  markets,
  loanTypes,
  propertyTypes,
  stateProvinces,
  propertyClasses,
  categories,
  auth,
  countries,
  formBuilder,
  users,
  userCompanies,
  contacts,
  services,
  emailTemplates,
  consultants,
  consultantCategories,
  consultantContacts,
  roles,
  serviceRoles,
  brokerListings,
});

export default (state, action) => appReducer(state, action);
