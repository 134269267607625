import React, { useState } from 'react';
import {
  Row,
  Col,
  Modal,
  Slider,
} from 'antd';
import { BuilderColumn } from 'components';

export default React.memo(({ visible, onGridModalOk, onCancel }) => {
  const colCounts = [1, 2, 3, 4, 6, 8, 12];
  const marks = { ...colCounts };
  const [inputValue, setInputValue] = useState(1);
  return (<Modal
    title='Grid System'
    visible={visible}
    onOk={() => onGridModalOk({
      columns: marks[inputValue],
      size: 24 / marks[inputValue],
    })}
    okText={'Add'}
    width={'60%'}
    onCancel={onCancel}
  >
    <Row>
      <Col span={24}>
        <Slider
          min={0}
          max={Object.keys(colCounts).length - 1}
          marks={marks}
          onChange={(value) => setInputValue(value)}
          value={inputValue}
        />
      </Col>
      <Col span={24}>
        <div>
          <Row gutter={16}>
            {[...Array(marks[inputValue]).keys()].map((res) => <Col
              span={24 / marks[inputValue]}
              key={`${res}_col`}
            >
              <BuilderColumn>Column</BuilderColumn>
            </Col>)}
          </Row>
        </div>
      </Col>
    </Row>
  </Modal>);
});
