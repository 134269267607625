import { messages } from 'messages';

const {
  propertyNameInputMessages,
  addressInputMessages,
  leasesNumberInputMessages,
  timingInputMessages,
  purposeTextAreaMessages,
  linkInputMessages,
  statusSelectMessages,
} = messages.services.form;

const formItemsConfig = {
  propertyNameInput: {
    label: propertyNameInputMessages.label,
    fieldName: 'propertyName',
    decoratorOptions: {},
    elementProps: {},
  },
  addressInput: {
    label: addressInputMessages.label,
    fieldName: 'address',
    decoratorOptions: {},
    elementProps: {},
  },
  leasesNumberInput: {
    label: leasesNumberInputMessages.label,
    fieldName: 'leasesNumber',
    decoratorOptions: {
      rules: [{ type: 'integer', min: 1, message: leasesNumberInputMessages.invalid }],
    },
    elementProps: { style: { width: '100%' } },
  },
  timingInput: {
    label: timingInputMessages.label,
    fieldName: 'timing',
    decoratorOptions: {
      rules: [{ type: 'integer', min: 1, message: timingInputMessages.invalid }],
    },
    elementProps: { style: { width: '100%' } },
  },
  statusSelect: {
    label: statusSelectMessages.label,
    fieldName: 'status',
    decoratorOptions: {
      rules: [{ type: 'enum', enum: ['in progress', 'pending', 'paid', 'completed'] }],
    },
    options: ['in progress', 'pending', 'paid', 'completed'],
    elementProps: { },
  },
  purposeTextArea: {
    label: purposeTextAreaMessages.label,
    fieldName: 'purpose',
    decoratorOptions: {},
    elementProps: { },
  },
  linkInput: {
    label: linkInputMessages.label,
    fieldName: 'url',
    decoratorOptions: {
      rules: [{ type: 'url', message: linkInputMessages.invalid }],
    },
    elementProps: { },
  },
};

export default formItemsConfig;
