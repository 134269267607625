import styled from 'styled-components';

const BuilderColumn = styled.div`
    height: 100px;
    background: #00a0e9;
    textAlign: center;
    color: #fff;
    lineHeight: 100px;
`;

/** @component */
export default BuilderColumn;
