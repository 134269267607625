import React, { useEffect, useCallback, useState } from 'react';
import { useDidUpdateEffect } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row, Col, Form, message, Modal,
} from 'antd';

import {
  Button,
  Input,
  InputNumber,
  Title,
  FormItem,
  FileUpload,
  Select,
} from 'components';

import {
  updateService,
  addServiceUploads,
  deleteServiceUploads,
} from 'actions/services';

import {
  updateServiceLoading,
  getSuccessState,
  getServiceUploads,
  addServiceUploadsLoading,
  deleteServiceUploadsLoading,
  getServicesAllErrors,
} from 'selectors/services';

import routes from 'constants/routes';
import { messages } from 'messages';
import formItemsConfig from './formItemsConfig';

const {
  submitSuccessMessage,
  editMessage,
  backToServicesMessage,
  attachmentsMessages,
} = messages.services.form;

const {
  propertyNameInput,
  addressInput,
  leasesNumberInput,
  timingInput,
  statusSelect,
  purposeTextArea,
  linkInput,
} = formItemsConfig;

const { confirm } = Modal;
const { Option } = Select;

const ServicesForm = (props) => {
  // file uploader options
  const FILE_SIZE = 3 * 1024 * 1024;
  const ALLOWED_EXTENSIONS = ['png', 'jpg', 'jpeg', 'xls', 'xlsx', 'pdf'];
  const MAX_AMOUNT = 3;

  const { form, history, match } = props;
  const { currentService } = props.location.state || {};
  const { getFieldDecorator } = form;
  const serviceName = match.params.type;

  const dispatch = useDispatch();
  const formErrors = useSelector(getServicesAllErrors);
  const isSubmitloading = useSelector(updateServiceLoading);
  const uploads = useSelector(getServiceUploads);
  const isAddUploadLoading = useSelector(addServiceUploadsLoading);
  const isDeleteUploadLoading = useSelector(deleteServiceUploadsLoading);
  const isSubmitSuccessful = useSelector(getSuccessState);

  const [fileList, setFileList] = useState(
    currentService && currentService.attachments
      ? currentService.attachments
      : [],
  );
  const [deletedFiles, setDeletedFiles] = useState([]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      message.success(submitSuccessMessage);
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    formErrors
      && formErrors.forEach((error) => message.error(`Error ${error.code} ${error.message}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formErrors)]);

  useDidUpdateEffect(() => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        submitForm(values);
      }
      return true;
    });
  }, [JSON.stringify(uploads)]);

  const getRequestValues = useCallback(
    (values) => {
      if (fileList.length) {
        const updatedFileList = [...fileList];
        updatedFileList
          .filter((file) => !file.url)
          .forEach((file) => {
            file.url = uploads.find((f) => f.name === file.name).url;
            file.uid = uploads.find((f) => f.name === file.name).uid;
          });
        setFileList(updatedFileList);
        setDeletedFiles([]);
      }

      const requestValues = {
        ...values,
        type: serviceName,
        userId: currentService.userId,
        attachments: fileList.length
          ? fileList.map((file) => ({
            name: file.name,
            url: file.url,
            uid: file.uid,
          }))
          : null,
      };

      return requestValues;
    },
    [currentService.userId, fileList, serviceName, uploads],
  );

  const submitForm = useCallback(
    (values) => {
      const modifyedValues = getRequestValues(values);
      dispatch(
        updateService.request({
          id: currentService.id,
          values: modifyedValues,
        }),
      );
    },
    [currentService, dispatch, getRequestValues],
  );

  const showConfirm = useCallback(
    (values) => {
      confirm({
        title: `${editMessage} ${serviceName}?`,
        confirmLoading: true,
        onOk() {
          const newFiles = fileList.filter((file) => !file.url);
          if (!(newFiles.length || deletedFiles.length)) {
            submitForm(values);
          } else if (currentService) {
            newFiles.length
              && dispatch(
                addServiceUploads.request({
                  file: newFiles,
                  source: serviceName,
                }),
              );
            deletedFiles.length
              && deletedFiles.forEach((file) => {
                dispatch(
                  deleteServiceUploads.request({
                    id: file.url.split('/').slice(-1)[0],
                  }),
                );
              });
          }
        },
        onCancel() {},
      });
    },
    [currentService, deletedFiles, dispatch, fileList, serviceName, submitForm],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          showConfirm(values);
        }
        return true;
      });
    },
    [form, showConfirm],
  );

  const changeHandler = (file, newFileList, changeType) => {
    setFileList(newFileList);
    if (!file.url && changeType === 'delete') {
      setDeletedFiles([...deletedFiles, file]);
    }
  };

  return (
    <>
      <Row gutter={40}>
        <Col span={16}>
          <Title level={4}>
            {`${editMessage} ${serviceName}`}
          </Title>
          <Form
            onSubmit={handleSubmit}
            layout={'vertical'}
            hideRequiredMark
          >
            <Row gutter={40}>
              <Col span={12}>
                <FormItem label={propertyNameInput.label}>
                  {getFieldDecorator(propertyNameInput.fieldName, {
                    ...propertyNameInput.decoratorOptions,
                    initialValue: currentService
                      ? currentService.propertyName
                      : null,
                  })(<Input {...propertyNameInput.elementProps} />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={addressInput.label}>
                  {getFieldDecorator(addressInput.fieldName, {
                    ...addressInput.decoratorOptions,
                    initialValue: currentService ? currentService.address : null,
                  })(<Input {...addressInput.elementProps} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={40}>
              {serviceName === 'lease' && (
                <Col span={12}>
                  <FormItem label={leasesNumberInput.label}>
                    {getFieldDecorator(leasesNumberInput.fieldName, {
                      ...leasesNumberInput.decoratorOptions,
                      initialValue: currentService
                        ? currentService.leasesNumber
                        : null,
                    })(<InputNumber {...leasesNumberInput.elementProps} />)}
                  </FormItem>
                </Col>
              )}
              <Col span={12}>
                <FormItem label={timingInput.label}>
                  {getFieldDecorator(timingInput.fieldName, {
                    ...timingInput.decoratorOptions,
                    initialValue: currentService ? currentService.timing : null,
                  })(<InputNumber {...timingInput.elementProps} />)}
                </FormItem>
              </Col>
            </Row>
            <FormItem label={statusSelect.label}>
              {getFieldDecorator(statusSelect.fieldName, {
                ...statusSelect.decoratorOptions,
                initialValue: currentService ? currentService.status : null,
              })(<Select {...statusSelect.elementProps}>
                {statusSelect.options.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>)}
            </FormItem>
            <FormItem label={linkInput.label}>
              {getFieldDecorator(linkInput.fieldName, {
                ...linkInput.decoratorOptions,
                initialValue: currentService ? currentService.url : null,
              })(<Input {...linkInput.elementProps} />)}
            </FormItem>
            <FormItem label={purposeTextArea.label}>
              {getFieldDecorator(purposeTextArea.fieldName, {
                ...purposeTextArea.decoratorOptions,
                initialValue: currentService ? currentService.purpose : null,
              })(<Input.TextArea {...purposeTextArea.elementProps} />)}
            </FormItem>
            <FormItem label={attachmentsMessages.label}>
              <FileUpload
                multiple
                extensions={ALLOWED_EXTENSIONS}
                maxAmount={MAX_AMOUNT}
                fileSize={FILE_SIZE}
                fileList={fileList}
                changeHandler={changeHandler}
              />
            </FormItem>
            <Row gutter={40}>
              <Col offset={12} span={12} style={{ textAlign: 'right' }}>
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={() => history.push(routes.services[serviceName].table.pathname)}>
                  {backToServicesMessage}
                </Button>
                <Button
                  style={{ marginLeft: 15 }}
                  type='success'
                  htmlType='submit'
                  loading={
                    isSubmitloading || isAddUploadLoading || isDeleteUploadLoading
                  }
                >
                  {`${editMessage} ${serviceName}`}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

ServicesForm.propTypes = {
  form: PropTypes.object,
  isSubmitloading: PropTypes.bool,
};

export default React.memo(Form.create({ name: 'Services_CRUD' })(ServicesForm));
