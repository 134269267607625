import { makeRequestAction } from './index';

export const getServiceRoles = makeRequestAction('GET_SERVICE_ROLES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addNewServiceRoles = makeRequestAction('ADD_NEW_SERVICE_ROLE');

export const deleteServiceRoles = makeRequestAction('DELETE_SERVICE_ROLE', {
  onSuccess(params, response) {
    return {
      ids: response.map((len) => len.id),
    };
  },
});

export const updateServiceRoles = makeRequestAction('UPDATE_SERVICE_ROLE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getPageServiceRoles = makeRequestAction('GET_PAGE_SERVICE_ROLES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
