import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { Button, Table, Title } from 'components';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon, Modal, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';


import {
  getPageServiceRoles, deleteServiceRoles, updateServiceRoles, addNewServiceRoles,
} from 'actions/roleManagements';
import { clearAllErrors } from 'actions/general';

import {
  getServiceRoles, pageServiceRolesLoading, getServiceRolesAllErrors, getSuccessState,
} from 'selectors/roleManagements';


import { messages } from 'messages';
import FormModal from './formModal';

const {
  titleMassage,
  addNewButtonMessage,
  deleteConfirmMessage,
  editButtonMessage,
  unselectButtonMassage,
  nameColumnMassage,
  submitSuccessMassage,
} = messages.roleManagementMessages;

const { confirm } = Modal;

const Footer = styled.div`
    margin-top: 10px;
    float: right;
  
    button {
      margin-left: 10px;
      margin-right: 10px;
    }
  `;

const ServiceRolesCompinent = (props) => {
  const PAGE_SIZE = 10;
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const getServiceRolesErrors = useSelector(getServiceRolesAllErrors);

  const serviceRoles = useSelector(getServiceRoles);
  const isServiceRolesLoading = useSelector(pageServiceRolesLoading);
  const isSubmitSuccessful = useSelector(getSuccessState);

  const [page, setPage] = useState(1);
  const [rowKeys, setRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rowDataState, setRowDataState] = useState(null);


  useEffect(() => {
    dispatch(getPageServiceRoles.request({ $skip: (page - 1) * PAGE_SIZE, '$sort[updatedAt]': -1 }));
  }, [dispatch, page]);

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful) {
      message.success(submitSuccessMassage);
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    getServiceRolesErrors && getServiceRolesErrors.forEach((err) => message.error(`Error ${err.code} ${err.message}`));
    dispatch(clearAllErrors.request());
  }, [dispatch, getServiceRolesErrors]);

  const columns = [
    {
      title: nameColumnMassage,
      dataIndex: 'service',
      key: 'service',
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 100,
      render: (text, record, index) => record.hovered
        && <Button type='default' size='small' onClick={() => onEditAssetHandler(record)}>
          <Icon type='edit' theme='filled' size='large' />
            {editButtonMessage}
          </Button>
      ,
    },
  ];

  const deleteAssets = useCallback(
    () => {
      dispatch(deleteServiceRoles.request({ 'id[$in]': rowKeys }));
      setRowKeys([]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowKeys],
  );

  const showDeleteDialog = useCallback(() => {
    confirm({
      title: deleteConfirmMessage,
      okType: 'danger',
      onOk() {
        deleteAssets();
      },
    });
  }, [deleteAssets]);

  const handleModalCancel = useCallback(() => {
    const { form } = formRef.current;
    form.resetFields();
    setIsModalVisible(false);
  }, []);

  const handleModalSubmit = useCallback(() => {
    const { form } = formRef.current;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      rowDataState
        ? dispatch(updateServiceRoles.request({ id: rowDataState.id, values }))
        : dispatch(addNewServiceRoles.request(values));
      form.resetFields();
      setIsModalVisible(false);
    });
  }, [dispatch, rowDataState]);

  const onCreateNewAssetHandler = useCallback(() => {
    setRowDataState(null);
    setIsModalVisible(true);
  }, []);

  const onEditAssetHandler = useCallback((rowData) => {
    setRowDataState(rowData);
    setIsModalVisible(true);
  }, []);

  return (
    <div>
      <Title level={4}>
        {titleMassage}
        <Button type='success' size='large' onClick={onCreateNewAssetHandler}>
          {addNewButtonMessage}
        </Button>
      </Title>
      <div>
        <Table
          loading={isServiceRolesLoading}
          rowKey='id'
          pagination={{
            current: page,
            total: serviceRoles.total,
            pageSize: PAGE_SIZE,
            onChange: (current) => setPage(current),
          }}
          dataSource={[...serviceRoles.data]}
          columns={columns}
          rowSelection={{
            selectedRowKeys: rowKeys,
            onChange: (selectedRowKeys) => setRowKeys(selectedRowKeys),
          }}
          />
        <Footer>
          <Button
            type='danger'
            size='large'
            onClick={showDeleteDialog}
            disabled={!rowKeys.length}
            >
            <Icon type='delete' theme='filled' />
          </Button>
          <Button type='danger' size='large' onClick={() => setRowKeys([])}>
            {unselectButtonMassage}
            <Icon type='reload' size='large' />
          </Button>
        </Footer>
        <FormModal
          wrappedComponentRef={formRef}
          visible={isModalVisible}
          onCancel={handleModalCancel}
          onSubmit={handleModalSubmit}
          serviceToEdit={rowDataState}
        />
      </div>
    </div>
  );
};

ServiceRolesCompinent.propTypes = {
  getServiceRolesErrors: PropTypes.array,
  serviceRoles: PropTypes.array,
  isServiceRolesLoading: PropTypes.bool,
  isSubmitSuccessful: PropTypes.bool,

};

export default React.memo(ServiceRolesCompinent);
