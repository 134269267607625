import { getStateProvinces, deleteStateProvinces, getPageStateProvinces } from 'actions/stateProvinces';

const stateProvinces = () => {
  const initialState = {};

  return (state = initialState, { type, response, ids }) => {
    switch (type) {
      case getStateProvinces.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getPageStateProvinces.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case deleteStateProvinces.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      default:
        return state;
    }
  };
};

export default stateProvinces();
