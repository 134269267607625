import { messages } from 'messages';

const {
  companyNameInputMessages,
  categorySelelctMessages,
  websiteInputMessages,
  cityInputMessages,
  addressInputMessages,
  phoneInputMessages,
  stateProvinceSelectMessages,
  notesInputMessages,
  aboutCombanyHTMLMessages,
  countrySelectMessages,
} = messages.consultants.form;

const formItemsConfig = {
  companyNameInput: {
    label: companyNameInputMessages.label,
    fieldName: 'companyName',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: companyNameInputMessages.required,
        },
        { max: 250 },
      ],
    },
    elementProps: {},
  },
  categorySelelct: {
    label: categorySelelctMessages.label,
    fieldName: 'categoryId',
    decoratorOptions: {
      rules: [{ required: true, message: categorySelelctMessages.required }],
    },
    elementProps: {},
    options: [],
  },
  websiteInput: {
    label: websiteInputMessages.label,
    fieldName: 'website',
    decoratorOptions: {
      rules: [{ max: 60, type: 'url' }, { required: true, message: websiteInputMessages.required }],
    },
    elementProps: {},
  },
  addressInput: {
    label: addressInputMessages.label,
    fieldName: 'address',
    decoratorOptions: {
      rules: [{ required: true, message: addressInputMessages.required }],
    },
    elementProps: {},
  },
  countrySelect: {
    label: countrySelectMessages.label,
    fieldName: 'country',
    decoratorOptions: {
      rules: [
        {
          required: true,
          message: countrySelectMessages.required,
        },
      ],
    },
    elementProps: {},
    options: [
      { id: 'ca', name: 'Canada' },
      { id: 'us', name: 'USA' },
    ],
  },
  cityInput: {
    label: cityInputMessages.label,
    fieldName: 'city',
    decoratorOptions: {
      rules: [{ required: true, message: cityInputMessages.required }],
    },
    elementProps: {},
  },
  phoneInput: {
    label: phoneInputMessages.label,
    fieldName: 'phone',
    decoratorOptions: {
      rules: [{ required: true, message: phoneInputMessages.required }],
    },
    elementProps: {},
  },
  stateProvinceSelect: {
    label: stateProvinceSelectMessages.label,
    fieldName: 'provinceId',
    decoratorOptions: {
      rules: [{ required: true, message: stateProvinceSelectMessages.required }],
    },
    elementProps: {},
    options: [],
  },
  notesInput: {
    label: notesInputMessages.label,
    fieldName: 'notes',
    decoratorOptions: {
      rules: [],
    },
    elementProps: {
      rows: 4,
    },
  },
  aboutCombanyHTMLEditor: {
    label: aboutCombanyHTMLMessages.label,
    fieldName: 'about',
    decoratorOptions: {
      rules: [],
    },
    elementProps: { style: { height: '300px' } },
  },
};

export default formItemsConfig;
