import { makeRequestAction } from './index';

export const getConsultants = makeRequestAction('GET_CONSULTANTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addNewConsultant = makeRequestAction('ADD_NEW_CONSULTANT');

export const deleteConsultants = makeRequestAction('DELETE_CONSULTANTS', {
  onSuccess(params, response) {
    return {
      ids: response.map((len) => len.id),
    };
  },
});

export const updateConsultant = makeRequestAction('UPDATE_CONSULTANT');

export const importConsultants = makeRequestAction('IMPORT_CONSULTANTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const importConsultantsContacts = makeRequestAction('IMPORT_CONSULTANTS_CONTACTS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
