import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { useDidUpdateEffect } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button, FormItem, Input, InputNumber, RichTextEditor, Select, Switch,
} from 'components';
import {
  Col, Form, Icon, message, Modal, Row, Spin, Typography, Upload,
} from 'antd';
import {
  getLenderLogo,
  getLenders,
  getLendersFormErrors,
  getLoading,
  getSelectedLender,
  getSelectedLenderLoading,
  getSuccessState,
  lenderLogoLoading,
  newLenderLoading,
} from 'selectors/lenders';
import { getPropertyTypes, propertyTypesLoading } from 'selectors/propertyTypes';
import { getStateProvinces, stateProvincesLoading } from 'selectors/stateProvinces';
import { getMarkets, marketsLoading } from 'selectors/markets';
import { getLoanTypes, loanTypesLoading } from 'selectors/loanTypes';
import { getPropertyClasses, propertyClassesLoading } from 'selectors/propertyClasses';
import { categoriesLoading, getCategories } from 'selectors/categories';

import {
  addLenderLogo,
  addNewLender,
  deleteLenderLogo,
  getLender as fetchLender,
  getLenders as fetchLenders,
  updateLender,
} from 'actions/lenders';
import { getMarkets as fetchMarkets } from 'actions/markets';
import { getLoanTypes as fetchLoanTypes } from 'actions/loanTypes';
import { getPropertyTypes as fetchPropertyTypes } from 'actions/propertyTypes';
import { getStateProvinces as fetchStateProvinces } from 'actions/stateProvinces';
import { getPropertyClasses as fetchPropertyClasses } from 'actions/propertyClasses';
import { getCategories as fetchCategories } from 'actions/categories';
import { getCountries as fetchCounties } from 'actions/countries';

import styled from 'styled-components';

import { messages } from 'messages';
import routes from 'constants/routes';
import Contacts from './contacts';
import formItemsConfig from './formItemsConfig';

const { Title } = Typography;
const {
  maxLoanInputMessages,
  feeMaxInputMessages,
  maxTermInputMessages,
  newLenderTitleMessage,
  editLenderTitleMessage,
  editButtonMessage,
  createButtonMessage,
  confirmModalMessage,
  chooseLogoMessage,
  portfolioMixMessages,
  originationsMessages,
  pricingModelsMessages,
  countrySelelctMessages,
  maxAmortizationInputMessages,
} = messages.landersCrudMessages;

const { Option } = Select;
const { confirm } = Modal;

const toNumber = (value) => {
  if (value === null || value === undefined || value === '') {
    return null;
  }
  return Number(value);
};

const StyledArrowButton = styled(Button)`
  padding-left: 15px !important;
`;

const OriginationDeleteButton = styled(Button)`
  margin-top: 28px;
`;

const TitleRow = styled(Row)`
  margin-bottom: 40px;
`;

const LenderData = (props) => {
  const { form, history } = props;
  const { getFieldDecorator } = form;
  const { Paragraph } = Typography;

  const current = props.location.state && props.location.state.currentLender;
  const currentLender = useSelector(getSelectedLender);
  const [imgURL, setImgURL] = useState(currentLender ? currentLender.logo : null);

  const dispatch = useDispatch();
  const lendersFormErrors = useSelector(getLendersFormErrors);
  const isSubmitloading = useSelector(newLenderLoading);
  const isSubmitSuccessful = useSelector(getSuccessState);
  const isGetLoading = useSelector(getLoading);
  const markets = useSelector(getMarkets);
  const loanTypes = useSelector(getLoanTypes);
  const propertyTypes = useSelector(getPropertyTypes);
  const stateProvinces = useSelector(getStateProvinces);
  const propertyClasses = useSelector(getPropertyClasses);
  const categories = useSelector(getCategories);
  const lenderLogoUrl = useSelector(getLenderLogo);
  const isMarketsLoading = useSelector(marketsLoading);
  const isLoanTypesLoading = useSelector(loanTypesLoading);
  const isPropertyTypesLoading = useSelector(propertyTypesLoading);
  const isStateProvincesLoading = useSelector(stateProvincesLoading);
  const isPropertyClassesLoading = useSelector(propertyClassesLoading);
  const isCategoriesLoading = useSelector(categoriesLoading);
  const isUploadLoading = useSelector(lenderLogoLoading);
  const isCurrentLenderLoading = useSelector(getSelectedLenderLoading);

  let categoryLenders = useSelector(getLenders);
  categoryLenders = currentLender && categoryLenders.data.filter((l) => l.categoryId === currentLender.categoryId);
  const currentLenderIndex = currentLender && categoryLenders.findIndex((l) => l.id === currentLender.id);

  const changeLender = (index) => {
    dispatch(fetchLender.request({ id: categoryLenders[index].id }));
    setImgURL(categoryLenders[index].logo);
  };

  const {
    companyNameInput,
    ratingInput,
    statusSelelct,
    countrySelelct,
    categorySelelct,
    websiteInput,
    addressInput,
    logoInput,
    minLoanInput,
    maxLoanInput,
    minDSCRInput,
    maxLTVInput,
    feeMaxInput,
    feeMinInput,
    maxTermInput,
    minTermInput,
    syndicateSelelct,
    syndicateNoteInput,
    portfolioSizeInput,
    numberOfLoansInput,
    minAmortizationInput,
    maxAmortizationInput,
    interestOnlySwitch,
    propertyClassSelelct,
    marketsSelelct,
    loanTypeSelelct,
    propertyTypeSelelct,
    stateProvinceSelect,
    notesInput,
    aboutCombanyHTMLEditor,
    // recourseSelect,
    rateTypeSelect,
    portfolioMixConstructionInput,
    portfolioMixBridgeInput,
    portfolioMixTermInput,
    portfolioMixCMHCInput,
    originationsYearSelect,
    originationsValueInput,
    pricingModelLoanTypesSelect,
    pricingModelIndexTypeSelect,
    pricingModelNonRecourseSwitch,
    pricingModelSpreadMinInput,
    pricingModelSpreadMaxInput,
    relationshipLenderInput,
  } = formItemsConfig;

  useEffect(() => {
    current && dispatch(fetchLender.request({ id: current.id }));
    dispatch(fetchMarkets.request({ $limit: 50 }));
    dispatch(fetchLoanTypes.request({ $limit: 50 }));
    dispatch(fetchPropertyTypes.request({ $limit: 50 }));
    dispatch(fetchStateProvinces.request({
      country: current ? current.country : 'ca',
      getAll: true,
    }));
    dispatch(fetchPropertyClasses.request({ $limit: 50 }));
    dispatch(fetchCategories.request({
      getAll: true,
      getCategoryOnly: true,
    }));
    dispatch(fetchCounties.request({ $limit: 50 }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state, dispatch]);

  useEffect(() => {
    currentLender && dispatch(fetchLenders.request({
      $limit: 1000,
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currentLender && setImgURL(currentLender.logo);
  }, [currentLender]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      message.success('Record has been successfully saved');
      history.push(routes.lenders.table.pathname);
    }
  }, [history, isSubmitSuccessful]);

  useEffect(() => {
    lendersFormErrors
      && lendersFormErrors.forEach((error) => message.error(`Error ${error.code} ${error.message}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(lendersFormErrors)]);

  useDidUpdateEffect(() => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        submitForm(values);
      }
      return true;
    });
    if (currentLender) {
      setImgURL(lenderLogoUrl);
    }
  }, [lenderLogoUrl]);

  // eslint-disable-next-line no-return-await
  const getLogoFile = useCallback(async () => await fetch(imgURL).then((r) => r.blob()), [imgURL]);

  const getRequestValues = useCallback(
    (values) => {
      const {
        originationsKeys,
        originationsYears,
        originationsValues,
        pricingModelKeys,
        pricingModelLoanTypes,
        pricingModelIndexType,
        pricingModelNonRecourse,
        pricingModelSpreadMin,
        pricingModelSpreadMax,
        ...otherValues
      } = values;
      const originations = originationsKeys
        .map((key) => ({
          year: originationsYears[key],
          value: originationsValues[key],
        }))
        .filter((origination) => origination.year);
      const pricings = pricingModelKeys
        .map(((key) => ({
          loanType: pricingModelLoanTypes[key],
          indexType: pricingModelIndexType[key],
          nonRecource: pricingModelNonRecourse[key],
          spreadMin: pricingModelSpreadMin[key],
          spreadMax: pricingModelSpreadMax[key],
        })));

      let logo = null;
      if (imgURL) {
        if (currentLender && currentLender.logo === imgURL) {
          logo = currentLender.logo;
        } else {
          logo = lenderLogoUrl;
        }
      }

      return {
        ...otherValues,
        io: values.io ? 'true' : 'false',
        originations,
        pricings,
        logo,
      };
    },
    [currentLender, imgURL, lenderLogoUrl],
  );

  const submitForm = useCallback(
    (values) => {
      const modifyedValues = getRequestValues(values);
      currentLender
        ? dispatch(
          updateLender.request({
            id: currentLender.id,
            values: modifyedValues,
          }),
        )
        : dispatch(addNewLender.request(modifyedValues));
    },
    [currentLender, dispatch, getRequestValues],
  );

  const showConfirm = useCallback(
    (values) => {
      confirm({
        title: confirmModalMessage,
        confirmLoading: true,
        onOk() {
          if (currentLender) {
            if (currentLender.logo) {
              if (imgURL && imgURL !== currentLender.logo) {
                getLogoFile().then((file) => dispatch(addLenderLogo.request({ file, source: 'lenders' })));
                return;
              }
              if (!imgURL) {
                dispatch(
                  deleteLenderLogo.request({
                    id: currentLender.logo.split('/').slice(-1)[0],
                  }),
                );
                return;
              }
            } else if (imgURL) {
              getLogoFile().then((file) => dispatch(addLenderLogo.request({ file, source: 'lenders' })));
              return;
            }
          } else if (imgURL) {
            getLogoFile().then((file) => dispatch(addLenderLogo.request({ file, source: 'lenders' })));
            return;
          }
          submitForm(values);
        },
        onCancel() {},
      });
    },
    [currentLender, dispatch, getLogoFile, imgURL, submitForm],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          showConfirm(values);
        }
        return true;
      });
    },
    [form, showConfirm],
  );

  const handleReset = (e) => {
    e.preventDefault();
    form.resetFields();
    setImgURL(currentLender ? currentLender.logo : null);
  };

  const removeValueArray = (key, index) => {
    const keys = form.getFieldValue(key);
    form.setFieldsValue({
      [key]: keys.filter((k) => k !== index),
    });
  };

  const addValueArray = (key) => {
    const keys = form.getFieldValue(key);
    const newKeys = keys || [];
    const nextKeys = newKeys.concat(
      newKeys.length ? newKeys.slice(-1)[0] + 1 : 0,
    );
    form.setFieldsValue({
      [key]: nextKeys,
    });
  };

  const compareToMaxLoan = (rule, value, callback) => {
    if (value && value < form.getFieldValue(minLoanInput.fieldName)) {
      callback(maxLoanInputMessages.maxCompareToMin);
    } else {
      callback();
    }
  };

  const compareToMinLoan = (rule, value, callback) => {
    if (!value && value !== 0) {
      callback();
      return;
    }
    if (
      !form.getFieldValue(maxLoanInput.fieldName)
      || form.getFieldValue(maxLoanInput.fieldName) < value
    ) {
      form.setFieldsValue({ maxLoan: value + 1 });
    }
    value
      && form.validateFields([maxLoanInput.fieldName], { force: true }, () => {});
    callback();
  };

  const compareToFeeMax = (rule, value, callback) => {
    if (value && value < form.getFieldValue(feeMinInput.fieldName)) {
      callback(feeMaxInputMessages.maxCompareToMin);
    } else {
      callback();
    }
  };

  const compareToFeeMin = (rule, value, callback) => {
    if (!value && value !== 0) {
      callback();
      return;
    }
    if (
      !form.getFieldValue(feeMaxInput.fieldName)
      || form.getFieldValue(feeMaxInput.fieldName) < value
    ) {
      form.setFieldsValue({ feeMax: value + 1 });
    }
    value
      && form.validateFields([feeMaxInput.fieldName], { force: true }, () => {});
    callback();
  };

  const compareToMaxTerm = (rule, value, callback) => {
    if (value && value < form.getFieldValue(minTermInput.fieldName)) {
      callback(maxTermInputMessages.maxCompareToMin);
    } else {
      callback();
    }
  };

  const compareToMinTerm = (rule, value, callback) => {
    if (!value && value !== 0) {
      callback();
      return;
    }
    if (
      !form.getFieldValue(maxTermInput.fieldName)
      || form.getFieldValue(maxTermInput.fieldName) < value
    ) {
      form.setFieldsValue({ [maxTermInput.fieldName]: value + 1 });
    }
    value
      && form.validateFields([maxTermInput.fieldName], { force: true }, () => {});
    callback();
  };

  const compareToMaxAmortization = (rule, value, callback) => {
    if (value && value < form.getFieldValue(maxAmortizationInput.fieldName)) {
      callback(maxAmortizationInputMessages.maxCompareToMin);
    } else {
      callback();
    }
  };

  const compareToMinAmortization = (rule, value, callback) => {
    if (!value && value !== 0) {
      callback();
      return;
    }
    if (
      !form.getFieldValue(maxAmortizationInput.fieldName)
      || form.getFieldValue(maxAmortizationInput.fieldName) < value
    ) {
      form.setFieldsValue({ [maxAmortizationInput.fieldName]: value + 1 });
    }
    value
      && form.validateFields(
        [maxAmortizationInput.fieldName],
        { force: true },
        () => {},
      );
    callback();
  };

  const comparePortfolioMixValues = (rule, value, callback) => {
    if (
      form.getFieldValue(portfolioMixConstructionInput.fieldName)
        + form.getFieldValue(portfolioMixBridgeInput.fieldName)
        + form.getFieldValue(portfolioMixTermInput.fieldName)
        + form.getFieldValue(portfolioMixCMHCInput.fieldName)
      > 100
    ) {
      callback(portfolioMixMessages.sumError);
    } else {
      callback();
    }
  };

  const logoChangeHandler = async (file) => {
    if (file.type.includes('image')) {
      setImgURL(URL.createObjectURL(file));
    } else {
      message.error('File must be an image!');
    }
  };

  const handleCountryChange = useCallback(async (e) => {
    dispatch(fetchStateProvinces.request({ country: e, getAll: true }));
    form.setFieldsValue({ stateProvinces: [] });
    form.setFieldsValue({ pricingModelKeys: [] });
  }, [dispatch, form]);

  const handleFilter = (inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

  getFieldDecorator('originationsKeys', {
    initialValue: currentLender
      ? currentLender.lender_company_originations.map((o, index) => index)
      : [],
  });
  const originationsKeys = form.getFieldValue('originationsKeys');

  getFieldDecorator('pricingModelKeys', {
    initialValue: currentLender
      ? currentLender.lender_company_pricings.map((p, index) => index)
      : [],
  });
  const pricingModelKeys = form.getFieldValue('pricingModelKeys');

  const countrySelectValue = form.getFieldValue(countrySelelct.fieldName);

  return (
    <Fragment>
      <Spin spinning={!!(current && isCurrentLenderLoading)}>
        <Form
          onSubmit={handleSubmit}
          layout={'vertical'}
          labelAlign={'left'}
          hideRequiredMark
      >
          {/* <Col span={}></Col> */}
          <TitleRow>
            <Col span={8} style={{ textAlign: 'left' }}>
              <Title ellipsis level={4}>
                {currentLender ? editLenderTitleMessage : newLenderTitleMessage}
              </Title>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              {
              currentLender
              && <>
                <StyledArrowButton
                  onClick={() => changeLender(currentLenderIndex - 1)}
                  disabled={!categoryLenders[currentLenderIndex - 1]}
                  loading={isGetLoading}>
                  {!isGetLoading && <Icon type='arrow-left' />}
                </StyledArrowButton>
                {' '}
                <StyledArrowButton
                  onClick={() => changeLender(currentLenderIndex + 1)}
                  disabled={!categoryLenders[currentLenderIndex + 1]}
                  loading={isGetLoading}>
                  {!isGetLoading && <Icon type='arrow-right' />}
                </StyledArrowButton>
              </>
          }
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Button type='danger' size='large' onClick={handleReset} style={{ margin: '0 10px' }}>
                <Icon type='delete' theme='filled' />
              </Button>
              <Button
                type='success'
                size='large'
                htmlType='submit'
                loading={isUploadLoading || isSubmitloading}
                style={{ margin: '0 10px' }}
            >
                {currentLender ? editButtonMessage : createButtonMessage}
              </Button>
            </Col>
          </TitleRow>
          <Row gutter={[30, 30]}>
            <Col span={8}>
              <FormItem label={logoInput.label}>
                <Upload
                  listType='picture-card'
                  showUploadList={false}
                  beforeUpload={(file) => {
                    logoChangeHandler(file);
                    return false;
                  }}
              >
                  {imgURL ? (
                    <>
                      <img src={imgURL} style={{ width: '100%' }} alt='logo' />
                      <Button
                        style={{ width: '100%' }}
                        type='danger'
                        size='small'
                        onClick={(e) => {
                          e.stopPropagation();
                          setImgURL();
                        }}
                    >
                        <Icon type='delete' />
                      </Button>
                    </>
                  ) : (
                    <div>
                      <Icon type='plus' />
                      <div>{chooseLogoMessage}</div>
                    </div>
                  )}
                </Upload>
              </FormItem>
              <FormItem label={websiteInput.label}>
                {getFieldDecorator(websiteInput.fieldName, {
                  ...websiteInput.decoratorOptions,
                  initialValue: currentLender ? currentLender.website : null,
                })(<Input {...websiteInput.elementProps} />)}
              </FormItem>
              <FormItem label={companyNameInput.label}>
                {getFieldDecorator(companyNameInput.fieldName, {
                  ...companyNameInput.decoratorOptions,
                  initialValue: currentLender ? currentLender.companyName : null,
                })(<Input {...companyNameInput.elementProps} />)}
              </FormItem>
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <FormItem label={ratingInput.label}>
                    {getFieldDecorator(ratingInput.fieldName, {
                      ...ratingInput.decoratorOptions,
                      initialValue: currentLender
                        ? toNumber(currentLender.rating)
                        : null,
                    })(<InputNumber {...ratingInput.elementProps} />)}
                  </FormItem>
                </Col>
                <Col span={6} offset={6}>
                  <FormItem label={relationshipLenderInput.label}>
                    {getFieldDecorator(relationshipLenderInput.fieldName, {
                      ...relationshipLenderInput.decoratorOptions,
                      initialValue: currentLender ? currentLender.relationshipLender : false,
                    })(<Switch {...relationshipLenderInput.elementProps} disabled={!currentLender} />)}
                  </FormItem>
                </Col>
              </Row>
              <FormItem label={statusSelelct.label}>
                {getFieldDecorator(statusSelelct.fieldName, {
                  ...statusSelelct.decoratorOptions,
                  initialValue: currentLender ? currentLender.status : 'active',
                })(
                  <Select {...statusSelelct.elementProps}>
                    {statusSelelct.options.map((option) => (
                      <Option key={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
              <FormItem label={countrySelelct.label}>
                {getFieldDecorator(countrySelelct.fieldName, {
                  ...countrySelelct.decoratorOptions,
                  initialValue: currentLender ? currentLender.country : 'ca',
                  onChange: (e) => handleCountryChange(e),
                })(
                  <Select {...countrySelelct.elementProps}>
                    {countrySelelct.options.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
              <FormItem label={stateProvinceSelect.label}>
                {getFieldDecorator(stateProvinceSelect.fieldName, {
                  ...stateProvinceSelect.decoratorOptions,
                  initialValue: currentLender
                    ? currentLender.rel_lender_state_provinces.map(
                      (p) => p.state_province.id,
                    )
                    : [],
                })(
                  <Select
                    {...stateProvinceSelect.elementProps}
                    loading={isStateProvincesLoading}
                    filterOption={(a, b) => handleFilter(a, b)}
                >
                    {stateProvinces.data.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
              <FormItem label={categorySelelct.label}>
                {getFieldDecorator(categorySelelct.fieldName, {
                  ...categorySelelct.decoratorOptions,
                  initialValue: currentLender
                    ? currentLender.categoryId
                    : null,
                })(
                  <Select
                    {...categorySelelct.elementProps}
                    loading={isCategoriesLoading}
                >
                    {categories?.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <FormItem label={minLoanInput.label}>
                    {getFieldDecorator(minLoanInput.fieldName, {
                      rules: [
                        ...minLoanInput.decoratorOptions.rules,
                        {
                          validator: compareToMinLoan,
                        },
                      ],
                      initialValue: currentLender
                        ? toNumber(currentLender.minLoan)
                        : null,
                    })(<InputNumber {...minLoanInput.elementProps} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label={maxLoanInput.label}>
                    {getFieldDecorator(maxLoanInput.fieldName, {
                      rules: [
                        ...minLoanInput.decoratorOptions.rules,
                        {
                          validator: compareToMaxLoan,
                        },
                      ],
                      initialValue: currentLender
                        ? toNumber(currentLender.maxLoan)
                        : null,
                    })(<InputNumber {...maxLoanInput.elementProps} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <FormItem label={portfolioSizeInput.label}>
                    {getFieldDecorator(portfolioSizeInput.fieldName, {
                      ...portfolioSizeInput.decoratorOptions,
                      initialValue: currentLender
                        ? toNumber(currentLender.portfolioSize)
                        : null,
                    })(<InputNumber {...portfolioSizeInput.elementProps} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <FormItem label={numberOfLoansInput.label}>
                    {getFieldDecorator(numberOfLoansInput.fieldName, {
                      ...numberOfLoansInput.decoratorOptions,
                      initialValue: currentLender
                        ? toNumber(currentLender.numberOfLoans)
                        : null,
                    })(<InputNumber {...numberOfLoansInput.elementProps} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={8}>
                  <FormItem label={minAmortizationInput.label}>
                    {getFieldDecorator(minAmortizationInput.fieldName, {
                      ...minAmortizationInput.decoratorOptions,
                      rules: [
                        ...minAmortizationInput.decoratorOptions.rules,
                        {
                          validator: compareToMinAmortization,
                        },
                      ],
                      initialValue: currentLender
                        ? toNumber(currentLender.minAmortization)
                        : null,
                    })(
                      <InputNumber
                        {...minAmortizationInput.elementProps}
                    />,
                    )}
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={maxAmortizationInput.label}>
                    {getFieldDecorator(maxAmortizationInput.fieldName, {
                      ...maxAmortizationInput.decoratorOptions,
                      rules: [
                        ...maxAmortizationInput.decoratorOptions.rules,
                        {
                          validator: compareToMaxAmortization,
                        },
                      ],
                      initialValue: currentLender
                        ? toNumber(currentLender.maxAmortization)
                        : null,
                    })(
                      <InputNumber
                        {...maxAmortizationInput.elementProps}
                    />,
                    )}
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={interestOnlySwitch.label}>
                    {getFieldDecorator(interestOnlySwitch.fieldName, {
                      ...interestOnlySwitch.decoratorOptions,
                      initialValue: currentLender
                        ? currentLender.io === 'true'
                        : false,
                    })(
                      <Switch
                        {...interestOnlySwitch.elementProps}
                    />,
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <FormItem label={minDSCRInput.label}>
                    {getFieldDecorator(minDSCRInput.fieldName, {
                      ...minDSCRInput.decoratorOptions,
                      initialValue: currentLender
                        ? toNumber(currentLender.minDSCR)
                        : null,
                    })(<InputNumber {...minDSCRInput.elementProps} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label={maxLTVInput.label}>
                    {getFieldDecorator(maxLTVInput.fieldName, {
                      ...maxLTVInput.decoratorOptions,
                      initialValue: currentLender
                        ? toNumber(currentLender.maxLTV)
                        : null,
                    })(<InputNumber {...maxLTVInput.elementProps} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <FormItem label={feeMinInput.label}>
                    {getFieldDecorator(feeMinInput.fieldName, {
                      rules: [
                        ...feeMinInput.decoratorOptions.rules,
                        {
                          validator: compareToFeeMin,
                        },
                      ],
                      initialValue: currentLender
                        ? toNumber(currentLender.feeMin)
                        : null,
                    })(<InputNumber {...feeMinInput.elementProps} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label={feeMaxInput.label}>
                    {getFieldDecorator(feeMaxInput.fieldName, {
                      rules: [
                        ...feeMaxInput.decoratorOptions.rules,
                        {
                          validator: compareToFeeMax,
                        },
                      ],
                      initialValue: currentLender
                        ? toNumber(currentLender.feeMax)
                        : null,
                    })(<InputNumber {...feeMaxInput.elementProps} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <FormItem label={minTermInput.label}>
                    {getFieldDecorator(minTermInput.fieldName, {
                      rules: [
                        ...minTermInput.decoratorOptions.rules,
                        {
                          validator: compareToMinTerm,
                        },
                      ],
                      initialValue: currentLender
                        ? toNumber(currentLender.minTerm)
                        : null,
                    })(<InputNumber {...minTermInput.elementProps} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label={maxTermInput.label}>
                    {getFieldDecorator(maxTermInput.fieldName, {
                      rules: [
                        ...maxTermInput.decoratorOptions.rules,
                        {
                          validator: compareToMaxTerm,
                        },
                      ],
                      initialValue: currentLender
                        ? toNumber(currentLender.maxTerm)
                        : null,
                    })(<InputNumber {...maxTermInput.elementProps} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <FormItem label={syndicateSelelct.label}>
                    {getFieldDecorator(syndicateSelelct.fieldName, {
                      ...syndicateSelelct.decoratorOptions,
                      initialValue: currentLender ? currentLender.syndicate : null,
                    })(
                      <Select
                        style={{ width: '100% !important' }}
                        {...syndicateSelelct.elementProps}
                    >
                        {syndicateSelelct.options.map((option) => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label={syndicateNoteInput.label}>
                    {getFieldDecorator(syndicateNoteInput.fieldName, {
                      ...syndicateNoteInput.decoratorOptions,
                      initialValue: currentLender
                        ? currentLender.syndicateNote
                        : null,
                    })(<Input {...syndicateNoteInput.elementProps} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <FormItem label={rateTypeSelect.label}>
                    {getFieldDecorator(rateTypeSelect.fieldName, {
                      ...rateTypeSelect.decoratorOptions,
                      initialValue: currentLender ? currentLender.rateType : null,
                    })(
                      <Select {...rateTypeSelect.elementProps}>
                        {rateTypeSelect.options.map((option) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Col>

            <Col span={16}>
              <FormItem label={aboutCombanyHTMLEditor.label}>
                {getFieldDecorator(aboutCombanyHTMLEditor.fieldName, {
                  ...aboutCombanyHTMLEditor.decoratorOptions,
                  initialValue: currentLender ? currentLender.aboutCompany : '',
                })(<RichTextEditor />)}
              </FormItem>
              <FormItem label={addressInput.label}>
                {getFieldDecorator(addressInput.fieldName, {
                  ...addressInput.decoratorOptions,
                  initialValue: currentLender ? currentLender.address : null,
                })(<Input {...addressInput.elementProps} />)}
              </FormItem>
              <FormItem label={propertyClassSelelct.label}>
                {getFieldDecorator(propertyClassSelelct.fieldName, {
                  ...propertyClassSelelct.decoratorOptions,
                  initialValue: currentLender
                    ? currentLender.rel_lender_options_property_classes.map(
                      (propertyClass) => propertyClass.options_property_class.id,
                    )
                    : [],
                })(
                  <Select
                    {...propertyClassSelelct.elementProps}
                    loading={isPropertyClassesLoading}
                >
                    {propertyClasses.data.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
              <FormItem label={marketsSelelct.label}>
                {getFieldDecorator(marketsSelelct.fieldName, {
                  ...marketsSelelct.decoratorOptions,
                  initialValue: currentLender
                    ? currentLender.rel_lender_options_markets.map(
                      (m) => m.options_market.id,
                    )
                    : [],
                })(
                  <Select
                    {...marketsSelelct.elementProps}
                    loading={isMarketsLoading}
                >
                    {markets.data.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
              <FormItem label={loanTypeSelelct.label}>
                {getFieldDecorator(loanTypeSelelct.fieldName, {
                  ...loanTypeSelelct.decoratorOptions,
                  initialValue: currentLender
                    ? currentLender.rel_lender_options_loan_types.map(
                      (l) => l.options_loan_type.id,
                    )
                    : [],
                })(
                  <Select
                    {...loanTypeSelelct.elementProps}
                    loading={isLoanTypesLoading}
                >
                    {loanTypes.data.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
              <FormItem label={propertyTypeSelelct.label}>
                {getFieldDecorator(propertyTypeSelelct.fieldName, {
                  ...propertyTypeSelelct.decoratorOptions,
                  initialValue: currentLender
                    ? currentLender.rel_lender_options_property_types.map(
                      (p) => p.options_property_type.id,
                    )
                    : [],
                })(
                  <Select
                    {...propertyTypeSelelct.elementProps}
                    loading={isPropertyTypesLoading}
                >
                    {propertyTypes.data.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
              <Paragraph level={1}>{portfolioMixMessages.title}</Paragraph>
              <Row gutter={[10, 10]}>
                <Col span={6}>
                  <FormItem label={portfolioMixConstructionInput.label}>
                    {getFieldDecorator(portfolioMixConstructionInput.fieldName, {
                      rules: [
                        ...portfolioMixConstructionInput.decoratorOptions.rules,
                        {
                          validator: comparePortfolioMixValues,
                        },
                      ],
                      initialValue: currentLender
                        ? toNumber(currentLender.portfolioMixConstruction)
                        : null,
                    })(
                      <InputNumber
                        {...portfolioMixConstructionInput.elementProps}
                    />,
                    )}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={portfolioMixBridgeInput.label}>
                    {getFieldDecorator(portfolioMixBridgeInput.fieldName, {
                      rules: [
                        ...portfolioMixBridgeInput.decoratorOptions.rules,
                        {
                          validator: comparePortfolioMixValues,
                        },
                      ],
                      initialValue: currentLender
                        ? toNumber(currentLender.portfolioMixBridge)
                        : null,
                    })(<InputNumber {...portfolioMixBridgeInput.elementProps} />)}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={portfolioMixTermInput.label}>
                    {getFieldDecorator(portfolioMixTermInput.fieldName, {
                      rules: [
                        ...portfolioMixTermInput.decoratorOptions.rules,
                        {
                          validator: comparePortfolioMixValues,
                        },
                      ],
                      initialValue: currentLender
                        ? toNumber(currentLender.portfolioMixTerm)
                        : null,
                    })(<InputNumber {...portfolioMixTermInput.elementProps} />)}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={portfolioMixCMHCInput.label}>
                    {getFieldDecorator(portfolioMixCMHCInput.fieldName, {
                      rules: [
                        ...portfolioMixCMHCInput.decoratorOptions.rules,
                        {
                          validator: comparePortfolioMixValues,
                        },
                      ],
                      initialValue: currentLender
                        ? toNumber(currentLender.portfolioMixCmhc)
                        : null,
                    })(<InputNumber {...portfolioMixCMHCInput.elementProps} />)}
                  </FormItem>
                </Col>
              </Row>

              <Paragraph>{originationsMessages.title}</Paragraph>
              {originationsKeys.map((key, index) => (
                <Row gutter={[10, 10]} key={key.toString()} align='middle'>
                  <Col span={11}>
                    <FormItem label={originationsYearSelect.label}>
                      {getFieldDecorator(
                        `${originationsYearSelect.fieldName}[${key}]`,
                        {
                          ...originationsYearSelect.decoratorOptions,
                          initialValue:
                          currentLender
                          && currentLender.lender_company_originations[key]
                            ? toNumber(
                              currentLender.lender_company_originations[key]
                                .year,
                            )
                            : null,
                        },
                      )(
                        <Select {...originationsYearSelect.elementProps}>
                          {originationsYearSelect.options.map((option) => (
                            <Option
                              key={option}
                              value={option}
                              disabled={originationsKeys
                                .map((k) => form.getFieldValue(
                                  `${originationsYearSelect.fieldName}[${k}]`,
                                ))
                                .includes(option)}
                          >
                              {option}
                            </Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                  </Col>
                  <Col span={11}>
                    <FormItem label={originationsValueInput.label}>
                      {getFieldDecorator(
                        `${originationsValueInput.fieldName}[${key}]`,
                        {
                          ...originationsValueInput.decoratorOptions,
                          initialValue:
                          currentLender
                          && currentLender.lender_company_originations[key]
                            ? toNumber(
                              currentLender.lender_company_originations[key]
                                .value,
                            )
                            : null,
                        },
                      )(<InputNumber {...originationsValueInput.elementProps} />)}
                    </FormItem>
                  </Col>
                  <Col span={2} align={'right'}>
                    <OriginationDeleteButton
                      type='danger'
                      onClick={() => removeValueArray('originationsKeys', key)}
                  >
                      <Icon theme='filled' type='delete' />
                    </OriginationDeleteButton>
                  </Col>
                </Row>
              ))}
              <Row style={{ marginBottom: 32, textAlign: 'right' }} >
                <Button type='primary' onClick={() => addValueArray('originationsKeys')}>
                  <Icon type='plus' /> {originationsMessages.addOrigination}
                </Button>
              </Row>

              <Paragraph>{pricingModelsMessages.title}</Paragraph>
              {pricingModelKeys.map((key, index) => (
                <Row gutter={[10, 10]} key={key.toString()} align='middle'>
                  <Col span={5}>
                    <FormItem label={pricingModelLoanTypesSelect.label}>
                      {getFieldDecorator(`${pricingModelLoanTypesSelect.fieldName}[${key}]`, {
                        ...pricingModelLoanTypesSelect.decoratorOptions,
                        initialValue: currentLender && currentLender.lender_company_pricings[key]
                          ? currentLender.lender_company_pricings[key].loanType
                          : null,
                      })(
                        <Select
                          {...pricingModelLoanTypesSelect.elementProps}
                          loading={isLoanTypesLoading}
                      >
                          {loanTypes.data.map((option) => (
                            <Option
                              key={option.id}
                              value={option.name}
                              disabled={pricingModelKeys
                                .map((k) => form.getFieldValue(
                                  `${pricingModelLoanTypesSelect.fieldName}[${k}]`,
                                ))
                                .includes(option.name)}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                  </Col>
                  <Col span={5}>
                    <FormItem label={pricingModelIndexTypeSelect.label}>
                      {getFieldDecorator(
                        `${pricingModelIndexTypeSelect.fieldName}[${key}]`,
                        {
                          ...pricingModelIndexTypeSelect.decoratorOptions,
                          initialValue:
                          currentLender && currentLender.lender_company_pricings[key]
                            ? currentLender.lender_company_pricings[key].indexType
                            : null,
                        },
                      )(
                        <Select {...pricingModelIndexTypeSelect.elementProps}>
                          {countrySelectValue ? pricingModelIndexTypeSelect.options[countrySelectValue].map((option) => (
                            <Option
                              key={option}
                              value={option}
                          >
                              {option}
                            </Option>
                          )) : <Option key='default' disabled> {countrySelelctMessages.required} </Option>}
                        </Select>,
                      )}
                    </FormItem>
                  </Col>
                  <Col span={5}>
                    <FormItem label={pricingModelSpreadMinInput.label}>
                      {getFieldDecorator(`${pricingModelSpreadMinInput.fieldName}[${key}]`, {
                        ...pricingModelSpreadMinInput.decoratorOptions,
                        initialValue:
                          currentLender && currentLender.lender_company_pricings[key]
                            ? Number(currentLender.lender_company_pricings[key].spreadMin)
                            : null,
                      })(<InputNumber step={0.1} {...pricingModelSpreadMinInput.elementProps} />)}
                    </FormItem>
                  </Col>
                  <Col span={5}>
                    <FormItem label={pricingModelSpreadMaxInput.label}>
                      {getFieldDecorator(`${pricingModelSpreadMaxInput.fieldName}[${key}]`, {
                        ...pricingModelSpreadMaxInput.decoratorOptions,
                        initialValue:
                          currentLender && currentLender.lender_company_pricings[key]
                            ? Number(currentLender.lender_company_pricings[key].spreadMax)
                            : null,
                      })(<InputNumber step={0.1} {...pricingModelSpreadMaxInput.elementProps} />)}
                    </FormItem>
                  </Col>
                  <Col span={2} align={'middle'}>
                    <FormItem label={pricingModelNonRecourseSwitch.label}>
                      {getFieldDecorator(`${pricingModelNonRecourseSwitch.fieldName}[${key}]`, {
                        ...pricingModelNonRecourseSwitch.decoratorOptions,
                        initialValue: currentLender && currentLender.lender_company_pricings[key]
                          ? currentLender.lender_company_pricings[key].nonRecource : false,
                      })(
                        <Switch
                          {...pricingModelNonRecourseSwitch.elementProps}
                    />,
                      )}
                    </FormItem>
                  </Col>
                  <Col span={2} align={'right'} >
                    <OriginationDeleteButton
                      type='danger'
                      onClick={() => removeValueArray('pricingModelKeys', key)}
                  >
                      <Icon theme='filled' type='delete' />
                    </OriginationDeleteButton>
                  </Col>
                </Row>
              ))}
              <Row style={{ marginBottom: 32, textAlign: 'right' }} >
                <Button type='primary' onClick={() => addValueArray('pricingModelKeys')}>
                  <Icon type='plus' /> {pricingModelsMessages.addPricingModel}
                </Button>
              </Row>
              <FormItem label={notesInput.label}>
                {getFieldDecorator(notesInput.fieldName, {
                  ...notesInput.decoratorOptions,
                  initialValue: currentLender ? currentLender.notes : '',
                })(<Input.TextArea {...notesInput.elementProps} />)}
              </FormItem>
            </Col>


          </Row>
          {currentLender
        && <Contacts
          lenderId={currentLender.id}
          country={currentLender.country}
          provinces={stateProvinces.data} />}
        </Form>
      </Spin>
    </Fragment>
  );
};

LenderData.propTypes = {
  form: PropTypes.object,
  isSubmitloading: PropTypes.bool,
  markets: PropTypes.array,
  loanTypes: PropTypes.array,
  propertyTypes: PropTypes.array,
  stateProvinces: PropTypes.array,
  propertyClasses: PropTypes.array,
  categories: PropTypes.array,
  isMarketsLoading: PropTypes.bool,
  isLoanTypesLoading: PropTypes.bool,
  isPropertyTypesLoading: PropTypes.bool,
  isStateProvincesLoading: PropTypes.bool,
  isPropertyClassesLoading: PropTypes.bool,
  isCategoriesLoading: PropTypes.bool,
};

const LendersCRUD = Form.create({ name: 'Lenders_CRUD' })(LenderData);
export default React.memo(LendersCRUD);
