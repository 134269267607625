import { createSelector } from 'reselect';
import {
  getPropertyTypes as fetchPropertyTypes, getPagePropertyTypes, addNewPropertyTypes, deletePropertyTypes, updatePropertyTypes,
} from 'actions/propertyTypes';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getPropertyTypesState = (state) => state.propertyTypes;
export const getErrorsState = (state) => state.error;

export const getPropertyTypes = createSelector(
  getPropertyTypesState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const propertyTypesLoading = createLoadingSelector(fetchPropertyTypes.actionName)();
export const propertyTypesError = createErrorSelector(fetchPropertyTypes.actionName)();


export const pagePropertyTypesLoading = createLoadingSelector(getPagePropertyTypes.actionName)();
export const pagePropertyTypesError = createErrorSelector(getPagePropertyTypes.actionName)();

export const addNewPropertyTypesLoading = createLoadingSelector(addNewPropertyTypes.actionName)();
export const addNewPropertyTypesError = createErrorSelector(addNewPropertyTypes.actionName)();

export const deletePropertyTypesLoading = createLoadingSelector(deletePropertyTypes.actionName)();
export const deletePropertyTypesError = createErrorSelector(deletePropertyTypes.actionName)();

export const getPropertyTypesAllErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      getPagePropertyTypes.actionName,
      addNewPropertyTypes.actionName,
      deletePropertyTypes.actionName,
      updatePropertyTypes.actionName,
      getPagePropertyTypes.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);
