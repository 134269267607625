import { makeRequestAction } from './index';


export const getUsers = makeRequestAction('GET_USERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
      companyId: params.companyId,
    };
  },
});
export const getPageUsers = makeRequestAction('GET_PAGE_USERS', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});


export const addNewUsers = makeRequestAction('ADD_NEW_USER');
export const deleteUsers = makeRequestAction('DELETE_USERS', {
  onSuccess(params, response) {
    return {
      ids: response.map((len) => len.id),
    };
  },
});

export const updateUsers = makeRequestAction('UPDATE_USER');
