import {
  getLender,
  getAllLenders,
  getLenders,
  deleteLenders,
  addNewLender,
  updateLender,
  importLenders,
  importLendersData,
  importLendersContacts,
} from 'actions/lenders';

const lender = () => {
  const initialState = {};

  return (state = initialState, action) => {
    const { type, response, ids } = action;
    switch (type) {
      case getAllLenders.requestTypes.SUCCESS:
        return {
          ...state,
          all: response,
        };
      case getLenders.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getLender.requestTypes.SUCCESS:
        return {
          ...state,
          selectedLender: response,
        };
      case deleteLenders.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      case addNewLender.requestTypes.REQUEST:
      case updateLender.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addNewLender.requestTypes.SUCCESS:
      case updateLender.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case importLenders.requestTypes.SUCCESS:
        return {
          ...state,
          importLendersInfo: {
            ...response,
          },
        };
      case importLendersData.requestTypes.SUCCESS:
        return {
          ...state,
          importLendersDataInfo: {
            ...response,
          },
        };
      case importLendersContacts.requestTypes.SUCCESS:
        return {
          ...state,
          importLendersContactsInfo: {
            ...response,
          },
        };
      case addNewLender.requestTypes.FAILURE:
      case updateLender.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      default:
        return state;
    }
  };
};

export default lender();
