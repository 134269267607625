import { messages } from 'messages';

const {
  marketsMessages,
  loanTypesMessages,
  propertyTypesMessages,
  stateProvincesMessages,
  categoriesMessages,
  propertyClassesMessages,
  consultantCategoriesMessages,
} = messages.assets;


const assetsConfig = {
  propertyClasses: {
    titleMessage: propertyClassesMessages.title,
    name: propertyClassesMessages.name,
    columns: [{
      title: propertyClassesMessages.nameColumn,
      dataIndex: 'name',
      key: 'name',
    }],
    formItems: {
      name: {
        label: propertyClassesMessages.name,
        fieldName: 'name',
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: propertyClassesMessages.nameRequired,
            },
            { max: 250 },
          ],
        },
        elementProps: {},
      },
    },
  },
  markets: {
    titleMessage: marketsMessages.title,
    name: marketsMessages.name,
    columns: [{
      title: marketsMessages.nameColumn,
      dataIndex: 'name',
      key: 'name',
    }],
    formItems: {
      name: {
        label: marketsMessages.name,
        fieldName: 'name',
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: marketsMessages.nameRequired,
            },
            { max: 250 },
          ],
        },
        elementProps: {},
      },
    },
  },
  loanTypes: {
    titleMessage: loanTypesMessages.title,
    name: loanTypesMessages.name,
    columns: [{
      title: loanTypesMessages.nameColumn,
      dataIndex: 'name',
      key: 'name',
    }],
    formItems: {
      name: {
        label: loanTypesMessages.name,
        fieldName: 'name',
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: loanTypesMessages.nameRequired,
            },
            { max: 250 },
          ],
        },
        elementProps: {},
      },
    },
  },
  propertyTypes: {
    titleMessage: propertyTypesMessages.title,
    name: propertyTypesMessages.name,
    columns: [{
      title: propertyTypesMessages.nameColumn,
      dataIndex: 'name',
      key: 'name',
    }],
    formItems: {
      name: {
        label: propertyTypesMessages.name,
        fieldName: 'name',
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: propertyTypesMessages.nameRequired,
            },
            { max: 250 },
          ],
        },
        elementProps: {},
      },
    },
  },
  stateProvinces: {
    titleMessage: stateProvincesMessages.title,
    name: stateProvincesMessages.name,
    columns: [{
      title: stateProvincesMessages.nameColumn,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: stateProvincesMessages.abbreviationColumn,
      dataIndex: 'abbreviation',
      key: 'abbreviation',
    },
    {
      title: stateProvincesMessages.countryColumn,
      dataIndex: 'country',
      key: 'country',
    }],
    formItems: {
      name: {
        label: stateProvincesMessages.name,
        fieldName: 'name',
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: stateProvincesMessages.nameRequired,
            },
            { max: 250 },
          ],
        },
        elementProps: {},
      },
      abbreviation: {
        label: stateProvincesMessages.abbreviation,
        fieldName: 'abbreviation',
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: stateProvincesMessages.abbreviationRequired,
            },
            { max: 250 },
          ],
        },
        elementProps: {},
      },
      country: {
        label: stateProvincesMessages.country,
        fieldName: 'country',
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: stateProvincesMessages.countryRequired,
            },
            { type: 'enum', enum: ['ca', 'us'] },
          ],
        },
        elementProps: {},
        options: ['ca', 'us'],
      },
    },
  },
  categories: {
    titleMessage: categoriesMessages.title,
    name: categoriesMessages.name,
    columns: [{
      title: categoriesMessages.nameColumn,
      dataIndex: 'name',
      key: 'name',
    }],
    formItems: {
      name: {
        label: categoriesMessages.name,
        fieldName: 'name',
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: categoriesMessages.nameRequired,
            },
            { max: 250 },
          ],
        },
        elementProps: {},
      },
    },
  },
  consultantCategories: {
    titleMessage: consultantCategoriesMessages.title,
    name: consultantCategoriesMessages.name,
    columns: [{
      title: consultantCategoriesMessages.nameColumn,
      dataIndex: 'name',
      key: 'name',
    }],
    formItems: {
      name: {
        label: consultantCategoriesMessages.name,
        fieldName: 'name',
        decoratorOptions: {
          rules: [
            {
              required: true,
              message: consultantCategoriesMessages.nameRequired,
            },
            { max: 250 },
          ],
        },
        elementProps: {},
      },
    },
  },
};

export default assetsConfig;
