import {
  getForms, getForm, updateForm, addForm, deleteForm,
} from 'actions/formBuilder';

const formBuilder = () => {
  const initialState = {
    forms: null,
    selectedForm: null,
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getForms.requestTypes.SUCCESS:
        return {
          ...state,
          forms: response,
        };
      case getForm.requestTypes.SUCCESS:
        return {
          ...state,
          selectedForm: response,
        };
      case deleteForm.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - response.ids.length,
        };
      case addForm.requestTypes.REQUEST:
      case updateForm.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addForm.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case addForm.requestTypes.FAILURE:
      case updateForm.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      case updateForm.requestTypes.SUCCESS:
        return {
          ...state,
          selectedForm: response,
          isSuccessful: true,
        };
      default:
        return state;
    }
  };
};

export default formBuilder();
