import axios from 'axios';
import Promise from 'bluebird';
import { getAccessToken } from 'configs/localStorage';
import { API_ROOT } from 'configs/envVars';

Promise.config({
  cancellation: true,
});

// overwrite native Promise implementation with Bluebird's
window.Promise = Promise;

export const authorizationHeader = () => ({
  Authorization: getAccessToken(),
});

export default (headers = {}) => {
  const service = axios.create({
    baseURL: API_ROOT, // url of the api
    headers: {
      Authorization: getAccessToken(),
      ...headers,
    },
  });
  service.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorResponse = error.response;
      if (process.env.NODE_ENV === 'production') {
        switch (errorResponse.status) {
          case 404:
            window.location.pathname = '/not-found';
            break;
          case 403:
            window.location.pathname = '/not-permitted';
            break;
          default:
            break;
        }
      }
      if (errorResponse.status === 401) {
        window.localStorage.clear();
      }
      throw error;
    },
  );
  return service;
};
