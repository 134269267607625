import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getPropertyTypes, deletePropertyTypes, addNewPropertyTypes, updatePropertyTypes, getPagePropertyTypes,
} from 'actions/propertyTypes';
import {
  getPropertyTypesApi, deletePropertyTypesApi, addNewPropertyTypeApi, updatePropertyTypesApi,
} from 'services/propertyTypes';
import fetchEntity from './fetch-entity';


const fetchPropertyTypes = fetchEntity.bind(null, getPropertyTypes.actions, getPropertyTypesApi);
const fetchPagePropertyTypes = fetchEntity.bind(null, getPagePropertyTypes.actions, getPropertyTypesApi);
const fetchAddNewPropertyType = fetchEntity.bind(
  null,
  addNewPropertyTypes.actions,
  addNewPropertyTypeApi,
);

const fetchUpdatePropertyType = fetchEntity.bind(
  null,
  updatePropertyTypes.actions,
  updatePropertyTypesApi,
);

const fetchDeletePropertyTypes = fetchEntity.bind(
  null,
  deletePropertyTypes.actions,
  deletePropertyTypesApi,
);

export function* loadGetPagePropertyTypes({ params }) {
  yield call(fetchPagePropertyTypes, { ...params });
}

function* watchGetPagePropertyTypes() {
  yield takeLatest([getPagePropertyTypes.actionName], loadGetPagePropertyTypes);
}

export function* loadGetPagePropertyTypesOnChange({ params }) {
  const propertyTypes = yield select((state) => state.propertyTypes);
  const { total, skip, limit } = propertyTypes;
  if (skip && skip >= total) {
    yield call(fetchPagePropertyTypes, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchPagePropertyTypes, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetPagePropertyTypesOnChange() {
  yield takeLatest([
    deletePropertyTypes.requestTypes.SUCCESS,
    addNewPropertyTypes.requestTypes.SUCCESS,
    updatePropertyTypes.requestTypes.SUCCESS,
  ], loadGetPagePropertyTypesOnChange);
}

export function* loadGetPropertyTypes({ params }) {
  yield call(fetchPropertyTypes, { ...params });
}
function* watchGetPropertyTypes() {
  yield takeLatest(getPropertyTypes.actionName, loadGetPropertyTypes);
}

export function* loadUpdatePropertyTypes({ params }) {
  yield call(fetchUpdatePropertyType, { ...params });
}

function* watchUpdatePropertyTypes() {
  yield takeLatest(updatePropertyTypes.actionName, loadUpdatePropertyTypes);
}

export function* loadAddNewPropertyType({ params }) {
  yield call(fetchAddNewPropertyType, { ...params });
}

function* watchAddNewPropertyType() {
  yield takeLatest(addNewPropertyTypes.actionName, loadAddNewPropertyType);
}
export function* loadDeletePropertyTypes({ params }) {
  yield call(fetchDeletePropertyTypes, { ...params });
}

function* watchDeletePropertyTypes() {
  yield takeLatest(deletePropertyTypes.actionName, loadDeletePropertyTypes);
}

export default {
  watchGetPropertyTypes,
  watchUpdatePropertyTypes,
  watchAddNewPropertyType,
  watchDeletePropertyTypes,
  watchGetPagePropertyTypes,
  watchGetPagePropertyTypesOnChange,
};
