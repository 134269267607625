import { getRoles } from 'actions/roles';

const roles = () => {
  const initialState = {};

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getRoles.requestTypes.SUCCESS:
        return {
          ...response,
        };
      default:
        return state;
    }
  };
};

export default roles();
