import React from 'react';
import styled from 'styled-components';
import {
  Card as card, Icon, Typography, Popconfirm,
} from 'antd';

const { Text } = Typography;

const BuilderFieldCardWrapper = styled(card)`
  line-height: 1 !important;
  .ant-card-body {
    padding: 10px;
    position: relative; 
    background: #fafafa;
  }
  .ant-card-actions > li {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .ant-card-actions > li > span > .anticon {
    font-size: 11px;
    line-height: 11px;
  }
  .drag-button
  {
      position: absolute;
      top: 10px;
      right: 10px;
  }
  .byline
  {
    font-size: 11px;
  }
`;

const BuilderFieldCard = ({
  id, label, type, slug, locked, removeField, onSettingsClick, dragHandleProps,
}) => (<BuilderFieldCardWrapper
  style={{ marginBottom: 10 }}
  key={`field-${id}`}
  actions={[
    <Icon type='setting' key='setting' onClick={onSettingsClick} />,
    <Popconfirm
      title='Are you sure you want to delete this filed?'
      onConfirm={() => removeField(id)}
      okText='Remove'
      cancelText='Cancel'
    >
      <Icon
        type='delete'
        key='delete'
        theme='twoTone'
        twoToneColor='#ff0000'
      />
    </Popconfirm>,
  ]}
>
  <Icon type='drag' key='drag' className={'drag-button'} {...dragHandleProps} />
  <Text ellipsis>{label}</Text>
  <Text code ellipsis>
    {type}
  </Text>
  <br />
  <Text type='secondary' className={'byline'} ellipsis>
    {slug}
  </Text>
</BuilderFieldCardWrapper>);
export default BuilderFieldCard;
