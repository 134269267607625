import { makeRequestAction } from './index';

export const getEmailTemplates = makeRequestAction('GET_EMAIL_TEMPLATES', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const getPageEmailTemplates = makeRequestAction('GET_PAGE_EMAIL_TEMPLATES', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const addNewEmailTemplate = makeRequestAction('ADD_NEW_EMAIL_TEMPLATE');

export const deleteEmailTemplates = makeRequestAction('DELETE_EMAIL_TEMPLATE', {
  onSuccess(params, response) {
    return {
      ids: response.map((len) => len.id),
    };
  },
});

export const updateEmailTemplates = makeRequestAction('UPDATE_EMAIL_TEMPLATE', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
