import {
  getUserCompanies,
  getPageUserCompanies,
  deleteUserCompanies,
  addNewUserCompany,
  updateUserCompanies,
} from 'actions/userCompanies';

const userCompanies = () => {
  const initialState = {};

  return (state = initialState, action) => {
    const { type, response, ids } = action;
    switch (type) {
      case getUserCompanies.requestTypes.SUCCESS:
      case getPageUserCompanies.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case deleteUserCompanies.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      case addNewUserCompany.requestTypes.REQUEST:
      case updateUserCompanies.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addNewUserCompany.requestTypes.SUCCESS:
      case updateUserCompanies.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case addNewUserCompany.requestTypes.FAILURE:
      case updateUserCompanies.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      default:
        return state;
    }
  };
};

export default userCompanies();
