
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

const RichTextEditor = styled(ReactQuill)`
  background-color: white;
`;

export default RichTextEditor;
