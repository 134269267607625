import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import PageContainer from 'containers/PageContainer';
import { useSelector } from 'react-redux';
import { userIsAuth } from 'selectors/auth';
import Routes from './Routes';

const AppRouter = (props) => {
  const isAuth = useSelector(userIsAuth);

  return (
    <BrowserRouter>
      {isAuth
        ? <PageContainer title={props.location}>
          <Routes {...props} />
        </PageContainer>
        : <Routes {...props} />
      }
    </BrowserRouter>
  );
};

export default AppRouter;
