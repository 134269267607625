import styled from 'styled-components';
import propTypes from 'prop-types';

const BuilderWrapper = styled.div`
  margin: 20px auto;
  border: 2px solid #e8e8e8;
  padding: 20px;
  background: #fff;  
`;

BuilderWrapper.propTypes = {
  key: propTypes.string,
};
/** @component */
export default BuilderWrapper;
