import { createSelector } from 'reselect';
import {
  getContacts as fetchContacts, getPageContacts, addNewContact, deleteContacts, updateContacts,
} from 'actions/contacts';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getContactsState = (state) => state.contacts;
export const getErrorsState = (state) => state.error;

export const getContacts = createSelector(
  getContactsState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const contactsLoading = createLoadingSelector(fetchContacts.actionName)();
export const contactsError = createErrorSelector(fetchContacts.actionName)();


export const pageContactsLoading = createLoadingSelector(getPageContacts.actionName)();
export const pageContactsError = createErrorSelector(getPageContacts.actionName)();

export const addNewContactLoading = createLoadingSelector(addNewContact.actionName)();
export const addNewContactError = createErrorSelector(addNewContact.actionName)();

export const updateContactsLoading = createLoadingSelector(updateContacts.actionName)();
export const updateContactsError = createErrorSelector(updateContacts.actionName)();

export const deleteContactsLoading = createLoadingSelector(deleteContacts.actionName)();
export const deleteContactsError = createErrorSelector(deleteContacts.actionName)();

export const getContactsAllErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      addNewContact.actionName,
      deleteContacts.actionName,
      updateContacts.actionName,
      getContacts.actionName,
      getPageContacts.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);
