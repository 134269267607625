import React, { useImperativeHandle, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Modal, Form, Input, message,
} from 'antd';

import { getRoles as fetchRoles } from 'actions/roles';
import { getRoles, rolesLoading, rolesError } from 'selectors/roles';
import { clearAllErrors } from 'actions/general';

import { FormItem, Select } from 'components/';
import { messages } from 'messages';

const {
  formModalEditTitleMassage,
  formModalCreateTitleMassage,
  formModalEditMassage,
  formModalCreateMassage,
  serviceNameMassage,
  serviceRolesMassage,
  serviceNameRequiredMassage,
} = messages.roleManagementMessages;

const { Option } = Select;

const FormModal = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const roles = useSelector(getRoles);
  const isRolesLoading = useSelector(rolesLoading);
  const getRolesError = useSelector(rolesError);

  const {
    visible,
    onCancel,
    onSubmit,
    form,
    serviceToEdit,
  } = props;
  const { getFieldDecorator } = form;

  useImperativeHandle(ref, () => ({
    form,
  }));

  useEffect(() => {
    dispatch(fetchRoles.request());
  }, [dispatch]);

  useEffect(() => {
    getRolesError && message.error(`Error Roles ${getRolesError.code} ${getRolesError.message}`);
    dispatch(clearAllErrors.request());
  }, [dispatch, getRolesError]);

  return (

    <Modal
      visible={visible}
      title={serviceToEdit ? formModalEditTitleMassage : formModalCreateTitleMassage}
      okText={serviceToEdit ? formModalEditMassage : formModalCreateMassage}
      onCancel={onCancel}
      onOk={onSubmit}
>
      <Form layout='vertical'>
        <FormItem label={serviceNameMassage}>
          {getFieldDecorator('service', {
            rules: [
              {
                required: true,
                message: serviceNameRequiredMassage,
              },
            ],
            initialValue: serviceToEdit ? serviceToEdit.service : null,
          })(<Input />)}
        </FormItem>
        <FormItem
          label={serviceRolesMassage}
            >
          {getFieldDecorator('roles', {
            initialValue: serviceToEdit
              ? serviceToEdit.rel_service_roles.map(
                (r) => r.role.id,
              )
              : [],
          })(
            <Select
              mode='multiple'
              loading={isRolesLoading}
                >
              {roles.map((option) => (
                <Option key={option.id} value={option.id}>
                  {option.name}
                </Option>
              ))}
            </Select>,
          )}
        </FormItem>
      </Form>
    </Modal>
  );
});

FormModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  form: PropTypes.object,
  serviceToEdit: PropTypes.object,
  roles: PropTypes.array,
  isRolesLoading: PropTypes.bool,
  isRolesError: PropTypes.object,
};

const ServiceRolesFormModal = Form.create({ name: 'service_roles_form_in_modal' })(FormModal);

export default ServiceRolesFormModal;
