import { messages } from 'messages';

const {
  formNameInputMessages,
  loanTypeSelectMessages,
} = messages.formBuilder.form;

const formItemsConfig = {
  formNameInput: {
    label: formNameInputMessages.label,
    fieldName: 'name',
    decoratorOptions: {
      rules: [{ required: true, message: formNameInputMessages.required }],
    },
    elementProps: {},
  },
  loanTypeSelect: {
    label: loanTypeSelectMessages.label,
    fieldName: 'loanType',
    decoratorOptions: {
      rules: [{ required: true, message: loanTypeSelectMessages.required }],
    },
    elementProps: {},
    options: [
      {
        name: 'Bridge',
        value: 'bridge_template',
      },
      {
        name: 'Term',
        value: 'term_template',
      },
      {
        name: 'Constructions',
        value: 'constructions_template',
      },
    ],
  },
};

export default formItemsConfig;
