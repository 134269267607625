import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getMarkets, deleteMarkets, addNewMarkets, updateMarkets, getPageMarkets,
} from 'actions/markets';
import {
  getMarketsApi, deleteMarketsApi, addNewMarketApi, updateMarketsApi,
} from 'services/markets';
import fetchEntity from './fetch-entity';

const fetchMarkets = fetchEntity.bind(null, getMarkets.actions, getMarketsApi);
const fetchPageMarkets = fetchEntity.bind(null, getPageMarkets.actions, getMarketsApi);
const fetchAddNewMarket = fetchEntity.bind(
  null,
  addNewMarkets.actions,
  addNewMarketApi,
);

const fetchUpdateMarket = fetchEntity.bind(
  null,
  updateMarkets.actions,
  updateMarketsApi,
);

const fetchDeleteMarkets = fetchEntity.bind(
  null,
  deleteMarkets.actions,
  deleteMarketsApi,
);

export function* loadGetPageMarkets({ params }) {
  yield call(fetchPageMarkets, { ...params });
}

function* watchGetPageMarkets() {
  yield takeLatest([getPageMarkets.actionName], loadGetPageMarkets);
}

export function* loadGetPageMarketsOnChange({ params }) {
  const markets = yield select((state) => state.markets);
  const { total, skip, limit } = markets;
  if (skip && skip >= total) {
    yield call(fetchPageMarkets, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchPageMarkets, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetPageMarketsOnChange() {
  yield takeLatest([
    deleteMarkets.requestTypes.SUCCESS,
    addNewMarkets.requestTypes.SUCCESS,
    updateMarkets.requestTypes.SUCCESS,
  ], loadGetPageMarketsOnChange);
}

export function* loadGetMarkets({ params }) {
  yield call(fetchMarkets, { ...params });
}
function* watchGetMarkets() {
  yield takeLatest(getMarkets.actionName, loadGetMarkets);
}

export function* loadUpdateMarkets({ params }) {
  yield call(fetchUpdateMarket, { ...params });
}

function* watchUpdateMarkets() {
  yield takeLatest(updateMarkets.actionName, loadUpdateMarkets);
}

export function* loadAddNewMarket({ params }) {
  yield call(fetchAddNewMarket, { ...params });
}

function* watchAddNewMarket() {
  yield takeLatest(addNewMarkets.actionName, loadAddNewMarket);
}
export function* loadDeleteMarkets({ params }) {
  yield call(fetchDeleteMarkets, { ...params });
}

function* watchDeleteMarkets() {
  yield takeLatest(deleteMarkets.actionName, loadDeleteMarkets);
}

export default {
  watchGetMarkets,
  watchUpdateMarkets,
  watchAddNewMarket,
  watchDeleteMarkets,
  watchGetPageMarkets,
  watchGetPageMarketsOnChange,
};
