/* eslint-disable no-nested-ternary */
import React, {
  Fragment, useEffect, useCallback, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Icon,
  Modal,
  Radio,
  message,
  Typography,
  DatePicker,
  InputNumber,
} from 'antd';
import moment from 'moment-timezone';

import {
  Button,
  Input,
  FormItem,
  Select,
  SelectWithInput,
  Switch,
  NumberFormat,
} from 'components';
import {
  getUsers as fetchUsers,
  addNewUsers,
  updateUsers,
} from 'actions/users';
import { getUserCompanies as fetchUserCompanies } from 'actions/userCompanies';
import { getRoles as fetchRoles } from 'actions/roles';
import { getStateProvinces as fetchStateProvines } from 'actions/stateProvinces';
import {
  getUsers,
  addNewUsersLoading,
  getUsersFormErrors,
  getSuccessState,
} from 'selectors/users';
import { getUserCompanies } from 'selectors/userCompanies';
import { getRoles } from 'selectors/roles';
import { getStateProvinces } from 'selectors/stateProvinces';

import { messages } from 'messages';
import routes from 'constants/routes';
import formItemsConfig from './formItemsConfig';

const { Title } = Typography;
const {
  newUserTitleMessage,
  editUserTitleMessage,
  editButtonMessage,
  createButtonMessage,
  confirmModalMessage,
  repeatPasswordInputMessages,
} = messages.usersCrudMessages;

const {
  businessNameSelect,
  emailInput,
  usernameInput,
  passwordInput,
  repeatPasswordInput,
  verifiedSwitch,
  approvedSwitch,
  activeSwitch,
  accountExpireDateInput,
  userRoleSelect,
  parentSelect,
  accessLendersInput,
  accessConsultantsInput,
  dealEmailLimitInput,
  firstLoginInput,
  createdAtInput,
  updatedAtInput,
  firstNameInput,
  lastNameInput,
  jobTitleInput,
  addressInput,
  postalInput,
  cityInput,
  provinceInput,
  countryInput,
  timeZoneInput,
  phoneNumberInput,
  faxInput,
  isBDUserInput,
} = formItemsConfig;

const { Option } = Select;
const { confirm } = Modal;

const UserData = (props) => {
  const { form, history } = props;
  const { currentUser } = props.location.state || {};
  const { getFieldDecorator } = form;

  const dispatch = useDispatch();
  const userCompanies = useSelector(getUserCompanies);
  const roles = useSelector(getRoles);
  const users = useSelector(getUsers);
  const usersFormErrors = useSelector(getUsersFormErrors);
  const isSubmitloading = useSelector(addNewUsersLoading);
  const isSubmitSuccessful = useSelector(getSuccessState);
  const stateProvinces = useSelector(getStateProvinces);
  const [timeZones, setTimeZones] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const countryVal = form.getFieldValue(countryInput.fieldName);

  useEffect(() => {
    dispatch(fetchUsers.request({ getAll: true }));
    dispatch(fetchUserCompanies.request({ $limit: 1000 }));
    dispatch(fetchRoles.request());
    dispatch(fetchStateProvines.request({ getAll: true }));
  }, [dispatch]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      message.success('Record has been successfully saved');
      !currentUser && history.push(routes.users.table.pathname);
    }
  }, [currentUser, history, isSubmitSuccessful]);

  useEffect(() => {
    usersFormErrors
      && usersFormErrors.forEach((error) => message.error(`Error ${error.code}: ${JSON.stringify(error)}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(usersFormErrors)]);

  useEffect(() => {
    usersFormErrors
      && usersFormErrors.forEach((error) => message.error(`Error ${error.code}: ${JSON.stringify(error)}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(usersFormErrors)]);

  useEffect(() => {
    if (countryVal) {
      const tzVal = moment.tz.zonesForCountry(countryVal);
      setTimeZones(tzVal);

      const provinceVals = stateProvinces.data.filter((item) => item.country === countryVal);
      setProvinces(provinceVals);
    }
  }, [countryVal, stateProvinces]);

  const getRequestValues = useCallback(
    (values) => {
      const {
        accountExpireDate,
        createdAt,
        updatedAt,
        firstLogin,
        firstName,
        lastName,
        jobTitle,
        address,
        postal,
        city,
        country,
        province,
        timeZone,
        phoneNumber,
        fax,
        email,
        username,
        password,
        repeatPassword,
        dealEmailLimit,
        accessLenders,
        accessConsultants,
        parent = parseInt(values.parent, 10),
        ...otherValues
      } = values;

      let accessLendersBasic = false;
      let accessLendersIntermediate = false;
      let accessLendersAdvanced = false;
      let accessConsultantsBasic = false;
      let accessConsultantsIntermediate = false;
      let accessConsultantsAdvanced = false;

      switch (accessLenders) {
        case 'basic':
          accessLendersBasic = true;
          break;
        case 'intermediate':
          accessLendersIntermediate = true;
          break;
        case 'advanced':
          accessLendersAdvanced = true;
          break;
        default:
          break;
      }

      switch (accessConsultants) {
        case 'basic':
          accessConsultantsBasic = true;
          break;
        case 'intermediate':
          accessConsultantsIntermediate = true;
          break;
        case 'advanced':
          accessConsultantsAdvanced = true;
          break;
        default:
          break;
      }

      const requestValues = {
        accountExpireDate: accountExpireDate
          ? accountExpireDate.toISOString()
          : accountExpireDate,
        user_detail: {
          firstName,
          lastName,
          jobTitle,
          address,
          city,
          province,
          country,
          timeZone,
          postal,
          phoneNumber,
          fax,
        },
        user_setting: {
          dealEmailLimit,
          accessLendersBasic,
          accessLendersIntermediate,
          accessLendersAdvanced,
          accessConsultantsBasic,
          accessConsultantsIntermediate,
          accessConsultantsAdvanced,
        },
        username,
        dealEmailLimit,
        parent: parseInt(parent, 10),
        ...otherValues,
      };

      if (!currentUser) {
        requestValues.email = email;
        if (password && repeatPassword) {
          requestValues.password = password;
          requestValues.repeatPassword = repeatPassword;
        }
      } else {
        requestValues.password = password;
        requestValues.repeatPassword = repeatPassword;
      }

      return requestValues;
    },
    [currentUser],
  );

  const submitForm = useCallback(
    (values) => {
      const modifiedValues = getRequestValues(values);
      currentUser
        ? dispatch(
          updateUsers.request({ id: currentUser.id, values: modifiedValues }),
        )
        : dispatch(addNewUsers.request(modifiedValues));
    },
    [currentUser, dispatch, getRequestValues],
  );

  const showConfirm = useCallback(
    (values) => {
      confirm({
        title: confirmModalMessage,
        confirmLoading: true,
        onOk() {
          submitForm(values);
        },
        onCancel() {},
      });
    },
    [submitForm],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          showConfirm(values);
        }
        return true;
      });
    },
    [form, showConfirm],
  );

  const handleReset = (e) => {
    e.preventDefault();
    form.resetFields();
  };

  const repeatPasswordValidator = (rule, value, callback) => {
    if (value && value !== form.getFieldValue(passwordInput.fieldName)) {
      callback(repeatPasswordInputMessages.invalid);
    } else {
      callback();
    }
  };

  return (
    <Fragment>
      <Row gutter={[20, 20]} align={'middle'}>
        <Col span={24}>
          <Form
            onSubmit={handleSubmit}
            layout={'vertical'}
            labelAlign={'left'}
            hideRequiredMark
          >
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <Title ellipsis level={4}>
                  {currentUser ? editUserTitleMessage : newUserTitleMessage}
                </Title>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button
                  type='danger'
                  size='large'
                  onClick={handleReset}
                  style={{ margin: '0 10px' }}
                >
                  <Icon type='delete' theme='filled' />
                </Button>
                <Button
                  type='success'
                  size='large'
                  htmlType='submit'
                  loading={isSubmitloading}
                  style={{ margin: '0 10px' }}
                >
                  {currentUser ? editButtonMessage : createButtonMessage}
                </Button>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                {currentUser && (
                <Row>
                  <Col span={6}>
                    <FormItem label={firstLoginInput.label}>
                      {getFieldDecorator(firstLoginInput.fieldName, {
                        ...firstLoginInput.decoratorOptions,
                        initialValue: moment(currentUser.firstLogin).format('lll'),
                      })(<Input {...firstLoginInput.elementProps} />)}
                    </FormItem>
                  </Col>
                  <Col span={6} offset={3}>
                    <FormItem label={createdAtInput.label}>
                      {getFieldDecorator(createdAtInput.fieldName, {
                        ...createdAtInput.decoratorOptions,
                        initialValue: moment(currentUser.createdAt).format('lll'),
                      })(<Input {...createdAtInput.elementProps} />)}
                    </FormItem>
                  </Col>
                  <Col span={6} offset={3}>
                    <FormItem label={updatedAtInput.label}>
                      {getFieldDecorator(updatedAtInput.fieldName, {
                        ...updatedAtInput.decoratorOptions,
                        initialValue: moment(currentUser.updatedAt).format('lll'),
                      })(<Input {...updatedAtInput.elementProps} />)}
                    </FormItem>
                  </Col>
                </Row>
                )}
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <FormItem label={usernameInput.label}>
                  {getFieldDecorator(usernameInput.fieldName, {
                    ...usernameInput.decoratorOptions,
                    initialValue: currentUser ? currentUser.username : null,
                  })(
                    <Input
                      {...emailInput.elementProps}
                    />,
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={emailInput.label}>
                  {getFieldDecorator(emailInput.fieldName, {
                    ...emailInput.decoratorOptions,
                    initialValue: currentUser ? currentUser.email : null,
                  })(
                    <Input
                      disabled={!!currentUser}
                      {...emailInput.elementProps}
                    />,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <FormItem label={businessNameSelect.label}>
                  {getFieldDecorator(businessNameSelect.fieldName, {
                    ...businessNameSelect.decoratorOptions,
                    initialValue:
                      currentUser && currentUser.user_company
                        ? currentUser.user_company.name
                        : null,
                  })(
                    <SelectWithInput
                      inputProps={{ placeholder: 'Add Company Name' }}
                      {...businessNameSelect.elementProps}
                    >
                      {userCompanies.data.map((option) => (
                        <Option key={option.name} value={option.name}>
                          {option.name}
                        </Option>
                      ))}
                    </SelectWithInput>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <FormItem label={currentUser ? 'Password (ATENTION! Only use to reset)' : passwordInput.label}>
                  {getFieldDecorator(passwordInput.fieldName, {
                    rules: currentUser ? [] : passwordInput.decoratorOptions.rules,
                  })(<Input.Password {...passwordInput.elementProps} />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={currentUser ? 'Repeat Password (ATENTION! Only use to reset)' : repeatPasswordInput.label}>
                  {getFieldDecorator(repeatPasswordInput.fieldName, {
                    rules: currentUser ? [] : [
                      ...repeatPasswordInput.decoratorOptions.rules,
                      { validator: repeatPasswordValidator },
                    ],
                  })(<Input.Password {...repeatPasswordInput.elementProps} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <FormItem label={userRoleSelect.label}>
                  {getFieldDecorator(userRoleSelect.fieldName, {
                    ...userRoleSelect.decoratorOptions,
                    initialValue: currentUser
                      ? currentUser.roles
                      : 'Self Serve',
                  })(
                    <Select {...userRoleSelect.elementProps}>
                      {roles && roles.map((option) => (
                        <Option key={option.name} value={option.name}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={parentSelect.label}>
                  {getFieldDecorator(parentSelect.fieldName, {
                    ...parentSelect.decoratorOptions,
                    initialValue: currentUser ? currentUser.parent : 'No Parent',
                  })(
                    <Select {...parentSelect.elementProps}>
                      {[{ id: null, email: 'No Parent' }].concat(users.data).map((option) => (
                        <Option key={option.id} value={option.id}>
                          {option.email}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <FormItem label={accountExpireDateInput.label}>
                  {getFieldDecorator(accountExpireDateInput.fieldName, {
                    ...accountExpireDateInput.decoratorOptions,
                    initialValue:
                      currentUser && currentUser.accountExpireDate
                        ? moment(currentUser.accountExpireDate)
                        : null,
                  })(<DatePicker {...accountExpireDateInput.elementProps} />)}
                </FormItem>
              </Col>
              <Col span={4}>
                <FormItem label={verifiedSwitch.label}>
                  {getFieldDecorator(verifiedSwitch.fieldName, {
                    ...verifiedSwitch.decoratorOptions,
                    initialValue: currentUser ? currentUser.isVerified : null,
                  })(<Switch {...verifiedSwitch.elementProps} />)}
                </FormItem>
              </Col>
              <Col span={4}>
                <FormItem label={approvedSwitch.label}>
                  {getFieldDecorator(approvedSwitch.fieldName, {
                    ...approvedSwitch.decoratorOptions,
                    initialValue: currentUser ? currentUser.approved : null,
                  })(<Switch {...approvedSwitch.elementProps} />)}
                </FormItem>
              </Col>
              <Col span={4}>
                <FormItem label={activeSwitch.label}>
                  {getFieldDecorator(activeSwitch.fieldName, {
                    ...activeSwitch.decoratorOptions,
                    initialValue: currentUser ? currentUser.isActive : null,
                  })(<Switch {...activeSwitch.elementProps} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Row gutter={[20, 20]}>
                  <Col span={4}>
                    <FormItem label={dealEmailLimitInput.label}>
                      {getFieldDecorator(dealEmailLimitInput.fieldName, {
                        ...dealEmailLimitInput.decoratorOptions,
                        initialValue: currentUser ? currentUser.user_setting.dealEmailLimit : null,
                      })(<InputNumber
                        style={{ width: '68%' }}
                        placeholder='10'
                        min={1}
                        max={100000}
                        defaultValue={(currentUser && currentUser.user_setting.dealEmailLimit) || 10}
                        {...dealEmailLimitInput.elementProps} />)}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label={accessLendersInput.label}>
                      {getFieldDecorator(accessLendersInput.fieldName, {
                        ...accessLendersInput.decoratorOptions,
                        initialValue: currentUser && currentUser.user_setting.accessLendersBasic
                          ? 'basic'
                          : currentUser && currentUser.user_setting.accessLendersIntermediate
                            ? 'intermediate'
                            : currentUser && currentUser.user_setting.accessLendersAdvanced
                              ? 'advanced'
                              : null,
                      })(<Radio.Group>
                        <Radio key={'basic'} value={'basic'}>{'Basic'}</Radio>
                        <Radio key={'intermediate'} value={'intermediate'}>{'Intermediate'}</Radio>
                        <Radio key={'advanced'} value={'advanced'}>{'Advanced'}</Radio>
                      </Radio.Group>)}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label={accessConsultantsInput.label}>
                      {getFieldDecorator(accessConsultantsInput.fieldName, {
                        ...accessConsultantsInput.decoratorOptions,
                        initialValue: currentUser && currentUser.user_setting.accessConsultantsBasic
                          ? 'basic'
                          : currentUser && currentUser.user_setting.accessConsultantsIntermediate
                            ? 'intermediate'
                            : currentUser && currentUser.user_setting.accessConsultantsAdvanced
                              ? 'advanced'
                              : null,
                      })(<Radio.Group>
                        <Radio key={'basic'} value={'basic'}>{'Basic'}</Radio>
                        <Radio key={'intermediate'} value={'intermediate'}>{'Intermediate'}</Radio>
                        <Radio key={'advanced'} value={'advanced'}>{'Advanced'}</Radio>
                      </Radio.Group>)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label={isBDUserInput.label}>
                      {getFieldDecorator(isBDUserInput.fieldName, {
                        ...isBDUserInput.decoratorOptions,
                        initialValue: currentUser ? currentUser.isBDUser : false,
                      })(<Switch {...isBDUserInput.elementProps} />)}
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Title level={4}>User Details</Title>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <FormItem label={firstNameInput.label}>
                  {getFieldDecorator(firstNameInput.fieldName, {
                    ...firstNameInput.decoratorOptions,
                    initialValue:
                      currentUser && currentUser.user_detail
                        ? currentUser.user_detail.firstName
                        : null,
                  })(<Input {...firstNameInput.elementProps} />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={lastNameInput.label}>
                  {getFieldDecorator(lastNameInput.fieldName, {
                    ...lastNameInput.decoratorOptions,
                    initialValue:
                      currentUser && currentUser.user_detail
                        ? currentUser.user_detail.lastName
                        : null,
                  })(<Input {...lastNameInput.elementProps} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <FormItem label={jobTitleInput.label}>
                  {getFieldDecorator(jobTitleInput.fieldName, {
                    ...jobTitleInput.decoratorOptions,
                    initialValue:
                      currentUser && currentUser.user_detail
                        ? currentUser.user_detail.jobTitle
                        : null,
                  })(<Input {...jobTitleInput.elementProps} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={6}>
                <FormItem label={addressInput.label}>
                  {getFieldDecorator(addressInput.fieldName, {
                    ...addressInput.decoratorOptions,
                    initialValue:
                      currentUser && currentUser.user_detail
                        ? currentUser.user_detail.address
                        : null,
                  })(<Input {...addressInput.elementProps} />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={cityInput.label}>
                  {getFieldDecorator(cityInput.fieldName, {
                    ...cityInput.decoratorOptions,
                    initialValue: currentUser && currentUser.user_detail
                      ? currentUser.user_detail.city
                      : null,
                  })(<Input {...cityInput.elementProps} />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={countryInput.label}>
                  {getFieldDecorator(countryInput.fieldName, {
                    ...countryInput.decoratorOptions,
                    initialValue: currentUser && currentUser.user_detail
                      ? currentUser.user_detail.country
                      : null,
                  })(<Select {...countryInput.elementProps}>
                    <Option key='ca' value='ca'>Canada</Option>
                    <Option key='us' value='us'>USA</Option>
                  </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={provinceInput.label}>
                  {getFieldDecorator(provinceInput.fieldName, {
                    ...provinceInput.decoratorOptions,
                    initialValue: currentUser && currentUser.user_detail
                      ? currentUser.user_detail.province
                      : null,
                  })(<Select {...provinceInput.elementProps}>
                    {provinces?.map((option) => (
                      <Option key={option.id} value={option.name}>
                        {option.name}
                      </Option>
                    ))
                    }
                  </Select>)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={6}>
                <FormItem label={postalInput.label}>
                  {getFieldDecorator(postalInput.fieldName, {
                    ...postalInput.decoratorOptions,
                    initialValue:
                      currentUser && currentUser.user_detail
                        ? currentUser.user_detail.postal
                        : null,
                  })(<Input {...postalInput.elementProps} />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={timeZoneInput.label}>
                  {getFieldDecorator('timeZone', {
                    ...timeZoneInput.decoratorOptions,
                    initialValue: currentUser && currentUser.user_detail
                      ? currentUser.user_detail.timeZone
                      : null,
                  })(<Select {...timeZoneInput.elementProps}>
                    {timeZones?.map((tzOption, idx) => (
                      <Option key={`${tzOption}_${idx}`} value={tzOption}>
                        {tzOption}
                      </Option>
                    ))}
                  </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={phoneNumberInput.label}>
                  {getFieldDecorator(phoneNumberInput.fieldName, {
                    ...phoneNumberInput.decoratorOptions,
                    initialValue:
                      currentUser && currentUser.user_detail
                        ? currentUser.user_detail.phoneNumber
                        : null,
                  })(<NumberFormat {...phoneNumberInput.elementProps} />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={faxInput.label}>
                  {getFieldDecorator(faxInput.fieldName, {
                    ...faxInput.decoratorOptions,
                    initialValue:
                      currentUser && currentUser.user_detail
                        ? currentUser.user_detail.fax
                        : null,
                  })(<NumberFormat {...faxInput.elementProps} />)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

UserData.propTypes = {
  form: PropTypes.object,
  isSubmitloading: PropTypes.bool,
};

const UserCRUD = Form.create({ name: 'Users_CRUD' })(UserData);
export default React.memo(UserCRUD);
