import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getStateProvinces, deleteStateProvinces, addNewStateProvinces, updateStateProvinces, getPageStateProvinces,
} from 'actions/stateProvinces';
import {
  getStateProvincesApi, deleteStateProvincesApi, addNewStateProvinceApi, updateStateProvincesApi,
} from 'services/stateProvinces';
import fetchEntity from './fetch-entity';

const fetchStateProvinces = fetchEntity.bind(null, getStateProvinces.actions, getStateProvincesApi);
const fetchPageStateProvinces = fetchEntity.bind(null, getPageStateProvinces.actions, getStateProvincesApi);
const fetchAddNewStateProvince = fetchEntity.bind(
  null,
  addNewStateProvinces.actions,
  addNewStateProvinceApi,
);

const fetchUpdateStateProvince = fetchEntity.bind(
  null,
  updateStateProvinces.actions,
  updateStateProvincesApi,
);

const fetchDeleteStateProvinces = fetchEntity.bind(
  null,
  deleteStateProvinces.actions,
  deleteStateProvincesApi,
);

export function* loadGetPageStateProvinces({ params }) {
  yield call(fetchPageStateProvinces, { ...params });
}

function* watchGetPageStateProvinces() {
  yield takeLatest([getPageStateProvinces.actionName], loadGetPageStateProvinces);
}

export function* loadGetPageStateProvincesOnChange({ params }) {
  const stateProvinces = yield select((state) => state.stateProvinces);
  const { total, skip, limit } = stateProvinces;
  if (skip && skip >= total) {
    yield call(fetchPageStateProvinces, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchPageStateProvinces, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetPageStateProvincesOnChange() {
  yield takeLatest([
    deleteStateProvinces.requestTypes.SUCCESS,
    addNewStateProvinces.requestTypes.SUCCESS,
    updateStateProvinces.requestTypes.SUCCESS,
  ], loadGetPageStateProvincesOnChange);
}

export function* loadGetStateProvinces({ params }) {
  yield call(fetchStateProvinces, { ...params });
}
function* watchGetStateProvinces() {
  yield takeLatest(getStateProvinces.actionName, loadGetStateProvinces);
}

export function* loadUpdateStateProvinces({ params }) {
  yield call(fetchUpdateStateProvince, { ...params });
}

function* watchUpdateStateProvinces() {
  yield takeLatest(updateStateProvinces.actionName, loadUpdateStateProvinces);
}

export function* loadAddNewStateProvince({ params }) {
  yield call(fetchAddNewStateProvince, { ...params });
}

function* watchAddNewStateProvince() {
  yield takeLatest(addNewStateProvinces.actionName, loadAddNewStateProvince);
}
export function* loadDeleteStateProvinces({ params }) {
  yield call(fetchDeleteStateProvinces, { ...params });
}

function* watchDeleteStateProvinces() {
  yield takeLatest(deleteStateProvinces.actionName, loadDeleteStateProvinces);
}

export default {
  watchGetStateProvinces,
  watchUpdateStateProvinces,
  watchAddNewStateProvince,
  watchDeleteStateProvinces,
  watchGetPageStateProvinces,
  watchGetPageStateProvincesOnChange,
};
