import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getPropertyClasses, deletePropertyClasses, addNewPropertyClasses, updatePropertyClasses, getPagePropertyClasses,
} from 'actions/propertyClasses';
import {
  getPropertyClassesApi, deletePropertyClassesApi, addNewPropertyClasseApi, updatePropertyClassesApi,
} from 'services/propertyClasses';
import fetchEntity from './fetch-entity';

const fetchPropertyClasses = fetchEntity.bind(null, getPropertyClasses.actions, getPropertyClassesApi);
const fetchPagePropertyClasses = fetchEntity.bind(null, getPagePropertyClasses.actions, getPropertyClassesApi);
const fetchAddNewPropertyClasse = fetchEntity.bind(
  null,
  addNewPropertyClasses.actions,
  addNewPropertyClasseApi,
);

const fetchUpdatePropertyClasse = fetchEntity.bind(
  null,
  updatePropertyClasses.actions,
  updatePropertyClassesApi,
);

const fetchDeletePropertyClasses = fetchEntity.bind(
  null,
  deletePropertyClasses.actions,
  deletePropertyClassesApi,
);

export function* loadGetPagePropertyClasses({ params }) {
  yield call(fetchPagePropertyClasses, { ...params });
}

function* watchGetPagePropertyClasses() {
  yield takeLatest([getPagePropertyClasses.actionName], loadGetPagePropertyClasses);
}

export function* loadGetPagePropertyClassesOnChange({ params }) {
  const propertyClasses = yield select((state) => state.propertyClasses);
  const { total, skip, limit } = propertyClasses;
  if (skip && skip >= total) {
    yield call(fetchPagePropertyClasses, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchPagePropertyClasses, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetPagePropertyClassesOnChange() {
  yield takeLatest([
    deletePropertyClasses.requestTypes.SUCCESS,
    addNewPropertyClasses.requestTypes.SUCCESS,
    updatePropertyClasses.requestTypes.SUCCESS,
  ], loadGetPagePropertyClassesOnChange);
}

export function* loadGetPropertyClasses({ params }) {
  yield call(fetchPropertyClasses, { ...params });
}
function* watchGetPropertyClasses() {
  yield takeLatest(getPropertyClasses.actionName, loadGetPropertyClasses);
}

export function* loadUpdatePropertyClasses({ params }) {
  yield call(fetchUpdatePropertyClasse, { ...params });
}

function* watchUpdatePropertyClasses() {
  yield takeLatest(updatePropertyClasses.actionName, loadUpdatePropertyClasses);
}

export function* loadAddNewPropertyClasse({ params }) {
  yield call(fetchAddNewPropertyClasse, { ...params });
}

function* watchAddNewPropertyClasse() {
  yield takeLatest(addNewPropertyClasses.actionName, loadAddNewPropertyClasse);
}
export function* loadDeletePropertyClasses({ params }) {
  yield call(fetchDeletePropertyClasses, { ...params });
}

function* watchDeletePropertyClasses() {
  yield takeLatest(deletePropertyClasses.actionName, loadDeletePropertyClasses);
}

export default {
  watchGetPropertyClasses,
  watchUpdatePropertyClasses,
  watchAddNewPropertyClasse,
  watchDeletePropertyClasses,
  watchGetPagePropertyClasses,
  watchGetPagePropertyClassesOnChange,
};
