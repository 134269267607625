import { makeRequestAction } from './index';

export const getCategories = makeRequestAction('GET_CATEGORYS', {
  onSuccess(params, response) {
    return {
      response: [
        ...response,
      ],
    };
  },
});

export const addNewCategories = makeRequestAction('ADD_NEW_CATEGORY');

export const deleteCategories = makeRequestAction('DELETE_CATEGORY', {
  onSuccess(params, response) {
    return {
      response: { ids: response.map((len) => len.id) },
    };
  },
});

export const updateCategories = makeRequestAction('UPDATE_CATEGORY', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});

export const getPageCategories = makeRequestAction('GET_PAGE_CATEGORIES', {
  onSuccess(params, response) {
    return {
      response,
    };
  },
});
