import styled from 'styled-components';
import { PageHeader as header } from 'antd';

const BuilderPageHeader = styled(header)`
    padding: 0 0 20px 0 !important;
    .ant-page-header-heading-extra
    {
        top: 0;
        right: 0;
    }
`;


/** @component */
export default BuilderPageHeader;
