import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getUserCompanies,
  deleteUserCompanies,
  addNewUserCompany,
  updateUserCompanies,
  getPageUserCompanies,
  addUserCompanyLogo,
  deleteUserCompanyLogo,
} from 'actions/userCompanies';
import {
  getUserCompaniesApi,
  deleteUserCompaniesApi,
  addNewUserCompanyApi,
  updateUserCompaniesApi,
} from 'services/userCompanies';
import {
  addUploadApi,
  deleteUploadApi,
} from 'services/uploads';
import fetchEntity from './fetch-entity';

const fetchUserCompanies = fetchEntity.bind(null, getUserCompanies.actions, getUserCompaniesApi);
const fetchPageUserCompanies = fetchEntity.bind(null, getPageUserCompanies.actions, getUserCompaniesApi);
const fetchAddNewUserUserCompany = fetchEntity.bind(
  null,
  addNewUserCompany.actions,
  addNewUserCompanyApi,
);

const fetchUpdateUser = fetchEntity.bind(
  null,
  updateUserCompanies.actions,
  updateUserCompaniesApi,
);

const fetchDeleteUserCompanies = fetchEntity.bind(
  null,
  deleteUserCompanies.actions,
  deleteUserCompaniesApi,
);

const fetchAddUserCompanyLogo = fetchEntity.bind(
  null,
  addUserCompanyLogo.actions,
  addUploadApi,
);

const fetchDeleteUserCompanyLogo = fetchEntity.bind(
  null,
  deleteUserCompanyLogo.actions,
  deleteUploadApi,
);

export function* loadGetPageUserCompanies({ params }) {
  yield call(fetchPageUserCompanies, { ...params, '$sort[updatedAt]': -1 });
}

function* watchGetPageUserCompanies() {
  yield takeLatest([getPageUserCompanies.actionName], loadGetPageUserCompanies);
}

export function* loadGetPageUserCompaniesOnChange({ params }) {
  const userCompanies = yield select((state) => state.userCompanies);
  const { total, skip, limit } = userCompanies;
  if (skip && skip >= total) {
    yield call(fetchPageUserCompanies, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchPageUserCompanies, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetPageUserCompaniesOnChange() {
  yield takeLatest([
    deleteUserCompanies.requestTypes.SUCCESS,
    addNewUserCompany.requestTypes.SUCCESS,
    updateUserCompanies.requestTypes.SUCCESS,
  ], loadGetPageUserCompaniesOnChange);
}

export function* loadGetUserCompanies({ params }) {
  yield call(fetchUserCompanies, { ...params, '$sort[updatedAt]': -1 });
}

function* watchGetUserCompanies() {
  yield takeLatest(getUserCompanies.actionName, loadGetUserCompanies);
}

export function* loadUpdateUserCompanies({ params }) {
  yield call(fetchUpdateUser, { ...params });
}

function* watchUpdateUserCompanies() {
  yield takeLatest(updateUserCompanies.actionName, loadUpdateUserCompanies);
}

export function* loadAddNewUserUserCompany({ params }) {
  yield call(fetchAddNewUserUserCompany, { ...params });
}

function* watchAddNewUserUserCompany() {
  yield takeLatest(addNewUserCompany.actionName, loadAddNewUserUserCompany);
}

export function* loadDeleteUserCompanies({ params }) {
  yield call(fetchDeleteUserCompanies, { ...params });
}

function* watchDeleteUserCompanies() {
  yield takeLatest(deleteUserCompanies.actionName, loadDeleteUserCompanies);
}

export function* loadAddUserCompanyLogo({ params }) {
  yield call(fetchAddUserCompanyLogo, { ...params });
}

function* watchAddUserCompanyLogo() {
  yield takeLatest(addUserCompanyLogo.actionName, loadAddUserCompanyLogo);
}

export function* loadDeleteUserCompanyLogo({ params }) {
  yield call(fetchDeleteUserCompanyLogo, { ...params });
}

function* watchDeleteUserCompanyLogo() {
  yield takeLatest(deleteUserCompanyLogo.actionName, loadDeleteUserCompanyLogo);
}

export default {
  watchGetUserCompanies,
  watchUpdateUserCompanies,
  watchAddNewUserUserCompany,
  watchDeleteUserCompanies,
  watchGetPageUserCompanies,
  watchGetPageUserCompaniesOnChange,
  watchAddUserCompanyLogo,
  watchDeleteUserCompanyLogo,
};
