import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  message,
  Spin,
  Col,
  PageHeader,
  Input,
  Descriptions,
  Button,
  Switch,
  Modal,
} from 'antd';
import {
  getSelectedForm,
  getSelectedFormLoading,
  getSelectedFormError,
} from 'selectors/formBuilder';
import { getForm, deleteForm } from 'actions/formBuilder';
import AddButton from './partials/addButton';
import GridCardComponent from './GridCard';

export default React.memo(
  ({
    history, formId, updateForm, isUpdateError, isUpdating,
  }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(getSelectedFormLoading);
    const error = useSelector(getSelectedFormError);
    const data = useSelector(getSelectedForm);
    const [busySlugs, setBusySlugs] = useState([]);
    const isTemplate = data && data.id && data.id.includes('template');

    useEffect(() => {
      const slugs = data && data.fields.map((field) => field.child.map((child) => child.fields.map((f) => f.slug)));
      setBusySlugs(slugs ? slugs.flat(2) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(data)]);

    useEffect(() => {
      dispatch(getForm.request({ id: formId }));
    }, [dispatch, formId]);

    useEffect(() => {
      if (!isLoading && error) {
        message.error(error.message || 'Form Not Found');
        history.push('/form-builder');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, isLoading]);

    const showDeleteConfirm = () => {
      Modal.confirm({
        title: 'Permanently delete this form?',
        onOk() {
          dispatch(deleteForm.request({ _id: formId }));
          history.push('/form-builder');
        },
      });
    };

    return (
      <Fragment>
        {!isLoading && data ? (
          <Fragment>
            <PageHeader
              title={
                <Input
                  defaultValue={data.name}
                  onChange={(e) => {
                    updateForm({
                      ...data,
                      name: e.target.value,
                    });
                  }}
                  addonBefore='Form Name'
                />
              }
              extra={[
                <Button
                  key='action-button-remove-form'
                  type='danger'
                  loading={isUpdating}
                  disabled={isTemplate}
                  onClick={showDeleteConfirm}
                >
                  Permanently delete
                </Button>,
              ]}
            >
              <Descriptions size='small' column={2}>
                <Descriptions.Item label='Author'>
                  {data.authorName}
                </Descriptions.Item>
                <Descriptions.Item label='Created At'>
                  {moment(data.createdAt).format('lll')}
                </Descriptions.Item>
                <Descriptions.Item label='Updated At'>
                  {moment(data.updatedAt).format('lll')}
                </Descriptions.Item>
                <Descriptions.Item label='Published'>
                  <Switch
                    size={'small'}
                    loading={isUpdating}
                    onChange={(value) => {
                      updateForm({
                        ...data,
                        isActive: value,
                      });
                    }}
                    disabled={isTemplate}
                    defaultChecked={data.isActive}
                  />
                </Descriptions.Item>
              </Descriptions>
            </PageHeader>

            {data.fields.map((result) => (
              <GridCardComponent
                field={result}
                updateForm={updateForm}
                currentData={data}
                key={`${result.id}-grid-card`}
                busySlugs={busySlugs}
              />
            ))}

            <AddButton
              disabled={isTemplate}
              updateForm={updateForm}
              formData={data} />
          </Fragment>
        ) : (
          <Col span={24} style={{ textAlign: 'center' }}>
            <Spin />
          </Col>
        )}
      </Fragment>
    );
  },
);
