import { makeRequestAction } from './index';

export const getStateProvinces = makeRequestAction('GET_STATE_PROVINCES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const addNewStateProvinces = makeRequestAction('ADD_NEW_STATE_PROVINCE');

export const deleteStateProvinces = makeRequestAction('DELETE_STATE_PROVINCE', {
  onSuccess(params, response) {
    return {
      ids: response.map((len) => len.id),
    };
  },
});

export const updateStateProvinces = makeRequestAction('UPDATE_STATE_PROVINCE', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});

export const getPageStateProvinces = makeRequestAction('GET_PAGE_STATE_PROVINCES', {
  onSuccess(params, response) {
    return {
      response: {
        ...response,
      },
    };
  },
});
