import React, { useState } from 'react';
import { Select, Input } from 'antd';
import styled from 'styled-components';

const { Option } = Select;
const StyledInput = styled(Input)`
  border-left: 0 !important; 
  border-right: 0 !important;
  margin: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: none !important
`;

const SelectWithInput = (props) => {
  const { inputProps, ...otherProps } = props;

  const [open, setOpen] = useState();
  const [isInputFocused, setInputFocused] = useState();
  const [value, setValue] = useState();
  const [addedOptions, setAddedOptions] = useState([]);

  const addOption = () => {
    value && setAddedOptions([...addedOptions, value]);
    setValue();
  };

  return (
    <Select
      open={open}
      onSelect={() => setOpen(false)}
      onDropdownVisibleChange={(o) => o && setOpen(o)}
      onBlur={(e) => !isInputFocused && setOpen(false)}
      dropdownRender={(menu) => <div>
        {menu}
        <div>
          <StyledInput
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => setInputFocused(true)}
            onBlur={() => {
              setInputFocused(false);
              setOpen(false);
            }}
            onPressEnter={addOption}
            {...props.inputProps}
          />
        </div>
      </div>}
      {...otherProps}>
      {[...props.children,
        ...addedOptions.map((option) => <Option key={option} value={option}> {option} </Option>)]}
    </Select>
  );
};

export default SelectWithInput;
