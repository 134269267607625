import api from './api';

export const addUploadApi = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => (
    key === 'file' && Array.isArray(data[key])
      ? data[key].forEach((file) => formData.append(key, file))
      : formData.append(key, data[key])
  ));
  return api({ 'Content-type': 'application/x-www-form-urlencoded' }).post('/file-storage', formData);
};

export const deleteUploadApi = (params) => api().delete(`/file-storage/${params.id}`);
