import { createSelector } from 'reselect';
import {
  getUserCompanies as fetchUserCompanies,
  getPageUserCompanies,
  addNewUserCompany,
  deleteUserCompanies,
  updateUserCompanies,
  addUserCompanyLogo,
  deleteUserCompanyLogo,
} from 'actions/userCompanies';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';
import getUploadsState from './uploads';

export const getUserCompaniesState = (state) => state.userCompanies;
export const getErrorsState = (state) => state.error;

export const getUserCompanies = createSelector(
  getUserCompaniesState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const getSuccessState = createSelector(
  getUserCompaniesState,
  (state) => state.isSuccessful,
);

export const userCompaniesLoading = createLoadingSelector(fetchUserCompanies.actionName)();
export const userCompaniesError = createErrorSelector(fetchUserCompanies.actionName)();

export const pageUserCompaniesLoading = createLoadingSelector(getPageUserCompanies.actionName)();
export const pageUserCompaniesError = createErrorSelector(getPageUserCompanies.actionName)();

export const addNewUserCompanyLoading = createLoadingSelector(addNewUserCompany.actionName)();
export const addNewUserCompanyError = createErrorSelector(addNewUserCompany.actionName)();

export const updateUserCompaniesLoading = createLoadingSelector(updateUserCompanies.actionName)();
export const updateUserCompaniesError = createErrorSelector(updateUserCompanies.actionName)();

export const deleteUserCompaniesLoading = createLoadingSelector(deleteUserCompanies.actionName)();
export const deleteUserCompaniesError = createErrorSelector(deleteUserCompanies.actionName)();

export const getUserCompaniesFormErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      addNewUserCompany.actionName,
      deleteUserCompanies.actionName,
      updateUserCompanies.actionName,
      getPageUserCompanies.actionName,
      getUserCompanies.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);

export const getUserCompanyLogo = createSelector(getUploadsState, (uploads) => uploads[addUserCompanyLogo.actionName]);

export const addUserCompanyLogoLoading = createLoadingSelector(addUserCompanyLogo.actionName)();
export const addUserCompanyLogoError = createErrorSelector(addUserCompanyLogo.actionName)();

export const deleteUserCompanyLogoLoading = createLoadingSelector(deleteUserCompanyLogo.actionName)();
export const deleteUserCompanyLogoError = createErrorSelector(deleteUserCompanyLogo.actionName)();
