import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getServiceRoles, deleteServiceRoles, addNewServiceRoles, updateServiceRoles, getPageServiceRoles,
} from 'actions/roleManagements';
import {
  getServiceRolesApi, deleteServiceRolesApi, addNewServiceRoleApi, updateServiceRolesApi,
} from 'services/roleManagements';
import fetchEntity from './fetch-entity';

const fetchServiceRoles = fetchEntity.bind(null, getServiceRoles.actions, getServiceRolesApi);
const fetchPageServiceRoles = fetchEntity.bind(null, getPageServiceRoles.actions, getServiceRolesApi);
const fetchAddNewServiceRole = fetchEntity.bind(
  null,
  addNewServiceRoles.actions,
  addNewServiceRoleApi,
);

const fetchUpdateServiceRole = fetchEntity.bind(
  null,
  updateServiceRoles.actions,
  updateServiceRolesApi,
);

const fetchDeleteServiceRoles = fetchEntity.bind(
  null,
  deleteServiceRoles.actions,
  deleteServiceRolesApi,
);

export function* loadGetPageServiceRoles({ params }) {
  yield call(fetchPageServiceRoles, { ...params });
}

function* watchGetPageServiceRoles() {
  yield takeLatest([getPageServiceRoles.actionName], loadGetPageServiceRoles);
}

export function* loadGetPageServiceRolesOnChange({ params }) {
  const serviceRoles = yield select((state) => state.serviceRoles);
  const { total, skip, limit } = serviceRoles;
  if (skip && skip >= total) {
    yield call(fetchPageServiceRoles, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchPageServiceRoles, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetPageServiceRolesOnChange() {
  yield takeLatest([
    deleteServiceRoles.requestTypes.SUCCESS,
    addNewServiceRoles.requestTypes.SUCCESS,
    updateServiceRoles.requestTypes.SUCCESS,
  ], loadGetPageServiceRolesOnChange);
}

export function* loadGetServiceRoles({ params }) {
  yield call(fetchServiceRoles, { ...params });
}
function* watchGetServiceRoles() {
  yield takeLatest(getServiceRoles.actionName, loadGetServiceRoles);
}

export function* loadUpdateServiceRoles({ params }) {
  yield call(fetchUpdateServiceRole, { ...params });
}

function* watchUpdateServiceRoles() {
  yield takeLatest(updateServiceRoles.actionName, loadUpdateServiceRoles);
}

export function* loadAddNewServiceRole({ params }) {
  yield call(fetchAddNewServiceRole, { ...params });
}

function* watchAddNewServiceRole() {
  yield takeLatest(addNewServiceRoles.actionName, loadAddNewServiceRole);
}
export function* loadDeleteServiceRoles({ params }) {
  yield call(fetchDeleteServiceRoles, { ...params });
}

function* watchDeleteServiceRoles() {
  yield takeLatest(deleteServiceRoles.actionName, loadDeleteServiceRoles);
}

export default {
  watchGetServiceRoles,
  watchUpdateServiceRoles,
  watchAddNewServiceRole,
  watchDeleteServiceRoles,
  watchGetPageServiceRoles,
  watchGetPageServiceRolesOnChange,
};
