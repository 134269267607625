import { createSelector } from 'reselect';
import {
  getConsultantContacts as fetchConsultantContacts,
  getPageConsultantContacts,
  addNewConsultantContact,
  deleteConsultantContacts,
  updateConsultantContacts,
} from 'actions/consultantContacts';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getConsultantContactsState = (state) => state.consultantContacts;
export const getErrorsState = (state) => state.error;

export const getConsultantContacts = createSelector(
  getConsultantContactsState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const consultantContactsLoading = createLoadingSelector(fetchConsultantContacts.actionName)();
export const consultantContactsError = createErrorSelector(fetchConsultantContacts.actionName)();


export const pageConsultantContactsLoading = createLoadingSelector(getPageConsultantContacts.actionName)();
export const pageConsultantContactsError = createErrorSelector(getPageConsultantContacts.actionName)();

export const addNewConsultantContactLoading = createLoadingSelector(addNewConsultantContact.actionName)();
export const addNewConsultantContactError = createErrorSelector(addNewConsultantContact.actionName)();

export const updateConsultantContactsLoading = createLoadingSelector(updateConsultantContacts.actionName)();
export const updateConsultantContactsError = createErrorSelector(updateConsultantContacts.actionName)();

export const deleteConsultantContactsLoading = createLoadingSelector(deleteConsultantContacts.actionName)();
export const deleteConsultantContactsError = createErrorSelector(deleteConsultantContacts.actionName)();

export const getConsultantContactsAllErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      addNewConsultantContact.actionName,
      deleteConsultantContacts.actionName,
      updateConsultantContacts.actionName,
      getConsultantContacts.actionName,
      getPageConsultantContacts.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);
