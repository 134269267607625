import { createSelector } from 'reselect';
import {
  getStateProvinces as fetchStateProvinces, getPageStateProvinces, addNewStateProvinces, deleteStateProvinces, updateStateProvinces,
} from 'actions/stateProvinces';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getStateProvincesState = (state) => state.stateProvinces;
export const getErrorsState = (state) => state.error;

export const getStateProvinces = createSelector(
  getStateProvincesState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const stateProvincesLoading = createLoadingSelector(fetchStateProvinces.actionName)();
export const stateProvincesError = createErrorSelector(fetchStateProvinces.actionName)();


export const pageStateProvincesLoading = createLoadingSelector(getPageStateProvinces.actionName)();
export const pageStateProvincesError = createErrorSelector(getPageStateProvinces.actionName)();

export const addNewStateProvincesLoading = createLoadingSelector(addNewStateProvinces.actionName)();
export const addNewStateProvincesError = createErrorSelector(addNewStateProvinces.actionName)();

export const deleteStateProvincesLoading = createLoadingSelector(deleteStateProvinces.actionName)();
export const deleteStateProvincesError = createErrorSelector(deleteStateProvinces.actionName)();

export const getStateProvincesAllErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      addNewStateProvinces.actionName,
      deleteStateProvinces.actionName,
      updateStateProvinces.actionName,
      getPageStateProvinces.actionName,
      getStateProvinces.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);
