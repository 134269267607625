import React, {
  Fragment, useState, useEffect, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  FormItem,
  Select,
  RichTextEditor,
} from 'components';
import {
  Row, Col, Form, Icon, Typography, Modal, message,
} from 'antd';
import {
  getConsultants,
  newConsultantLoading,
  getConsultantsFormErrors,
  getSuccessState,
  getLoading,
} from 'selectors/consultants';
import {
  getStateProvinces,
  stateProvincesLoading,
} from 'selectors/stateProvinces';
import { getCategories, categoriesLoading } from 'selectors/consultantCategories';

import {
  addNewConsultant,
  updateConsultant,
  getConsultants as fetchConsultants,
  deleteConsultants as deleteConsultantsAction,
} from 'actions/consultants';
import { getStateProvinces as fetchStateProvinces } from 'actions/stateProvinces';
import { getCategories as fetchCategories } from 'actions/consultantCategories';

import styled from 'styled-components';

import { messages } from 'messages';
import routes from 'constants/routes';
import Contacts from './contacts';
import formItemsConfig from './formItemsConfig';

const { Title } = Typography;
const {
  newConsultantTitleMessage,
  editConsultantTitleMessage,
  editButtonMessage,
  confirmDeleteMessage,
  createButtonMessage,
  confirmModalMessage,
} = messages.consultants.form;

const { Option } = Select;
const { confirm } = Modal;

const StyledArrowButton = styled(Button)`
  padding-left: 15px !important;
`;

const TitleRow = styled(Row)`
  margin-bottom: 40px;
`;

const ConsultantsForm = (props) => {
  const { form, history } = props;
  const { getFieldDecorator } = form;

  const [currentConsultant, setCurrentConsultant] = useState(props.location.state && props.location.state.currentConsultant);

  const dispatch = useDispatch();
  const consultantsFormErrors = useSelector(getConsultantsFormErrors);
  const isSubmitloading = useSelector(newConsultantLoading);
  const isSubmitSuccessful = useSelector(getSuccessState);
  const isGetLoading = useSelector(getLoading);
  const stateProvinces = useSelector(getStateProvinces);
  const categories = useSelector(getCategories);
  const isStateProvincesLoading = useSelector(stateProvincesLoading);
  const isCategoriesLoading = useSelector(categoriesLoading);
  let categoryConsultants = useSelector(getConsultants);
  categoryConsultants = currentConsultant && categoryConsultants.data.filter((l) => l.categoryId === currentConsultant.categoryId);

  const currentConsultantIndex = currentConsultant && categoryConsultants.findIndex((l) => l.id === currentConsultant.id);

  const changeConsultant = (index) => {
    setCurrentConsultant(categoryConsultants[index]);
  };

  const {
    companyNameInput,
    categorySelelct,
    websiteInput,
    addressInput,
    cityInput,
    phoneInput,
    stateProvinceSelect,
    notesInput,
    aboutCombanyHTMLEditor,
    countrySelect,
  } = formItemsConfig;

  useEffect(() => {
    dispatch(fetchStateProvinces.request({
      country: currentConsultant?.country || 'ca',
      getAll: true,
    }));
    dispatch(fetchCategories.request({ $limit: 50 }));
  }, [currentConsultant, dispatch]);

  useEffect(() => {
    currentConsultant && dispatch(fetchConsultants.request({
      $limit: 1000,
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful) {
      message.success('Record has been successfully saved');
      history.push(routes.consultants.table.pathname);
    }
  }, [history, isSubmitSuccessful]);

  useEffect(() => {
    consultantsFormErrors
      && consultantsFormErrors.forEach((error) => message.error(`Error ${error.code} ${error.message}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(consultantsFormErrors)]);

  const getRequestValues = useCallback(
    (values) => values,
    [],
  );

  const submitForm = useCallback(
    (values) => {
      const modifyedValues = getRequestValues(values);
      currentConsultant
        ? dispatch(
          updateConsultant.request({
            id: currentConsultant.id,
            values: modifyedValues,
          }),
        )
        : dispatch(addNewConsultant.request(modifyedValues));
    },
    [currentConsultant, dispatch, getRequestValues],
  );

  const showConfirm = useCallback(
    (values) => {
      confirm({
        title: confirmModalMessage,
        confirmLoading: true,
        onOk() {
          submitForm(values);
        },
        onCancel() {},
      });
    },
    [submitForm],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          showConfirm(values);
        }
        return true;
      });
    },
    [form, showConfirm],
  );

  const showDeleteDialog = () => {
    confirm({
      title: confirmDeleteMessage,
      okType: 'danger',
      onOk() {
        deleteConsultant();
      },
    });
  };

  const deleteConsultant = useCallback(
    () => {
      dispatch(deleteConsultantsAction.request({ id: currentConsultant.id }));
      history.push(routes.consultants.table.pathname);
    }, [currentConsultant, dispatch, history],
  );

  const handleCountryChange = (country) => {
    dispatch(fetchStateProvinces.request({
      country,
      getAll: true,
    }));
    form.setFieldsValue({ provinceId: null });
  };

  return (
    <Fragment>
      <Form
        onSubmit={handleSubmit}
        layout={'vertical'}
        labelAlign={'left'}
        hideRequiredMark
      >
        <TitleRow>
          <Col span={8} style={{ textAlign: 'left' }}>
            <Title ellipsis level={4}>
              {currentConsultant ? editConsultantTitleMessage : newConsultantTitleMessage}
            </Title>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            {
              currentConsultant
              && <>
                <StyledArrowButton
                  onClick={() => changeConsultant(currentConsultantIndex - 1)}
                  disabled={!categoryConsultants[currentConsultantIndex - 1]}
                  loading={isGetLoading}>
                  {!isGetLoading && <Icon type='arrow-left' />}
                </StyledArrowButton>
                {' '}
                <StyledArrowButton
                  onClick={() => changeConsultant(currentConsultantIndex + 1)}
                  disabled={!categoryConsultants[currentConsultantIndex + 1]}
                  loading={isGetLoading}>
                  {!isGetLoading && <Icon type='arrow-right' />}
                </StyledArrowButton>
              </>
          }
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button type='danger' size='large' onClick={showDeleteDialog} style={{ margin: '0 10px' }}>
              <Icon type='delete' theme='filled' />
            </Button>
            <Button
              type='success'
              size='large'
              htmlType='submit'
              loading={isSubmitloading}
              style={{ margin: '0 10px' }}
            >
              {currentConsultant ? editButtonMessage : createButtonMessage}
            </Button>
          </Col>
        </TitleRow>
        <Row gutter={[30, 30]}>
          <Col span={12}>
            <FormItem
              label={companyNameInput.label}>
              {getFieldDecorator(companyNameInput.fieldName, {
                ...companyNameInput.decoratorOptions,
                initialValue: currentConsultant ? currentConsultant.companyName : null,
              })(<Input {...companyNameInput.elementProps} />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={categorySelelct.label}>
              {getFieldDecorator(categorySelelct.fieldName, {
                ...categorySelelct.decoratorOptions,
                initialValue: currentConsultant
                  ? currentConsultant.categoryId
                  : null,
              })(
                <Select
                  {...categorySelelct.elementProps}
                  loading={isCategoriesLoading}
                >
                  {categories.data.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label={countrySelect.label}>
              {getFieldDecorator(countrySelect.fieldName, {
                ...countrySelect.decoratorOptions,
                initialValue: currentConsultant ? currentConsultant.country : null,
                onChange: (country) => handleCountryChange(country),
              })(
                <Select {...countrySelect.elementProps}>
                  {countrySelect.options.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label={stateProvinceSelect.label}
            >
              {getFieldDecorator(stateProvinceSelect.fieldName, {
                ...stateProvinceSelect.decoratorOptions,
                initialValue: currentConsultant
                  ? currentConsultant.provinceId : null,
              })(
                <Select
                  {...stateProvinceSelect.elementProps}
                  loading={isStateProvincesLoading}
                >
                  {stateProvinces.data.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label={cityInput.label}>
              {getFieldDecorator(cityInput.fieldName, {
                ...cityInput.decoratorOptions,
                initialValue: currentConsultant ? currentConsultant.city : null,
              })(<Input {...cityInput.elementProps} />)}
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={addressInput.label}
            >
              {getFieldDecorator(addressInput.fieldName, {
                ...addressInput.decoratorOptions,
                initialValue: currentConsultant ? currentConsultant.address : null,
              })(<Input {...addressInput.elementProps} />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={websiteInput.label}>
              {getFieldDecorator(websiteInput.fieldName, {
                ...websiteInput.decoratorOptions,
                initialValue: currentConsultant ? currentConsultant.website : null,
              })(<Input {...websiteInput.elementProps} />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={phoneInput.label}>
              {getFieldDecorator(phoneInput.fieldName, {
                ...phoneInput.decoratorOptions,
                initialValue: currentConsultant ? currentConsultant.phone : null,
              })(<Input {...phoneInput.elementProps} />)}
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={aboutCombanyHTMLEditor.label}>
              {getFieldDecorator(aboutCombanyHTMLEditor.fieldName, {
                ...aboutCombanyHTMLEditor.decoratorOptions,
                initialValue: currentConsultant && currentConsultant.about ? currentConsultant.about : '',
              })(<RichTextEditor />)}
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={notesInput.label}>
              {getFieldDecorator(notesInput.fieldName, {
                ...notesInput.decoratorOptions,
                initialValue: currentConsultant ? currentConsultant.notes : '',
              })(<Input.TextArea {...notesInput.elementProps} />)}
            </FormItem>
          </Col>
        </Row>
        {currentConsultant && <Contacts
          consultantId={currentConsultant.id}
          country={currentConsultant.country}
          provinces={stateProvinces.data} />
        }
      </Form>
    </Fragment>
  );
};

ConsultantsForm.propTypes = {
  form: PropTypes.object,
  isSubmitloading: PropTypes.bool,
  markets: PropTypes.array,
  loanTypes: PropTypes.array,
  propertyTypes: PropTypes.array,
  stateProvinces: PropTypes.array,
  propertyClasses: PropTypes.array,
  categories: PropTypes.array,
  isMarketsLoading: PropTypes.bool,
  isLoanTypesLoading: PropTypes.bool,
  isPropertyTypesLoading: PropTypes.bool,
  isStateProvincesLoading: PropTypes.bool,
  isPropertyClassesLoading: PropTypes.bool,
  isCategoriesLoading: PropTypes.bool,
};

export default React.memo(Form.create({ name: 'consultants-form' })(ConsultantsForm));
