import React, { Fragment } from 'react';
import {
  Form,
  Select,
  Checkbox,
  InputNumber,
} from 'antd';
import { fileExtensions } from 'constants/formBuilder';

const { Option } = Select;

const ValidationTab = React.memo(({
  type, modalData, setModalData, getFieldDecorator, setFieldsValue,
}) => <Fragment>
  <Form.Item
    label={'Is Field Required'}
      >
    {getFieldDecorator('required', {
      initialValue: modalData.validations.required || false,
      valuePropName: 'checked',
    })(
      <Checkbox onChange={(e) => setModalData({
        ...modalData,
        validations: {
          ...modalData.validations,
          required: e.target.checked,
        },
      })}>Required</Checkbox>,
    )}
  </Form.Item>
  {['number', 'text', 'textarea'].includes(type) && <Fragment>
    <Form.Item
      label={'Min Length'}
        >
      {getFieldDecorator('min_length', {
        initialValue: modalData.validations.min_length || 0,
      })(
        <InputNumber
          min={0}
          onChange={(value) => setModalData({
            ...modalData,
            validations: {
              ...modalData.validations,
              min_length: value,
            },
          })} />,
      )}
    </Form.Item>
    <Form.Item
      label={'Max Length'}
        >
      {getFieldDecorator('max_length', {
        initialValue: modalData.validations.max_length || 1,
      })(
        <InputNumber
          min={1}
          onChange={(value) => setModalData({
            ...modalData,
            validations: {
              ...modalData.validations,
              max_length: value,
            },
          })} />,
      )}
    </Form.Item>
  </Fragment>}
  {type === 'media' && <Fragment>
    <Form.Item
      label={'Allow multi upload'}
        >
      {getFieldDecorator('multi_upload', {
        initialValue: (modalData.validations && modalData.validations.multi_upload) ? modalData.validations.multi_upload : false,
        valuePropName: 'checked',
      })(
        <Checkbox onChange={(e) => setModalData({
          ...modalData,
          validations: {
            ...modalData.validations,
            multi_upload: e.target.checked,
          },
        })}>Multi upload</Checkbox>,
      )}
    </Form.Item>
    <Form.Item
      label={'Max Upload size (MB)'}
        >
      {getFieldDecorator('max_upload_size', {
        initialValue: modalData.validations.max_upload_size || 5,
      })(
        <InputNumber
          min={1}
          onChange={(value) => setModalData({
            ...modalData,
            validations: {
              ...modalData.validations,
              max_upload_size: value,
            },
          })} />,
      )}
    </Form.Item>
    <Form.Item
      label={'File Types'}
        >
      {getFieldDecorator('allowed_file_type', {
        initialValue: modalData.validations.allowed_file_type || [],
        rules: [{ required: true, message: 'Please select allowed file type' }],
      })(
        <Select
          mode={'multiple'}
          style={{ width: '100%' }}
          placeholder='Please select allowed file type'
          onChange={(value) => setModalData({
            ...modalData,
            validations: {
              ...modalData.validations,
              allowed_file_type: value,
            },
          })}>
          {fileExtensions.map((res) => <Option value={res} key={res}>{res}</Option>)}
        </Select>,
      )}
    </Form.Item>
  </Fragment>}
</Fragment>);

export default ValidationTab;
