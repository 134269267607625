import { getCategories, deleteCategories, getPageCategories } from 'actions/categories';

/**
 * @todo
 * Please handle each action in seperate case
 * right now i can't see add new category action
 * I saw in saga you are handling few actions in watcher
 * please seperate it
 */
const categories = () => {
  const initialState = {
    categories: [],
  };

  return (state = initialState, { type, response, ids }) => {
    switch (type) {
      case getCategories.requestTypes.SUCCESS:
        return {
          ...initialState,
          categories: response,
        };
      case getPageCategories.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case deleteCategories.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - response.ids.length,
        };
      default:
        return state;
    }
  };
};

export default categories();
