import { createSelector } from 'reselect';
import { getRoles as fetchRoles } from 'actions/roles';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getRolesState = (state) => state.roles;

export const getRoles = createSelector(
  getRolesState,
  (state) => state.data || [],
);

export const rolesLoading = createLoadingSelector(
  fetchRoles.actionName,
)();
export const rolesError = createErrorSelector(
  fetchRoles.actionName,
)();
