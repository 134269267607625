import { createSelector } from 'reselect';
import {
  getMarkets as fetchMarkets, getPageMarkets, addNewMarkets, deleteMarkets, updateMarkets,
} from 'actions/markets';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getMarketsState = (state) => state.markets;
export const getErrorsState = (state) => state.error;

export const getMarkets = createSelector(
  getMarketsState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const marketsLoading = createLoadingSelector(fetchMarkets.actionName)();
export const marketsError = createErrorSelector(fetchMarkets.actionName)();


export const pageMarketsLoading = createLoadingSelector(getPageMarkets.actionName)();
export const pageMarketsError = createErrorSelector(getPageMarkets.actionName)();

export const addNewMarketsLoading = createLoadingSelector(addNewMarkets.actionName)();
export const addNewMarketsError = createErrorSelector(addNewMarkets.actionName)();

export const deleteMarketsLoading = createLoadingSelector(deleteMarkets.actionName)();
export const deleteMarketsError = createErrorSelector(deleteMarkets.actionName)();

export const getMarketsAllErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      addNewMarkets.actionName,
      deleteMarkets.actionName,
      updateMarkets.actionName,
      getPageMarkets.actionName,
      getMarkets.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);
