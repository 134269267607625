import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getConsultants,
  addNewConsultant,
  deleteConsultants,
  updateConsultant,
  importConsultants,
  importConsultantsContacts,
} from 'actions/consultants';
import {
  getConsultantsApi,
  postNewConsultantApi,
  deleteConsultantsApi,
  updateConsultantApi,
  postImportConsultantsApi,
  postImportConsultantsContactsApi,
} from 'services/consultants';
import fetchEntity from './fetch-entity';

const fetchConsultants = fetchEntity.bind(null, getConsultants.actions, getConsultantsApi);
const fetchAddNewConsultant = fetchEntity.bind(
  null,
  addNewConsultant.actions,
  postNewConsultantApi,
);

const fetchUpdateConsultant = fetchEntity.bind(
  null,
  updateConsultant.actions,
  updateConsultantApi,
);

const fetchDeleteConsultants = fetchEntity.bind(
  null,
  deleteConsultants.actions,
  deleteConsultantsApi,
);

const fetchImportConsultants = fetchEntity.bind(
  null,
  importConsultants.actions,
  postImportConsultantsApi,
);

const fetchImportConsultantsContacts = fetchEntity.bind(
  null,
  importConsultantsContacts.actions,
  postImportConsultantsContactsApi,
);

export function* loadUpdateConsultants({ params }) {
  yield call(fetchUpdateConsultant, { ...params });
}

function* watchUpdateConsultants() {
  yield takeLatest(updateConsultant.actionName, loadUpdateConsultants);
}


export function* loadGetConsultants({ params }) {
  yield call(fetchConsultants, { ...params, '$sort[updatedAt]': -1 });
}

function* watchGetConsultants() {
  yield takeLatest([getConsultants.actionName], loadGetConsultants);
}

export function* loadGetConsultantsOnChange({ params }) {
  const consultants = yield select((state) => state.consultants);
  const { total, skip, limit } = consultants;
  if (skip && skip >= total) {
    yield call(fetchConsultants, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchConsultants, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetConsultantsOnChange() {
  yield takeLatest([
    deleteConsultants.requestTypes.SUCCESS,
  ], loadGetConsultantsOnChange);
}

export function* loadAddNewConsultant({ params }) {
  yield call(fetchAddNewConsultant, { ...params });
}

function* watchAddNewConsultant() {
  yield takeLatest(addNewConsultant.actionName, loadAddNewConsultant);
}

export function* loadDeleteConsultants({ params }) {
  yield call(fetchDeleteConsultants, { ...params });
}

function* watchDeleteConsultants() {
  yield takeLatest(deleteConsultants.actionName, loadDeleteConsultants);
}

export function* loadImportConsultants({ params }) {
  yield call(fetchImportConsultants, { ...params });
}

function* watchImportConsultants() {
  yield takeLatest(importConsultants.actionName, loadImportConsultants);
}

export function* loadImportConsultantsContacts({ params }) {
  yield call(fetchImportConsultantsContacts, { ...params });
}

function* watchImportConsultantsContacts() {
  yield takeLatest(importConsultantsContacts.actionName, loadImportConsultantsContacts);
}

export default {
  watchGetConsultants,
  watchAddNewConsultant,
  watchDeleteConsultants,
  watchUpdateConsultants,
  watchGetConsultantsOnChange,
  watchImportConsultants,
  watchImportConsultantsContacts,
};
