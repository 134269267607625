import React, {
  useEffect, useState, useCallback,
} from 'react';
import { useDidUpdateEffect } from 'hooks';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Icon, Modal, message, Spin, Button,
} from 'antd';

import {
  Table, Title,
} from 'components';
import {
  getUserCompanies as getUserCompaniesAction,
  deleteUserCompanies as deleteUserCompaniesAction,
} from 'actions/userCompanies';
import {
  userCompaniesLoading as getLoadingSelector,
  pageUserCompaniesLoading as getPageLoadingSelector,
  deleteUserCompaniesLoading as deleteLoadingSelector,
  userCompaniesError as getErrorSelector,
  deleteUserCompaniesError as deleteErrorSelector,
  getUserCompanies,
} from 'selectors/userCompanies';
import {
  getUsers as getUsersAction,
} from 'actions/users';
import {
  getUsers,
} from 'selectors/users';

import routes from 'constants/routes';
import { messages } from 'messages';
import FormModal from '../Form';

const {
  titleMessage,
  editButtonMessage,
  deleteButtonMessage,
  addNewButtonMessage,
  deleteConfirmMessage,
  companyNameColumnName,
  logoColumnName,
  numberOfUsersColumnName,
} = messages.userCompanies.table;

const { confirm } = Modal;

const defaultColumns = [
  {
    title: logoColumnName,
    dataIndex: 'logo',
    key: 'logo',
    render: (text, record) => (
      record.logo
        ? <img src={record.logo} alt={record.name} style={{ height: 22 }} />
        : 'No Logo'
    ),
  },
  {
    title: companyNameColumnName,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: numberOfUsersColumnName,
    dataIndex: 'numberOfUsers',
    key: 'numberOfUsers',
    width: 150,
    render: (text, record) => (
      // eslint-disable-next-line no-nested-ternary
      text === 'loading'
        ? <Spin style={{ height: 22 }} />
        : text === 0
          ? text
          : <Link to={{
            pathname: routes.users.table.pathname,
            state: { userCompanyId: record.id },
          }}> {text} </Link>
    ),
  },
];


const UserCompaniesPage = (props) => {
  const PAGE_SIZE = 10;
  const dispatch = useDispatch();
  const columns = [
    ...defaultColumns,
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 180,
      render: (text, record) => record.hovered && record.numberOfUsers !== 'loading'
      && <>
        <Button type='default' size='small' onClick={() => onModalActionChange('Edit', record)}>
          <Icon type='edit' theme='filled' />
          {editButtonMessage}
        </Button>
        {' '}
        <Button
          disabled={record.numberOfUsers > 0}
          size='small'
          type='danger'
          onClick={() => showDeleteDialog(record.id)}
            >
          <Icon type='delete' theme='filled' />
          {deleteButtonMessage}
        </Button>
      </>,
    }];

  const [modalAction, setModalAction] = useState();
  const [currentRecord, setCurrentRecord] = useState();

  const userCompanies = useSelector(getUserCompanies);
  const users = useSelector(getUsers);
  const getLoading = useSelector(getLoadingSelector);
  const getPageLoading = useSelector(getPageLoadingSelector);
  const deleteLoading = useSelector(deleteLoadingSelector);
  const getError = useSelector(getErrorSelector);
  const deleteError = useSelector(deleteErrorSelector);

  const [userCount, setUserCount] = useState({});
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getUserCompaniesAction.request({ $skip: (page - 1) * PAGE_SIZE }));
  }, [dispatch, page]);

  useEffect(() => {
    getError && message.error(`Error ${getError.code}: ${getError.message}`);
    deleteError
        && message.error(`Error ${deleteError.code}: ${deleteError.message}`);
  }, [deleteError, getError]);

  useDidUpdateEffect(() => {
    userCompanies.data.map((company) => dispatch(getUsersAction.request({ companyId: company.id })));
  }, [userCompanies]);

  useDidUpdateEffect(() => {
    setUserCount({ ...userCount, [users.companyId]: users.total });
  }, [users]);

  const onModalActionChange = useCallback((action, record) => {
    setCurrentRecord(record);
    setModalAction(action);
  }, []);

  const showDeleteDialog = (id) => {
    confirm({
      title: deleteConfirmMessage,
      okType: 'danger',
      onOk() {
        dispatch(deleteUserCompaniesAction.request({ id }));
      },
    });
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Title level={4}>
            {titleMessage}
          </Title>
        </Col>
        <Col span={12} align={'right'}>
          <Button type='primary' size='large' onClick={() => onModalActionChange('Create')}>
            {addNewButtonMessage}
          </Button>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Table
            loading={getLoading || deleteLoading || getPageLoading}
            rowKey='id'
            pagination={{
              current: page,
              total: userCompanies.total,
              pageSize: PAGE_SIZE,
              onChange: (current) => setPage(current),
            }}
            dataSource={userCompanies.data.map((company) => (
              {
                ...company,
                numberOfUsers: Object.prototype.hasOwnProperty.call(userCount, company.id) ? userCount[company.id] : 'loading',
              }
            ))}
            columns={columns}
            style={{ paddingTop: '50px' }}
            />
        </Col>
        <FormModal
          modalAction={modalAction}
          onModalActionChange={onModalActionChange}
          currentCompany={currentRecord}
        />
      </Row>
    </>
  );
};

export default UserCompaniesPage;
