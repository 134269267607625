import React, { useState, useCallback } from 'react';
import { Table as AntTable } from 'antd';
import styled from 'styled-components';
import { messages } from 'messages';

const {
  paginationShowingMessage,
} = messages.general;

const StyledTable = styled(AntTable)`
  table {
    border-spacing: 0 5px !important;
    padding: 10px;
  }

  .ant-table-pagination {
    margin-top: ${(props) => props.pagination.embedded && '-33px !important'};
    padding-right: 40px;
  }

  .ant-table-thead > tr > th {
    padding-top: 0;
    padding-bottom: 0;
    color: #4962f5;
    background-color: transparent;
    font-weight: 700;
    font-size: 12px;
    border: none;

    .ant-table-selection {
      display: none;
    }
  }

  .ant-table-row {
    height: 57px !important;
    background-color: #ffffff;
  }
  .ant-table-tbody > tr > td
  {
    overflow-wrap: anywhere;
  }
`;

const Table = (props) => {
  const { pagination, ...otherProps } = props;
  const [hoveredRow, setHoveredRow] = useState();
  const rowKey = props.rowKey || 'key';

  const getDataSource = useCallback(() => {
    const dataSource = props.dataSource && [...props.dataSource];
    if (hoveredRow) {
      const rowIndex = dataSource.findIndex((l) => l[rowKey] === hoveredRow);
      const newRow = {
        ...dataSource[rowIndex],
        hovered: true,
      };
      dataSource[rowIndex] = newRow;
    }
    return dataSource;
  }, [hoveredRow, props.dataSource, rowKey]);

  return <StyledTable
    pagination={pagination === false ? false : {
      ...pagination,
      showTotal: (total, range) => `${paginationShowingMessage}: ${range[0]} - ${
        range[1]
      } / ${total}`,
      position: 'top',
      showLessItems: true,
      embedded: true,
    }}
    onRow={(record) => ({
      onMouseEnter: () => setHoveredRow(record[rowKey]),
      onMouseLeave: () => setHoveredRow(),
    })}
    {...otherProps}
    dataSource={getDataSource()}
/>;
};

export default Table;
