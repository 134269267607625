import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getCategories, deleteCategories, addNewCategories, updateCategories, getPageCategories,
} from 'actions/categories';
import {
  getCategoriesApi, deleteCategoriesApi, addNewCategoryApi, updateCategoriesApi,
} from 'services/categories';
import fetchEntity from './fetch-entity';

const fetchCategories = fetchEntity.bind(null, getCategories.actions, getCategoriesApi);
const fetchPageCategories = fetchEntity.bind(null, getPageCategories.actions, getCategoriesApi);
const fetchAddNewCategory = fetchEntity.bind(
  null,
  addNewCategories.actions,
  addNewCategoryApi,
);

const fetchUpdateCategory = fetchEntity.bind(
  null,
  updateCategories.actions,
  updateCategoriesApi,
);

const fetchDeleteCategories = fetchEntity.bind(
  null,
  deleteCategories.actions,
  deleteCategoriesApi,
);

export function* loadGetPageCategories({ params }) {
  yield call(fetchPageCategories, { ...params });
}

function* watchGetPageCategories() {
  yield takeLatest([getPageCategories.actionName], loadGetPageCategories);
}

export function* loadGetPageCategoriesOnChange({ params }) {
  const categories = yield select((state) => state.categories);
  const { total, skip, limit } = categories;
  if (skip && skip >= total) {
    yield call(fetchPageCategories, {
      $skip: total - Math.max(total % limit, limit),
      '$sort[updatedAt]': -1,
    });
  } else {
    yield call(fetchPageCategories, { $skip: skip, '$sort[updatedAt]': -1 });
  }
}

function* watchGetPageCategoriesOnChange() {
  yield takeLatest([
    deleteCategories.requestTypes.SUCCESS,
    addNewCategories.requestTypes.SUCCESS,
    updateCategories.requestTypes.SUCCESS,
  ], loadGetPageCategoriesOnChange);
}

export function* loadGetCategories({ params }) {
  yield call(fetchCategories, { ...params });
}
function* watchGetCategories() {
  yield takeLatest(getCategories.actionName, loadGetCategories);
}

export function* loadUpdateCategories({ params }) {
  yield call(fetchUpdateCategory, { ...params });
}

function* watchUpdateCategories() {
  yield takeLatest(updateCategories.actionName, loadUpdateCategories);
}

export function* loadAddNewCategory({ params }) {
  yield call(fetchAddNewCategory, { ...params });
}

function* watchAddNewCategory() {
  yield takeLatest(addNewCategories.actionName, loadAddNewCategory);
}
export function* loadDeleteCategories({ params }) {
  yield call(fetchDeleteCategories, { ...params });
}

function* watchDeleteCategories() {
  yield takeLatest(deleteCategories.actionName, loadDeleteCategories);
}

export default {
  watchGetCategories,
  watchUpdateCategories,
  watchAddNewCategory,
  watchDeleteCategories,
  watchGetPageCategories,
  watchGetPageCategoriesOnChange,
};
