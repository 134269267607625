import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDidUpdateEffect } from 'hooks';
import {
  Icon, Modal, Form, message, Upload,
} from 'antd';

import {
  Button, FormItem, Input, RichTextEditor,
} from 'components';
import { messages } from 'messages';
import {
  addNewUserCompany,
  updateUserCompanies,
  addUserCompanyLogo,
  deleteUserCompanyLogo,
} from 'actions/userCompanies';
import {
  addNewUserCompanyLoading,
  updateUserCompaniesLoading,
  getUserCompaniesFormErrors,
  getUserCompanyLogo,
  addUserCompanyLogoLoading,
  deleteUserCompanyLogoLoading,
} from 'selectors/userCompanies';

import formItemsConfig from './formItemsConfig';

const {
  title,
  createModalActionName,
  editModalActionName,
  chooseLogoMessage,
} = messages.userCompanies.form;

const {
  companyNameInput,
  logoInput,
  aboutCompanyHTMLEditor,
} = formItemsConfig;

const UserCompaniesForm = (props) => {
  const {
    form, modalAction, onModalActionChange, currentCompany,
  } = props;
  const { getFieldDecorator } = form;

  const dispatch = useDispatch();
  const isAddUserCompanyLoading = useSelector(addNewUserCompanyLoading);
  const isUpdateUserCompanyLoading = useSelector(updateUserCompaniesLoading);
  const userCompanyFormErrors = useSelector(getUserCompaniesFormErrors);
  const logoURL = useSelector(getUserCompanyLogo);
  const isAddUploadLoading = useSelector(addUserCompanyLogoLoading);
  const isDeleteUploadLoading = useSelector(deleteUserCompanyLogoLoading);

  const [imgURL, setImgURL] = useState(
    currentCompany ? currentCompany.logo : null,
  );

  useEffect(() => {
    userCompanyFormErrors && userCompanyFormErrors.forEach((error) => message.error(`Error ${error.code} ${error.message}`));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userCompanyFormErrors)]);

  useDidUpdateEffect(() => {
    if (!isUpdateUserCompanyLoading && !isAddUserCompanyLoading && !userCompanyFormErrors) {
      changeModalAction();
    }
  }, [isUpdateUserCompanyLoading, isAddUserCompanyLoading, JSON.stringify(userCompanyFormErrors)]);

  useDidUpdateEffect(() => {
    form.setFieldsValue({ [aboutCompanyHTMLEditor.fieldName]: currentCompany ? currentCompany.about : null });
    setImgURL(currentCompany && currentCompany.logo);
  }, [currentCompany]);

  useDidUpdateEffect(() => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        submitForm(values);
      }
      return true;
    });
  }, [logoURL]);

  const getLogoFile = useCallback(async () => {
    const file = await fetch(imgURL).then((r) => r.blob());
    return file;
  }, [imgURL]);

  const submitForm = useCallback(() => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const modifiedValues = {
          ...values,
          logo: logoURL,
        };
        dispatch(
          modalAction === 'Create'
            ? addNewUserCompany.request(modifiedValues)
            : updateUserCompanies.request({ id: currentCompany.id, values: modifiedValues }),
        );
      }
    });
  }, [currentCompany, dispatch, form, logoURL, modalAction]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (currentCompany) {
            if (currentCompany.logo) {
              if (imgURL && imgURL !== currentCompany.logo) {
                getLogoFile().then((file) => dispatch(addUserCompanyLogo.request({ file, source: 'user-companies' })));
                return;
              }
              if (!imgURL) {
                dispatch(
                  deleteUserCompanyLogo.request({
                    id: currentCompany.logo.split('/').slice(-1)[0],
                  }),
                );
                return;
              }
            } else if (imgURL) {
              getLogoFile().then((file) => dispatch(addUserCompanyLogo.request({ file, source: 'user-companies' })));
              return;
            }
          } else if (imgURL) {
            getLogoFile().then((file) => dispatch(addUserCompanyLogo.request({ file, source: 'user-companies' })));
            return;
          }
          submitForm(values);
        }
      });
    },
    [currentCompany, dispatch, form, getLogoFile, imgURL, submitForm],
  );

  const changeModalAction = (action) => {
    onModalActionChange(action);
    setImgURL();
    form.resetFields();
  };

  const logoChangeHandler = async (file) => {
    if (file.type.includes('image')) {
      setImgURL(URL.createObjectURL(file));
    } else {
      message.error('File must be an image!');
    }
  };

  return (
    <Modal
      style={{ top: '7%' }}
      title={`${modalAction === 'Create' ? createModalActionName : editModalActionName} ${title}`}
      visible={!!modalAction}
      onOk={handleSubmit}
      onCancel={() => changeModalAction()}
      okText={modalAction === 'Create' ? createModalActionName : editModalActionName}
      okButtonProps={{
        form: 'userCompanies',
        loading:
          isAddUserCompanyLoading
            || isUpdateUserCompanyLoading
            || isAddUploadLoading
            || isDeleteUploadLoading,
      }}>
      <Form layout='vertical' id='userCompanies' onSubmit={handleSubmit}>
        <FormItem label={companyNameInput.label}>
          {getFieldDecorator(companyNameInput.fieldName, {
            ...companyNameInput.decoratorOptions,
            initialValue: currentCompany ? currentCompany.name : null,
          })(
            <Input type='email' {...companyNameInput.elementProps} />,
          )}
        </FormItem>
        <FormItem
          label={logoInput.label}
            >
          <Upload
            listType='picture-card'
            showUploadList={false}
            beforeUpload={(file) => {
              logoChangeHandler(file);
              return false;
            }}
              >
            {imgURL ? (
              <>
                <img src={imgURL} style={{ width: '100%' }} alt='logo' />
                <Button
                  style={{ width: '100%' }}
                  type='danger'
                  size='small'
                  onClick={(e) => {
                    e.stopPropagation();
                    setImgURL();
                  }}
                    >
                  <Icon type='delete' />
                </Button>
              </>
            ) : (
              <div>
                <Icon type='plus' />
                <div>{chooseLogoMessage}</div>
              </div>
            )}
          </Upload>
        </FormItem>
        <FormItem label={aboutCompanyHTMLEditor.label}>
          {getFieldDecorator(aboutCompanyHTMLEditor.fieldName, {
            ...aboutCompanyHTMLEditor.decoratorOptions,
            initialValue: currentCompany ? currentCompany.about : null,
          })(<RichTextEditor />)}
        </FormItem>
      </Form>
    </Modal>
  );
};

export default Form.create({ name: 'user-companies-form' })(UserCompaniesForm);
