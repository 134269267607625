import { createSelector } from 'reselect';
import {
  getEmailTemplates as fetchEmailTemplates, getPageEmailTemplates, addNewEmailTemplate, deleteEmailTemplates, updateEmailTemplates,
} from 'actions/emailTemplates';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getEmailTemplatesState = (state) => state.emailTemplates;
export const getErrorsState = (state) => state.error;

export const getEmailTemplates = createSelector(
  getEmailTemplatesState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const getSuccessState = createSelector(
  getEmailTemplatesState,
  (state) => state.isSuccessful,
);

export const emailTemplatesLoading = createLoadingSelector(fetchEmailTemplates.actionName)();
export const emailTemplatesError = createErrorSelector(fetchEmailTemplates.actionName)();

export const pageEmailTemplatesLoading = createLoadingSelector(getPageEmailTemplates.actionName)();
export const pageEmailTemplatesError = createErrorSelector(getPageEmailTemplates.actionName)();

export const addNewEmailTemplateLoading = createLoadingSelector(addNewEmailTemplate.actionName)();
export const addNewEmailTemplateError = createErrorSelector(addNewEmailTemplate.actionName)();

export const updateEmailTemplatesLoading = createLoadingSelector(updateEmailTemplates.actionName)();
export const updateEmailTemplatesError = createErrorSelector(updateEmailTemplates.actionName)();

export const deleteEmailTemplatesLoading = createLoadingSelector(deleteEmailTemplates.actionName)();
export const deleteEmailTemplatesError = createErrorSelector(deleteEmailTemplates.actionName)();

export const getEmailTemplatesAllErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      addNewEmailTemplate.actionName,
      deleteEmailTemplates.actionName,
      updateEmailTemplates.actionName,
      getEmailTemplates.actionName,
      getPageEmailTemplates.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);
