import React, {
  useEffect, useState, useCallback,
} from 'react';
import {
  Button, Table, Title, Input,
} from 'components';
import styled from 'styled-components';
import {
  getLoading as getLoadingSelector,
  deleteLoading as deleteLoadingSelector,
  getError as getErrorSelector,
  deleteError as deleteErrorSelector,
  getConsultants,
  importConsultantsLoading,
  importConsultantsContactsLoading,
} from 'selectors/consultants';
import {
  Icon, Modal, message, Upload,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getConsultants as getConsultantsAction,
  deleteConsultants as deleteConsultantsAction,
  importConsultants as importConsultantsAction,
  importConsultantsContacts as importConsultantsContactsAction,
} from 'actions/consultants';
import { Link } from 'react-router-dom';

import routes from 'constants/routes';
import { messages } from 'messages';

const {
  consultantsTitleMessage,
  newConsultantButtonMessage,
  unselectButtonMessage,
  consultantEditButtonMessage,
  confirmDeleteMessage,
  companyNameColumnName,
  categoryColumnName,
  importConsultantsButtonMessage,
  importConsultantsContactsButtonMessage,
} = messages.consultants.table;

const { confirm } = Modal;

const defaultColumns = [
  {
    title: categoryColumnName,
    dataIndex: 'category',
    key: 'category',
    render: (category) => category.name,
  },
  {
    title: '',
    dataIndex: 'edit',
    key: 'edit',
    width: 100,
    render: (text, record) => record.hovered
    && <Link
      to={{
        pathname: routes.consultants.form.pathname,
        state: { currentConsultant: record },
      }}
    >
      <Button type='default' size='small'>
        <Icon type='edit' theme='filled' />
        {consultantEditButtonMessage}
      </Button>
    </Link>,
  },
];

const Footer = styled.div`
  margin-top: 10px;
  float: right;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const ConsultantsPage = (props) => {
  const PAGE_SIZE = 25;
  const dispatch = useDispatch();

  const consultants = useSelector(getConsultants);
  const getLoading = useSelector(getLoadingSelector);
  const deleteLoading = useSelector(deleteLoadingSelector);
  const getError = useSelector(getErrorSelector);
  const deleteError = useSelector(deleteErrorSelector);
  const getImportConsultantsLoading = useSelector(importConsultantsLoading);
  const getImportConsultantsContactsLoading = useSelector(importConsultantsContactsLoading);

  const handleSearch = (name) => {
    setSearchName(name);
    dispatch(getConsultantsAction.request({
      'companyName[$iLike]': `%${name}%`,
      $skip: (searchPage - 1) * PAGE_SIZE,
    }));
  };

  const columns = [
    {
      title: companyNameColumnName,
      dataIndex: 'companyName',
      key: 'companyName',
    },
    ...defaultColumns,
  ];

  const [page, setPage] = useState(1);
  const [rowKeys, setRowKeys] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [searchPage, setSearchPage] = useState(1);
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    dispatch(getConsultantsAction.request({ $skip: (page - 1) * PAGE_SIZE }));
  }, [dispatch, page]);

  useEffect(() => {
    getError && message.error(`Error ${getError.code}: ${getError.message}`);
    deleteError
      && message.error(`Error ${deleteError.code}: ${deleteError.message}`);
  }, [deleteError, getError]);

  useEffect(() => {
    dispatch(
      getConsultantsAction.request({
        'companyName[$iLike]': `%${searchName}%`,
        $skip: (searchPage - 1) * PAGE_SIZE,
      }),
    );
  }, [dispatch, searchPage, searchName]);

  const deleteConsultants = useCallback(
    () => {
      dispatch(deleteConsultantsAction.request({ 'id[$in]': rowKeys }));
      setRowKeys([]);
    }, [dispatch, rowKeys],
  );

  const showDeleteDialog = () => {
    confirm({
      title: confirmDeleteMessage,
      okType: 'danger',
      onOk() {
        deleteConsultants();
      },
    });
  };

  const importConsultants = useCallback(async (file) => {
    if (file.type.includes('text/csv')) {
      dispatch(importConsultantsAction.request({ file, source: 'consultants' }));
    } else {
      message.error('File must be an CSV!');
    }
  }, [dispatch]);

  const importConsultantsContacts = useCallback(async (file) => {
    if (file.type.includes('text/csv')) {
      dispatch(importConsultantsContactsAction.request({ file, source: 'consultants-contacts' }));
    } else {
      message.error('File must be an CSV!');
    }
  }, [dispatch]);

  return (
    <div>
      <Upload
        accept='.csv'
        showUploadList={false}
        beforeUpload={(file) => {
          importConsultants(file);
          return false;
        }}
      >
        <Button loading={getImportConsultantsLoading}>
          {!getImportConsultantsLoading && <Icon type='upload' />}{' '}
          {importConsultantsButtonMessage}
        </Button>
      </Upload>
      <Upload
        accept='.csv'
        showUploadList={false}
        beforeUpload={(file) => {
          importConsultantsContacts(file);
          return false;
        }}>
        <Button loading={getImportConsultantsContactsLoading}>
          {!getImportConsultantsContactsLoading && <Icon type='upload' />} {importConsultantsContactsButtonMessage}
        </Button>
      </Upload>
      <Title level={4} style={{ paddingTop: '30px' }}>
        {consultantsTitleMessage}
        <Link
          to={{
            pathname: routes.consultants.form.pathname,
            state: { currentConsultant: null },
          }}
        >
          <Button type='success' size='large'>
            {newConsultantButtonMessage}
          </Button>
        </Link>
      </Title>
      <div style={{ padding: 8 }}>
        <Input
          placeholder={'Search by name'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys[0])}
          style={{ width: 300, marginBottom: 8, display: 'block' }}
          />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys[0])}
          icon='search'
          size='small'
          style={{ width: 192, marginRight: 8 }}
          >
            Search
        </Button>
        <Button onClick={() => handleSearch('')} size='small' style={{ width: 100 }}>
            Reset
        </Button>
      </div>
      <div>
        <Table
          loading={getLoading || deleteLoading}
          rowKey='id'
          pagination={{
            current: searchName !== '' ? searchPage : page,
            total: consultants.total,
            pageSize: PAGE_SIZE,
            onChange: (current) => (searchName !== '' ? setSearchPage(current) : setPage(current)),
          }}
          dataSource={consultants.data}
          columns={columns}
          rowSelection={{
            selectedRowKeys: rowKeys,
            onChange: (selectedRowKeys) => setRowKeys(selectedRowKeys),
          }}
        />
        <Footer>
          <Button
            type='danger'
            size='large'
            onClick={showDeleteDialog}
            disabled={!rowKeys.length}
          >
            <Icon type='delete' theme='filled' />
          </Button>
          <Button type='danger' size='large' onClick={() => setRowKeys([])}>
            {unselectButtonMessage}
            <Icon type='reload' />
          </Button>
        </Footer>
      </div>
    </div>
  );
};

export default ConsultantsPage;
