import { createSelector } from 'reselect';
import {
  getCategories as fetchCategories, getPageCategories, addNewCategories, deleteCategories, updateCategories,
} from 'actions/consultantCategories';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getCategoriesState = (state) => state.consultantCategories;
export const getErrorsState = (state) => state.error;

export const getCategories = createSelector(
  getCategoriesState,
  (state) => ({ ...state, data: state.data || [] }),
);

export const categoriesLoading = createLoadingSelector(fetchCategories.actionName)();
export const categoriesError = createErrorSelector(fetchCategories.actionName)();


export const pageCategoriesLoading = createLoadingSelector(getPageCategories.actionName)();
export const pageCategoriesError = createErrorSelector(getPageCategories.actionName)();

export const addNewCategoriesLoading = createLoadingSelector(addNewCategories.actionName)();
export const addNewCategoriesError = createErrorSelector(addNewCategories.actionName)();

export const deleteCategoriesLoading = createLoadingSelector(deleteCategories.actionName)();
export const deleteCategoriesError = createErrorSelector(deleteCategories.actionName)();

export const getCategoriesAllErrors = createSelector(
  getErrorsState,
  (errors) => {
    const existingErrorsArray = [];
    const errorsArray = [
      addNewCategories.actionName,
      deleteCategories.actionName,
      updateCategories.actionName,
      getCategories.actionName,
      getPageCategories.actionName,
    ];

    errorsArray.forEach((error) => {
      errors[error] && existingErrorsArray.push(errors[error]);
    });
    return existingErrorsArray.length === 0 ? false : existingErrorsArray;
  },
);
