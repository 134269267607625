import { deleteServices, getServices as fetchServices } from 'actions/services';
import {
  Col, Icon, Modal, Row,
} from 'antd';
import { Button, Table, Title } from 'components';
import routes from 'constants/routes';
import { messages } from 'messages';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteLoading as deleteServicesLoading, getLoading as getServicesLoading, getServices } from 'selectors/services';
import serviceConfig from './serviceConfig';

const { confirm } = Modal;

const {
  editButtonMessage,
  deleteButtonMessage,
  deleteConfirmMessage,
} = messages.services.table;

const ServicesTable = (props) => {
  const PAGE_SIZE = 10;
  const { match } = props;
  const serviceName = match.params.type;

  const columns = [
    ...serviceConfig[serviceName].columns,
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 180,
      render: (text, record) => record.hovered && (
        <>
          <Link
            to={{
              pathname: routes.services[serviceName].form.pathname,
              state: { currentService: record },
            }}
              >
            <Button size='small'>
              <Icon type='edit' theme='filled' />
              {editButtonMessage}
            </Button>
          </Link>
          {' '}
          <Button
            size='small'
            type='danger'
            onClick={() => showDeleteConfirm(record.id)}
          >
            <Icon type='delete' theme='filled' />

            {deleteButtonMessage}
          </Button>
        </>
      ),
    },
  ];

  const dispatch = useDispatch();
  const services = useSelector(getServices)[serviceName] || [];
  const isGetServicesLoading = useSelector(getServicesLoading);
  const isDeleteServicesLoading = useSelector(deleteServicesLoading);

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchServices.request({ type: serviceName }));
  }, [dispatch, serviceName]);

  const showDeleteConfirm = (id) => {
    confirm({
      title: `${deleteConfirmMessage} ${serviceName}?`,
      okType: 'danger',
      onOk() {
        dispatch(deleteServices.request({ id }));
      },
    });
  };

  return (
    <>
      <Row gutter={40}>
        <Col span={12}>
          <Title level={4}>{serviceConfig[serviceName].titleMessage}</Title>
        </Col>
      </Row>
      <Table
        rowKey='id'
        columns={columns}
        dataSource={services.data}
        loading={isGetServicesLoading || isDeleteServicesLoading}
        pagination={{
          current: page,
          total: services.total,
          pageSize: PAGE_SIZE,
          onChange: (current) => setPage(current),
        }}
      />
    </>
  );
};

export default ServicesTable;
