import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm } from 'actions/formBuilder';
import {
  getUpdateFormError,
  getUpdateFormLoading,
} from 'selectors/formBuilder';
import Table from './Table';
import Builder from './Builder';
import AddnewForm from './AddNewForm';

export default withRouter(React.memo(({ history, match: { params } }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getUpdateFormLoading);
  const error = useSelector(getUpdateFormError);
  const updateFormAction = (data) => dispatch(updateForm.request(data));
  return params.id
    ? <Builder
      history={history}
      formId={params.id}
      isUpdating={isLoading}
      isUpdateError={error}
      updateForm={updateFormAction}
    />
    : <Fragment>
      <AddnewForm />
      <Table
        isUpdating={isLoading}
        isUpdateError={error}
        updateForm={updateFormAction}
        history={history} />
    </Fragment>;
}));
