import {
  getEmailTemplates,
  getPageEmailTemplates,
  deleteEmailTemplates,
  addNewEmailTemplate,
  updateEmailTemplates,
} from 'actions/emailTemplates';

const emailTemplates = () => {
  const initialState = {};

  return (state = initialState, { type, response, ids }) => {
    switch (type) {
      case getEmailTemplates.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case getPageEmailTemplates.requestTypes.SUCCESS:
        return {
          ...response,
        };
      case deleteEmailTemplates.requestTypes.SUCCESS:
        return {
          ...state,
          total: state.total - ids.length,
        };
      case addNewEmailTemplate.requestTypes.REQUEST:
      case updateEmailTemplates.requestTypes.REQUEST:
        return {
          ...state,
          isSuccessful: false,
        };
      case addNewEmailTemplate.requestTypes.SUCCESS:
      case updateEmailTemplates.requestTypes.SUCCESS:
        return {
          ...state,
          isSuccessful: true,
        };
      case addNewEmailTemplate.requestTypes.FAILURE:
      case updateEmailTemplates.requestTypes.FAILURE:
        return {
          ...state,
          isSuccessful: false,
        };
      default:
        return state;
    }
  };
};

export default emailTemplates();
