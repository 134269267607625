import { messages } from 'messages';

const {
  templateNameInputMessages,
  subjectInputMessages,
  textAreaInputMessages,
  htmlEditorInputMessages,
} = messages.emailTemplates.form;

const emailTemplatesFormConfig = {
  templateNameInput: {
    label: templateNameInputMessages.label,
    fieldName: 'templateName',
    decoratorOptions: {
      rules: [{ required: true, message: templateNameInputMessages.required }],
    },
    elementProps: {},
  },
  subjectInput: {
    label: subjectInputMessages.label,
    fieldName: 'subject',
    decoratorOptions: {
      rules: [{ required: true, message: subjectInputMessages.required }],
    },
    elementProps: {},
  },
  textAreaInput: {
    label: textAreaInputMessages.label,
    fieldName: 'text',
    decoratorOptions: {
      rules: [{ required: true, message: textAreaInputMessages.required }],
    },
    elementProps: {},
  },
  htmlEditorInput: {
    label: htmlEditorInputMessages.label,
    fieldName: 'html',
    decoratorOptions: {
      rules: [{ required: true, message: htmlEditorInputMessages.required }],
    },
    elementProps: { style: { height: '300px' } },
  },
};

export default emailTemplatesFormConfig;
