import { call, takeLatest, select } from 'redux-saga/effects';
import {
  getContacts, deleteContacts, addNewContact, updateContacts, getPageContacts,
} from 'actions/contacts';
import {
  getContactsApi, deleteContactsApi, addNewContactApi, updateContactsApi,
} from 'services/contacts';
import fetchEntity from './fetch-entity';

const fetchContacts = fetchEntity.bind(null, getContacts.actions, getContactsApi);
const fetchPageContacts = fetchEntity.bind(null, getPageContacts.actions, getContactsApi);
const fetchAddNewContact = fetchEntity.bind(
  null,
  addNewContact.actions,
  addNewContactApi,
);

const fetchUpdateContact = fetchEntity.bind(
  null,
  updateContacts.actions,
  updateContactsApi,
);

const fetchDeleteContacts = fetchEntity.bind(
  null,
  deleteContacts.actions,
  deleteContactsApi,
);

export function* loadGetPageContacts({ params }) {
  yield call(fetchPageContacts, { ...params });
}

function* watchGetPageContacts() {
  yield takeLatest([getPageContacts.actionName], loadGetPageContacts);
}

export function* loadGetPageContactsOnChange(action) {
  const contacts = yield select((state) => state.contacts);
  const companyId = action.response.companyId || action.response[0].companyId;
  const { total, skip, limit } = contacts;
  if (skip && skip >= total) {
    yield call(fetchPageContacts, {
      $skip: total - Math.max(total % limit, limit),
      companyId,
    });
  } else {
    yield call(fetchPageContacts, { $skip: skip, companyId, getAll: true });
  }
}

function* watchGetPageContactsOnChange() {
  yield takeLatest([
    deleteContacts.requestTypes.SUCCESS,
    addNewContact.requestTypes.SUCCESS,
    updateContacts.requestTypes.SUCCESS,
  ], loadGetPageContactsOnChange);
}

export function* loadGetContacts({ params }) {
  yield call(fetchContacts, { ...params });
}
function* watchGetContacts() {
  yield takeLatest(getContacts.actionName, loadGetContacts);
}

export function* loadUpdateContacts({ params }) {
  yield call(fetchUpdateContact, { ...params });
}

function* watchUpdateContacts() {
  yield takeLatest(updateContacts.actionName, loadUpdateContacts);
}

export function* loadAddNewContact({ params }) {
  yield call(fetchAddNewContact, { ...params });
}

function* watchAddNewContact() {
  yield takeLatest(addNewContact.actionName, loadAddNewContact);
}
export function* loadDeleteContacts({ params }) {
  yield call(fetchDeleteContacts, { ...params });
}

function* watchDeleteContacts() {
  yield takeLatest(deleteContacts.actionName, loadDeleteContacts);
}

export default {
  watchGetContacts,
  watchUpdateContacts,
  watchAddNewContact,
  watchDeleteContacts,
  watchGetPageContacts,
  watchGetPageContactsOnChange,
};
