import React, { useImperativeHandle, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Modal, Form, Input,
} from 'antd';

import { getCountries as fetchCountries } from 'actions/countries';

import { FormItem, Select } from 'components/';

import { messages } from 'messages';

import assetsConfig from './assetsConfig';

const { formModalEditTitle, formModalCreateTitle } = messages.assets;

const { Option } = Select;

const AssetsModal = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {
    visible,
    onCancel,
    onSubmit,
    form,
    currentAssetType,
    assetToEdit,
  } = props;
  const { getFieldDecorator } = form;

  useImperativeHandle(ref, () => ({
    form,
  }));
  const { formItems } = assetsConfig[currentAssetType];

  useEffect(() => {
    currentAssetType === 'stateProvinces' && dispatch(fetchCountries.request());
  }, [currentAssetType, dispatch]);

  return (
    <Modal
      visible={visible}
      title={`${assetToEdit ? formModalEditTitle : formModalCreateTitle} ${
        formItems.name.label
      }`}
      okText={`${assetToEdit ? formModalEditTitle : formModalCreateTitle}`}
      onCancel={onCancel}
      onOk={onSubmit}
    >
      <Form layout='vertical'>
        <FormItem label={formItems.name.label}>
          {getFieldDecorator(formItems.name.fieldName, {
            ...formItems.name.decoratorOptions,
            initialValue: assetToEdit ? assetToEdit.name : null,
          })(<Input {...formItems.name.elementProps} />)}
        </FormItem>
        {currentAssetType === 'stateProvinces' && (
          <Fragment>
            <FormItem label={formItems.abbreviation.label}>
              {getFieldDecorator(formItems.abbreviation.fieldName, {
                ...formItems.abbreviation.decoratorOptions,
                initialValue: assetToEdit ? assetToEdit.abbreviation : null,
              })(<Input {...formItems.abbreviation.elementProps} />)}
            </FormItem>
            <FormItem label={formItems.country.label}>
              {getFieldDecorator(formItems.country.fieldName, {
                ...formItems.country.decoratorOptions,
                initialValue: assetToEdit ? assetToEdit.country : 'ca',
              })(
                <Select {...formItems.country.elementProps}>
                  {formItems.country.options.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Fragment>
        )}
      </Form>
    </Modal>
  );
});

AssetsModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  form: PropTypes.object,
  currentAssetType: PropTypes.string,
  assetToEdit: PropTypes.object,
  countries: PropTypes.array,
  isCountriesLoading: PropTypes.bool,
  countriesError: PropTypes.object,
};

const AssetsFormModal = Form.create({ name: 'form_in_modal' })(AssetsModal);

export default AssetsFormModal;
